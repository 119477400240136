import PropTypes from "prop-types";
import React, { useState } from "react";
import H2 from '../../../../../shared/components/CustomH2';
import Button from '../../../../../shared/components/CustomLink';


const RedirectOn3 = (props) => {
    let {
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
    } = props;
    
    let image = data.inputs['backgroundImage'].value;

    return (
        <div className="results">
            <section
                className={`bandeau3 position-${data.inputs['position'].value}`}
                style={{
                    backgroundColor: data.inputs['backgroundColor'].value,
                    backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                    backgroundSize: '100%',
                    backgroundPosition: 'top center',
                    backgroundRepeat: 'no-repeat',
                    position: 'relative',
                    padding: data.inputs?.padding?.value,
                }}
            >
                { !preview && props.children }
                <div className="block">
                    {
                        typeof data.blocks.image.inputs.value?.value === 'string' || data.blocks.image.inputs.value?.value?.filePath ? (
                            <img onClick={() => inputCallback(id, 'image')} 
                                src={
                                    typeof data.blocks.image.inputs.value?.value === 'string' ? 
                                        data.blocks.image.inputs.value.value : 
                                        data.blocks.image.inputs.value?.value.filePath ? 
                                            ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.image.inputs.value.value.filePath}` 
                                            : "http://via.placeholder.com/100x100/CCCCCC/AAAAAA?text=Image"
                                }
                                alt="Image jeu"
                            />
                        ) : null
                    }
                    <H2 data={data} preview={preview} component={id} block={'title'} inputCallback={inputCallback} />
                    <Button data={data} preview={preview} component={id} block={'button'} inputCallback={inputCallback} className="as_button" nopadding={true} />
                </div>
            </section>
        </div>
    );
};

RedirectOn3.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingclassName: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default RedirectOn3;
