import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";

import styled from 'styled-components';
import request from '../../../../js/utils/fetch';
import slugify from 'slugify';
import TraductionSelect from '../../../layouts/TopPanel/TraductionSelect'
import moment from 'moment';

import { BUILDER, BUILDER_ASSETS, VIEW, CREATE } from '../../../../js/constants/constant-rights';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';
import * as Pagination from '../../../../js/utils/pagination';

import CardAssets from '../../../layouts/Card/cardContent/CardAssets';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import CardCustom from '../../../layouts/Card/CardCustom';

import colors from '../../../../config/theme/colors';
import EmptyAsset from '../../../../assets/pictos/empty-picto/empty_assets.png';
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import assetsConfig from './config/assets.config';
import assetsAddConfig from './config/assetsAdd.config';

import { START_LOADING, STOP_LOADING, SNACK } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import { eventService } from '../../../../js/services/event.service';
import { ROUTE_CHANNELS_CMS, ROUTE_SALES_MARKETPLACES, ROUTE_COMMUNITIES_SOCIALS, ROUTE_SALES_RETAILERS, ROUTE_COMMUNITIES_NEWSLETTERS, ROUTE_HOME } from '../../../../js/constants/route-names';

import { withApollo } from 'react-apollo';
import { GET_ASSET_CATEGORIES, GET_ASSETS_PAGINATION, GET_ASSETS_PAGINATION_LIGHT, GET_ASSETS_CURSOR, GET_ASSET_TYPE_WIDGET, GET_FULL_ASSETS, ADD_ASSET, ADD_ASSET_DATA, ADD_ASSET_EXPORT } from '../../../../queries/assets'
import { GET_GAME_TYPE } from '../../../../queries/asset_games'
import { GET_EAV_TYPES, GET_ATTRIBUTES_BY_TYPE } from '../../../../queries/attributes'
import { GET_ALL_CHANNELS } from '../../../../queries/channels'
import { GET_CATALOGS_CURSOR, GET_CATALOGS_PAGINATION, GET_CATALOGS_ATTR_BY_ID } from '../../../../queries/catalogs'
import { GET_RETAILERS_CURSOR, GET_RETAILERS_PAGINATION } from '../../../../queries/retailers';
import { GET_COMPLETENESSGROUPS } from '../../../../queries/completeness_group';
import { GET_CURRENCIES } from '../../../../queries/currencies';
import { GET_RATE_GROUPS_LIGHT } from '../../../../queries/rate_group';
import { CREATE_FORMAT_URL, ADD_FORMAT_URL_ATTRIBUTE } from '../../../../queries/formal_url';
import { withTranslation } from 'react-i18next';
import { ASSET_APPLICATION, ASSET_GAME, ASSET_MINISITE, ASSET_SALES } from '../../../../js/constants/assets-types';
import { checkRouting } from '../../../../js/utils/checkRouting';
import { getItemsLocalStorage, setItemsLocalStorage } from '../../../../js/helpers/filters';
import { getParams } from '../../../../js/utils/getParams';
import { Box } from '@material-ui/core';


const GridCustom = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
`;

class EngineAssets extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            assetTypeList: [],
            categories: [],
            filteredAssets: null,
            filteredCat: [],
            openForm: false,
            openFormAdd: false,
            openTypesForm: false,
            openGamesForm: false,
            dataLayout: null,
            assetsAddData: null,
            assetType: null,
            typeOf: null,
            assetTypesData: null,
            assetGamesData: null,
            hasAssets: null,
            activeAddButton: true,
            currentLang: props.locales[0].node.code,
            eavTypeId: null,
            paramIdentifier: null,
            paramStartAt: null,
            paramUrl: null,
            paramEndAt: null,
            paramStatus: true,
            errors: {},
            ready: false,
            readyRetailers: false,
            readyCatalogs: false,
            readyAssets: false,
            page: 1,
            pageAsset: 1,
            pageCatalog: 1,
            pageRetailer: 1,
            nbperpage: 6,
            nbperpageAssets: 8,
            nbperpageCatalogs: 8,
            nbperpageRetailers: 8,
            countPage: 0,
            countPageAssets: 0,
            countPageCatalogs: 0,
            countPageRetailers: 0,
            cursorRetailers: false,
            cursorCatalogs: false,
            cursorAssets: false,
            cursor: false,
            retailerSelected: null,
            catalogSelected: null,
            assetsSelected: [],
            skulist: [],
            searchValue: '',
            searchValueRetailers: '',
            searchValueCatalogs: '',
            searchAsset: '',
            arrayCatFilter: [],
            assetLangSelected: [],
            getAllCatalogs: [],
            getAllRetailers: [],
            separatorFormattingUrl: '-',
            pagination: {
                page: 0,
                perPage: 6,
                count: 0,
            },
            paginationForm: {
                catalog: {
                    page: 0,
                    perPage: 8,
                    count: 0,
                },
                asset: {
                    page: 0,
                    perPage: 8,
                    count: 0,
                },
                retailer: {
                    page: 0,
                    perPage: 8,
                    count: 0,
                },
            }
        };

        this.typingTimer = null;
        this.typeTesting = 'asset';
    }

    prepareChannels() {

        let localStorage_GET_ALL_CHANNELS = localStorage.getItem('GET_ALL_CHANNELS');
        if (!localStorage_GET_ALL_CHANNELS) {
            this.props.client.query({
                query: GET_ALL_CHANNELS,
            }).then(result => {
                localStorage.setItem('GET_ALL_CHANNELS', JSON.stringify(result));

                let getChannelsNotSystem = result.data.channels.edges.filter(e => e.node.isSystem === false)
                this.setState({
                    allChannelsData: getChannelsNotSystem,
                })
            });
        } else {
            let result = JSON.parse(localStorage_GET_ALL_CHANNELS);
            let getChannelsNotSystem = result.data.channels.edges.filter(e => e.node.isSystem === false)
            this.setState({
                allChannelsData: getChannelsNotSystem,
            })

        }
    }
    

    handleGetEavTypes() {

        let localStorage_GET_EAV_TYPES = localStorage.getItem('GET_EAV_TYPES');
        if (!localStorage_GET_EAV_TYPES) {
            this.props.client.query({
                query: GET_EAV_TYPES,
            }).then(result => {
                localStorage.setItem('GET_EAV_TYPES', JSON.stringify(result));
                let getEavAsset = result.data.eavTypes.edges.find(e => e.node.code === 'asset')
                let getAssetEavId = getEavAsset.node.id;
                this.setState({
                    eavTypeId: getAssetEavId,
                }, () => {

                    this.handleGetAttributesAsset();
                });
            });

        } else {
            let result = JSON.parse(localStorage_GET_EAV_TYPES);

            let getEavAsset = result.data.eavTypes.edges.find(e => e.node.code === 'asset')
            let getAssetEavId = getEavAsset.node.id;
            this.setState({
                eavTypeId: getAssetEavId,
            }, () => {

                this.handleGetAttributesAsset();
            });
        }
    }

    handleGetAttributesAsset() {
        this.props.client.query({
            query: GET_ATTRIBUTES_BY_TYPE,
            variables: { id: this.state.eavTypeId }
        }).then(result => {
            let getAllAttributes = result.data.eavType.attributes.edges
            this.setState({
                customAttributes: getAllAttributes
            })
            this.prepareAttributeValues()
        });
    }

    prepareAttributeValues() {
        for (let locale of this.props.locales) {
            let values = {};
            if (this.state.customAttributes) {
                for (let attribute of this.state.customAttributes) {
                    if (attribute.node.attributeType.input === 'select') {
                        values[attribute.node.identifier] = attribute.node.attributeOptions.edges[0].node.id;
                    }
                }

                this.setState({
                    [locale.node.code]: values
                });
            }
        }
    }

    handleGetAssetCategories(filtering) {
        return new Promise(async (resolve, reject) => {

            let localStorage_GET_ASSET_CATEGORIES = localStorage.getItem('GET_ASSET_CATEGORIES');
            if (!localStorage_GET_ASSET_CATEGORIES) {

                const getAssetsCategories = GET_ASSET_CATEGORIES;
                this.props.client.query({
                    query: getAssetsCategories,
                }).then(result => {
                    localStorage.setItem('GET_ASSET_CATEGORIES', JSON.stringify(result));
                    this.handleGetAssetCategoriesResult(resolve, reject, filtering, result);
                });
            } else {
                let result = JSON.parse(localStorage_GET_ASSET_CATEGORIES);
                this.handleGetAssetCategoriesResult(resolve, reject, filtering, result);
            }

        })
    }


    handleGetAssetCategoriesResult = (resolve, reject, filtering, result) => {

        let filterAssetTypes = _.cloneDeep(result.data.assetCategories.edges)
        for (let assetCat of filterAssetTypes) {
            let filter = assetCat.node.assetTypes.edges.filter(e => e.node.isActive)
            assetCat.node.assetTypes.edges = filter
        }
        let removeAssetCat = filterAssetTypes.filter(e => e.node.assetTypes.edges.length > 0)
        let assetCategories = result.data.assetCategories.edges;
        if (this.props.typeList && !filtering) {
            let getListCategories = []
            if (this.props.typeList === 'sales') {
                let arrayAssets = assetCategories.filter(e => ASSET_SALES.includes(e.node.identifier))
                for (let catAsset of arrayAssets) {
                    getListCategories = getListCategories.concat(catAsset.node.assetTypes.edges)
                }
            } else {
                getListCategories = assetCategories.find(e => e.node.identifier === this.props.typeList).node.assetTypes.edges
            }
            if (this.props.typeList === 'online') {
                if (this.props.filterBy === 'games') {
                    getListCategories = getListCategories.filter(e => ASSET_GAME.includes(e.node.identifier))
                } else if (this.props.filterBy === 'application') {
                    getListCategories = getListCategories.filter(e => ASSET_APPLICATION.includes(e.node.identifier))
                } else {
                    getListCategories = getListCategories.filter(e => ASSET_MINISITE.includes(e.node.identifier))
                }
            }
            this.setState({
                assetTypeList: getListCategories.map(asset => asset.node.id)
            })
        }
        this.setState({
            activeCategories: removeAssetCat,
            categories: assetCategories,
            dataLayout: assetsConfig,
            ready: true,
        }, () => {
            let newTypeOf = filterAssetTypes.map(categorie => ({
                ...categorie.node,
                textButton: categorie.node.assetTypes.edges.length > 0 ? this.props.t("spread.active_assets.configure") : this.props.t("spread.active_assets.contactUs"),
                onClick: categorie.node.assetTypes.edges.length > 0 ? () => this.handleAssetTypes(categorie.node.id, categorie.node.libelle) : () => console.log('Buy button'),
                isLocaleImage: true,
                secondaryStyle: categorie.node.assetTypes.edges.length > 0 ? false : true,
                secondaryText: categorie.node.assetTypes.edges.length > 0 ? null : this.props.t("spread.active_assets.noAccess")
            }));

            this.setState({
                typeOf: newTypeOf
            }, () => {
                console.log(this.state.typeOf);
            })
            resolve()
        });

    }


    handleMediaPicker = (selected, stateName) => {
        this.handleInputChange(stateName, selected, null, this.state.currentLang);
    }

    handleGetAllAssets = () => {
        this.setState({
            filteredAssets: null
        });
        const getAllAssets = GET_FULL_ASSETS;
        let newList = [];

        this.props.client.query({
            query: getAllAssets,
            fetchPolicy: 'no-cache'
        }).then(result => {
            for (let category of result.data.assetCategories.edges) {
                for (let type of category.node.assetTypes.edges) {
                    let image = type.node.logo;
                    let assets = type.node.assets.edges;
                    if (assets.length > 0) {
                        newList = newList.concat(assets.map(e => ({
                            ...e,
                            categoryLogo: image,
                        })));
                    }
                }
            }
            this.setState({
                filteredAssets: newList,
                filteredCat: newList
            })
            if (newList.length > 0) {
                this.setState({
                    hasAssets: true
                })
            }
        })
    }

    handleChangeTab = async (event, newValue, id, category) => {
        let pagination = await Pagination.resetPagination(this.state.pagination.perPage);
        this.setState({ pagination });
        let listOfTypes = []
        this.props.startLoading();
        if (id) {
            if (category.assetTypes?.edges.length > 0) {
                for (let type of category.assetTypes.edges) {
                    listOfTypes.push(type.node.id)
                }
                this.setState({
                    idActiveCat: category.id,
                    assetTypeList: listOfTypes,
                }, () => this.handleGetAssets(false, true));
            } else {
                this.setState({
                    idActiveCat: id,
                    assetTypeList: [id],
                }, () => this.handleGetAssets(false, true));
            }
        } else {
            this.setState({
                assetTypeList: [],
                activeCategorie: ['Tout voir'],
                noResult: false
            }, () => {
                this.handleChange(null);
                this.handleGetAssets();
            });
        }
    };

    doneTyping = (stateName) => {

        if (stateName === 'asset_store_name') {
            this.setState({
                paramIdentifier: slugify(this.state[this.state.currentLang].asset_store_name, { replacement: '_', lower: true, remove: /[^\w\-\s]+/g })
            })
        }

        if (this.state.paramIdentifier) {
            request(`${process.env.REACT_APP_API}/unique/${this.typeTesting}/${this.state.paramIdentifier}`, 'get').then(
                (data) => {
                    if (data.success) {
                        eventService.fire({ stateName: 'paramIdentifier', errorMessage: this.props.t("spread.active_assets.userAlreadyUsed") });
                    }
                }
            );
        }
        this.forceUpdate();
    };

    checkIdentifier = (stateName) => {
        if (stateName === 'asset_store_name' || stateName === 'paramIdentifier') {
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(() => { this.doneTyping(stateName) }, 500);
        }
    };

    setValue = (stateName, value, translated) => {
        if (translated) {
            let values = this.state[this.state.currentLang];

            if (!values) {
                values = {};
            }

            values[stateName] = value;

            this.setState({
                [this.state.currentLang]: values,
            });
        } else {
            this.setState({
                [stateName]: value,
            }, async () => {
                if (stateName === 'groupAttribut') {
                    await this.prepareAttributes();
                    this.prepareAttributeValues();
                } else if (stateName === 'currency' && value) {
                    let getAssetData = this.state.assetsAddData
                    let findIndex = getAssetData.formConfig.children.findIndex(e => e.labelName === "Paramétrage")
                    let findIndexChild = getAssetData.formConfig.children[findIndex].optionsInputs.findIndex(e => e.stateName === "groupRate")
                    getAssetData.formConfig.children[findIndex].optionsInputs[findIndexChild].disabled = false
                    getAssetData.formConfig.children[findIndex].optionsInputs[findIndexChild].value = this.state.listGroupRates.filter(e => e.node.currency.id === this.state.currency).map((groupRate, index) => {
                        return ({
                            value: groupRate.node.id,
                            label: `${groupRate.node.code}`,
                        });
                    })
                }
            });
        }
        if (stateName === 'asset_store_name' || stateName === 'paramIdentifier') {
            this.checkIdentifier(stateName);
        }
    };

    handleError = (e) => {
        this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.error"));

        this.props.stopLoading();

        if (e.graphQLErrors) {
            for (let error of e.graphQLErrors) {
                console.error('ERROR', `${error.message} =>`, error.debugMessage);
            }
        }
    };

    handleSuccess = async () => {
        await this.handleGetAssets();
        await this.handleGetAssets(true);
        this.props.snack(ALERT_SUCCESS, this.props.t("spread.active_assets.toastSuccess"));

        this.handleToggleDrawer('openFormAdd');
        this.handleToggleDrawer('openTypesForm');
        if (this.state.openGamesForm === true) {
            this.handleToggleDrawer('openGamesForm');
        }
        this.handleToggleDrawer('form');
        this.resetState();

        this.props.stopLoading();
    };

    handleFormError = (stateName, error) => {
        let errors = this.state.errors;
        errors[stateName] = error;
        this.setState({ errors });
    };

    hasErrors = () => {
        if (this.state.errors) {
            for (let error in this.state.errors) {
                if (this.state.errors[error])
                    return true;
            }
        }

        return false;
    };

    saveAttributes = asset => {
        return new Promise(async (resolve, reject) => {
            let attributes = this.state.customAttributes;

            for (let attribute of attributes) {
                for (let locale of this.props.locales) {
                    let formValue = this.state[locale.node.code][attribute.node.identifier];
                    let isMedia = attribute.node.attributeType.input === 'image' || attribute.node.attributeType.input === 'file';

                    if (formValue && isMedia) {
                        /*if (!formValue.changed)
                            continue;*/

                        isMedia = true;
                        /*formValue   = formValue.file;*/
                    }

                    if (formValue) {
                        // let resultMedia = null;
                        /*if (isMedia) { 
                            let formData = new FormData();
     
                            formData.append('file', formValue);
                            formData.append('type', formValue.type);
                            formData.append('is_internal', false);
        
                            resultMedia = await axios(`${process.env.REACT_APP_API}/media-objects`, 'post', formData);
                        }*/

                        let variables = {
                            "value": attribute.node.attributeType.input === 'select' ? formValue : null,
                            "asset": asset,
                            "attribute": attribute.node.id,
                            "locale": locale.node.id,
                        };

                        if (isMedia)
                            variables.media = formValue.id;

                        if (!isMedia)
                            variables.value = formValue;

                        await this.props.client.mutate({
                            mutation: ADD_ASSET_DATA,
                            variables
                        });
                    }
                }
            }

            resolve();
        });
    };


    saveAssetExport = asset => {
        return new Promise(async (resolve, reject) => {
            let position = 0;
            for (let value of this.state.formattingValue) {
                let variables = {
                    "position": position,
                    "keyName": value.value,
                    "asset": asset
                };
                if (value.attr === "SKU") {
                    variables.keyValue = value.attr;
                } else {
                    variables.attribute = value.attr;
                }

                await this.props.client.mutate({
                    mutation: ADD_ASSET_EXPORT,
                    variables
                });

                position++;
            }

            resolve();
        });
    };

    resetState() {
        this.setState({
            catalogSelected: null,
            retailerSelected: null,
            channelSelected: null,
            assetsSelected: null,
            paramIdentifier: null,
            paramStartAt: null,
            paramEndAt: null,
            paramStatus: true,
            stageUrl: null,
            stageHostUrl: null,
            prodUrl: null,
            prodHostUrl: null,
            assetLangSelected: [],
            // getAllCatalogs      : [],
            // getAllRetailers     : [],
            // paramUrl        : null,
            assetGameType: null,
            pageCatalog: 1,
            pageRetailer: 1,
            cursorRetailers: false,
            cursorCatalogs: false,
            currency: null,
            groupRate: null
            // pageCatalog     : 1,
        }, () => this.prepareAttributeValues(),
            this.handleGetCatalogs(),
            this.handleGetRetailers());

    }

    handlerMutation = async () => {
        try {
            if (this.hasErrors()) {
                this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.toastCheckField"));
                this.setState({ seeErrors: true });
                return eventService.fire();
            }


            this.props.startLoading();
            let variables = {
                'identifier': this.state.paramIdentifier,
                'assetType': this.state.assetType,
                'channel': this.state.channelSelected,
                'startAt': this.state.paramStartAt,
                'endAt': this.state.paramEndAt,
                'status': this.state.paramStatus,
                'updatedAt': moment().format(),
            }
            if (this.state.catalogSelected) {
                variables.catalog = this.state.catalogSelected;
            }
            if (this.state.retailerSelected) {
                variables.retailer = this.state.retailerSelected;
            }
            if (this.state.stageUrl !== null) {
                variables.stageUrl = this.state.stageUrl;
            }
            if (this.state.stageHostUrl !== null) {
                variables.stageHostUrl = this.state.stageHostUrl;
            }
            if (this.state.prodUrl !== null) {
                variables.prodUrl = this.state.prodUrl;
            }
            if (this.state.prodHostUrl !== null) {
                variables.prodHostUrl = this.state.prodHostUrl;
            }
            if (this.state.assetGameType) {
                variables.assetGameType = this.state.assetGameType;
            }
            if (this.state.assetLangSelected.length > 0) {
                if (this.state.currentAsset.multiLocale) {
                    variables.locales = this.state.assetLangSelected;
                } else {
                    let newArray = []
                    newArray.push(this.state.assetLangSelected)
                    variables.locales = newArray;
                }
            }
            if (this.state.currency) {
                variables.currencies = [this.state.currency];
            }
            if (this.state.groupRate) {
                variables.rateGroups = [this.state.groupRate];
            }
            if (this.state.assetsSelected.length > 0) {
                variables.assetWidgets = this.state.assetsSelected.map(e => e.node.id);
            }
            if (this.state.selectCompletenessGroup !== 'none') {
                variables.completenessGroup = this.state.selectCompletenessGroup;
            }

            const ADD_ASSET_RESULT = await this.props.client.mutate({
                mutation: ADD_ASSET,
                variables
            });

            // TODO: Check this
            let idNewAsset = ADD_ASSET_RESULT.data.createAsset.asset.id;

            await this.saveAttributes(idNewAsset);

            for (let assetTypesData of this.state.assetTypesData) {
                if (assetTypesData.id === this.state.assetType && assetTypesData.identifier === "customflux") {
                    await this.saveAssetExport(idNewAsset);
                }
            }

            if (this.state.formatUrl && this.state.formatUrl.length > 0) {
                const RESULT_CREATE_FORMAT_URL = await this.props.client.mutate({
                    mutation: CREATE_FORMAT_URL,
                    variables: { delimiter: this.state.separatorFormattingUrl, asset: idNewAsset },
                });

                let idFormatUrl = RESULT_CREATE_FORMAT_URL.data.createFormatUrl.formatUrl.id;

                for (let item of this.state.formatUrl) {
                    const RESULT_ADD_FORMAT_URL_ATTRIBUTE = await this.props.client.mutate({
                        mutation: ADD_FORMAT_URL_ATTRIBUTE,
                        variables: { formatUrl: idFormatUrl, attribute: item.attr, position: parseInt(item.value) }
                    })
                }
                this.setState({
                    separatorFormattingUrl: '-',
                    formattingValueUrl: [],
                })
            }

            this.handleSuccess();
        } catch (e) {
            this.handleError(e);
        }
    };

    handleInputChange = (stateName, evt, custom, translated) => {
        const value = evt?.target?.value ?? evt;
        this.setValue(stateName, value, translated);
    };

    copyArrayOfObjects = array => array.map(a => ({ ...a })); // be careful, only breaks references at objects level

    handleSearch = async (type) => {
        this.props.startLoading();
        switch (type) {
            case 'catalog':
                await this.handleGetCatalogs();
                break;
            case 'retailer':
                await this.handleGetRetailers();
                break;
            case 'asset':
                await this.handleGetAssets();
                break;
            default:
                return null;
        }
        if (this.state.currentAsset) {
            this.handleAssetAdd(this.state.currentAsset, true);
        }
    }

    handleChange = (e, type) => {
        return new Promise((resolve, reject) => {
            if (type) {
                clearTimeout(this.typingSearchTimer);
                this.setState({ searchValue: e.target.value });
                this.typingSearchTimer = setTimeout(() => {
                    this.handleSearch(type);
                }, 500);
            } else {
                if (e?.target?.value) {
                    if (e.target.value !== "") {
                        clearTimeout(this.typingSearchTimer);
                        this.setState({ searchAsset: e.target.value });
                        this.typingSearchTimer = setTimeout(() => {
                            this.handleSearch('asset');
                        }, 500);
                    }

                    else {
                        this.setState({
                            filteredAssets: this.state.filteredCat
                        })
                    }
                }
            }
            resolve();
        })
    }

    handleAssetAdd = async (categorie, open) => {
        let getAssetData = this.state.getAssetData;
        if (this.state.getAllCatalogs.length === 0 && this.state.getAllRetailers.length === 0) {
            this.props.startLoading();
            await this.handleGetCursorsCatalogs();
            this.props.startLoading();
            await this.handleGetCursorsRetailers();
        }
        if (!open) {
            this.setState({
                currentAsset: categorie,
                assetType: categorie.id
            })
            getAssetData = this.state.allChannelsData.filter(e => e.node.attributeGroup.id === categorie.attributeGroup.id)
            this.handleToggleDrawer('openFormAdd');
        }

        this.setState({
            assetsAddData: assetsAddConfig(this.state, categorie, this.state.currentLang, this.props.locales, this.state.listCurrencies, getAssetData, this.state.customAttributes, this.state.getAllCatalogs, this.handleMediaPicker, false, this.state.getAllRetailers, null, this.state.getAllAssets, this.state.listGroupRates, this.state.selectGroup),
            layoutImage: categorie.logo,
            getAssetData: getAssetData
        })
    }

    handleAssetAddGame = async (game) => {
        if (this.state.getAllCatalogs.length === 0) {
            this.props.startLoading();
            await this.handleGetCursorsCatalogs();
        }
        this.setState({
            currentAsset: game,
            assetGameType: game.id
        })
        this.handleToggleDrawer('openFormAdd');
        game.hasCatalog = true;

        this.setState({
            assetsAddData: assetsAddConfig(this.state, game, this.state.currentLang, this.props.locales, this.state.listCurrencies, [], this.state.customAttributes, this.state.getAllCatalogs, this.handleMediaPicker, true, this.changePage, this.state.countPageCatalogs, this.state.pageCatalog, this.state.listGroupRates, this.state.selectGroup),
            layoutImage: game.logo
        })
    }

    handleRouteRedirection(assetType) {
        let redirectionRoute = '';
        switch (assetType) {
            case 'Instore':
                redirectionRoute = ROUTE_SALES_RETAILERS
                break;
            case 'Online':
                redirectionRoute = ROUTE_SALES_MARKETPLACES
                break;
            case 'Réseaux sociaux':
                redirectionRoute = ROUTE_COMMUNITIES_SOCIALS
                break;
            case 'Boutique en ligne':
                redirectionRoute = ROUTE_CHANNELS_CMS
                break;
            case 'CMS':
                redirectionRoute = ROUTE_CHANNELS_CMS
                break;
            case 'Mailer':
                redirectionRoute = ROUTE_COMMUNITIES_NEWSLETTERS
                break;
            default:
                return null;
        }
        this.setState({
            currentAssetRoute: redirectionRoute
        })
    }

    handleAssetTypes = (categorie, libelleAssetType) => {
        let gameTypes = [];

        this.setState({
            currentAssetType: libelleAssetType
        }, () => this.handleRouteRedirection(this.state.currentAssetType));

        const getAssetsGameTypes = GET_GAME_TYPE;
        this.props.client.query({
            query: getAssetsGameTypes,
        }).then(result => {
            gameTypes = [...result.data.assetGameTypes.edges]
            let allCat = this.state.categories;
            let targetCat = allCat.find(e => e.node.id === categorie);
            let types = targetCat.node.assetTypes.edges;
            let checkChannel = '';
            let gameDatas = gameTypes.map(game => (
                {
                    ...game.node,
                    logo: game.node.logo,
                    isLocaleImage: true,
                    onClick: () => this.handleAssetAddGame(game.node, false),
                }
            ))
            types = types.map(type => (
                checkChannel = this.state.allChannelsData.filter(e => e.node.attributeGroup.id === type.node.attributeGroup.id),
                {
                    ...type.node,
                    logo: type.node.logo,
                    textButton: type.node.channelRequired
                        ? checkChannel.length > 0
                            ? this.props.t("spread.active_assets.configure")
                            : this.props.t("spread.active_assets.createChannel")
                        : type.node.isActive
                            ? this.props.t("spread.active_assets.configure")
                            : this.props.t("spread.active_assets.contactUs"),
                    onClick: type.node.identifier !== 'jeu'
                        ? type.node.channelRequired
                            ? checkChannel.length > 0
                                ? () => this.handleAssetAdd(type.node)
                                : () => this.goTo(this.state.currentAssetRoute)
                            : type.node.isActive
                                ? () => this.handleAssetAdd(type.node, false)
                                : () => console.log('Nous contacter')
                        : () => this.handleToggleDrawer('openGamesForm', type.node),

                    isLocaleImage: true,
                    secondaryStyle: type.node.channelRequired
                        ? checkChannel.length > 0
                            ? false
                            : true
                        : type.node.isActive
                            ? false
                            : true,
                    secondaryText: type.node.channelRequired
                        ? checkChannel.length > 0
                            ? null
                            : this.props.t("spread.active_assets.configureChannel")
                        : type.node.isActive
                            ? null
                            : this.props.t("spread.active_assets.noAccess"),
                    isActive: type.node.isActive,
                }
            ));
            this.setState({
                assetTypesData: types,
                assetGamesData: gameDatas
            });
            this.handleToggleDrawer('openTypesForm');
        })
    }

    handleToggleDrawer = (drawer, type) => {
        if (drawer === 'form') {
            this.setState({
                openForm: !this.state.openForm,
                activeAddButton: !this.state.activeAddButton
            });
        }

        if (drawer === 'openTypesForm') {
            this.setState({
                openTypesForm: !this.state.openTypesForm
            });
        }

        if (drawer === 'openGamesForm') {
            this.setState({
                readyRetailers: type ? true : false,
                readyAssets: type ? true : false,
                openGamesForm: !this.state.openGamesForm,
                assetType: type ? type.id : null
            });
        }

        if (drawer === 'openFormAdd') {
            this.setState({
                openFormAdd: !this.state.openFormAdd,
                errors: {},
            });
        }
    }

    handleDrawerWidthChange = (width) => {
        this.setState({ drawerWidthModified: width });
    }

    handleButtonGroupChange = (stateName, value) => {
        this.setState({ [stateName]: value });
    };

    handleLang = (event) => {
        this.setState({ currentLang: event.target.value });
        this.forceUpdate();
    };

    handleGetCurrencies = () => {

        let localStorage_GET_CURRENCIES = localStorage.getItem('GET_CURRENCIES');
        if (!localStorage_GET_CURRENCIES) {
            this.props.client.query({
                query: GET_CURRENCIES,
                fetchPolicy: 'no-cache'
            }).then(result => {
                localStorage.setItem('GET_CURRENCIES', JSON.stringify(result));
                this.setState({
                    listCurrencies: result.data.currencies.edges,
                })
            });
        } else {
            let result = JSON.parse(localStorage_GET_CURRENCIES);
            this.setState({
                listCurrencies: result.data.currencies.edges,
            })
        }

    }

    handleGetGroupRates = () => {

        let localStorage_GET_RATE_GROUPS_LIGHT = localStorage.getItem('GET_RATE_GROUPS_LIGHT');
        if (!localStorage_GET_RATE_GROUPS_LIGHT) {
            this.props.client.query({
                query: GET_RATE_GROUPS_LIGHT,
                fetchPolicy: 'no-cache'
            }).then(result => {
                localStorage.setItem('GET_RATE_GROUPS_LIGHT', JSON.stringify(result));
                this.setState({
                    listGroupRates: result.data.rateGroups.edges,
                })
            });
        } else {
            let result = JSON.parse(localStorage_GET_RATE_GROUPS_LIGHT);
            this.setState({
                listGroupRates: result.data.rateGroups.edges,
            })
        }


    }

    handleGetCursorsAssets = () => {

        let localStorage_GET_ASSETS_CURSOR = localStorage.getItem('GET_ASSETS_CURSOR');
        if (!localStorage_GET_ASSETS_CURSOR) {
            this.props.client.query({
                query: GET_ASSETS_CURSOR,
                fetchPolicy: 'no-cache'
            }).then(result => {
                localStorage.setItem('GET_ASSETS_CURSOR', JSON.stringify(result));
                this.setState({
                    listCursors: result.data.assets.edges,
                    listCursorsWidgets: result.data.assets.edges,
                });
            });
        } else {
            let result = JSON.parse(localStorage_GET_ASSETS_CURSOR);
            this.setState({
                listCursors: result.data.assets.edges,
                listCursorsWidgets: result.data.assets.edges,
            });
        }


    }

    handleGetAssets = async (isWidget, filtering) => {
        if (!isWidget) {
            await this.handleGetAssetCategories(filtering);
            let paginationVars = await Pagination.paginationQueryData(this.state.pagination);

            let variables = { ...paginationVars };

            if (this.state.assetTypeList) {

                variables.assetType_list = this.state.assetTypeList
            }
            let getFilters = getItemsLocalStorage("ASSETS_FILTERS") || {};

            if (!getFilters[this.props.filterName]) {
                getFilters[this.props.filterName] = {
                    filters: {},
                    pagination: {}
                };
            }

            getFilters[this.props.filterName].filters.searchValue = this.state.searchValue;
            getFilters[this.props.filterName].filters.assetTypeList = this.state.assetTypeList;
            getFilters[this.props.filterName].pagination = this.state.pagination;

            setItemsLocalStorage("ASSETS_FILTERS", getFilters);
            this.props.client.query({
                query: GET_ASSETS_PAGINATION_LIGHT,
                variables,
                fetchPolicy: 'no-cache'
            }).then(async (result) => {
                localStorage.setItem('allAssets', JSON.stringify(result));

                let pagination = await Pagination.updatePaginationData(this.state.pagination, result.data.assets);
                this.setState({
                    pagination,
                    countPage: Math.ceil(result.data.assets.totalCount / this.state.nbperpage),
                    listAssets: result.data.assets.edges,
                });
                this.handleGetCursorsAssets();
                this.props.stopLoading();
            });

        }
        else {
            this.props.client.query({
                query: GET_ASSET_TYPE_WIDGET
            }).then(async (result) => {
                let allTypeWidget = result.data.assetTypes.edges.map(e => e.node.assets.edges);
                let arrayIdWidget = [];
                for (let type of allTypeWidget) {
                    for (let element of type) {
                        let stripType = element.node.id.replace('/api/assets/', '')
                        arrayIdWidget.push(stripType)
                    }
                }
                if (arrayIdWidget.length > 0) {
                    let paginationVars = await Pagination.paginationQueryData(this.state.paginationForm.asset);

                    let variables = { ...paginationVars };

                    variables.id_list = arrayIdWidget.length > 0 ? arrayIdWidget : [null];

                    this.props.client.query({
                        query: GET_ASSETS_PAGINATION,
                        variables,
                        fetchPolicy: 'no-cache'
                    }).then(async (result) => {
                        let paginationForm = this.state.paginationForm;
                        paginationForm.asset = await Pagination.updatePaginationData(this.state.paginationForm.asset, result.data.assets);
                        this.setState({
                            paginationForm,
                            countPageAssets: Math.ceil(result.data.assets.totalCount / this.state.nbperpageAssets),
                            getAllAssets: result.data.assets.edges,
                            readyAssets: true,
                        }, () => {
                            if (this.state.currentAsset) {
                                this.handleAssetAdd(this.state.currentAsset, true)
                            }
                        });
                        this.props.stopLoading();
                    })
                } else {
                    this.setState({
                        getAllAssets: [],
                        readyAssets: true,
                    });
                    this.props.stopLoading();
                }
            })
        }
    }

    handleGetCursorsRetailers = () => {
        return new Promise((resolve, reject) => {
            let localStorage_GET_RETAILERS_CURSOR = localStorage.getItem('GET_RETAILERS_CURSOR');
            if (!localStorage_GET_RETAILERS_CURSOR) {
                this.props.client.query({
                    query: GET_RETAILERS_CURSOR,
                    fetchPolicy: 'no-cache'
                }).then(result => {
                    localStorage.setItem('GET_RETAILERS_CURSOR', JSON.stringify(result));

                    this.props.client.query({
                        query: GET_RETAILERS_CURSOR,
                        fetchPolicy: 'no-cache'
                    }).then(result => {
                        this.setState({
                            listCursorsRetailers: result.data.retailers.edges
                        }, () => {
                            this.handleGetRetailers();
                            resolve();
                        });
                    })

                });
            } else {

                let result = JSON.parse(localStorage_GET_RETAILERS_CURSOR);

                this.setState({
                    listCursorsRetailers: result.data.retailers.edges
                }, () => {
                    this.handleGetRetailers();
                    resolve();
                });


            }
        });
    }

    handleGetRetailers = () => {
        return new Promise((resolve, reject) => {
            let variables =
            {
                "nbperpage": this.state.nbperpageRetailers,
            };
            if (this.state.cursorRetailers && this.state.listCursorsRetailers && this.state.cursorRetailers !== this.state.listCursorsRetailers[0].cursor) {
                variables.cursor = this.state.cursorRetailers;
            }
            if (this.state.searchValueRetailers !== '') {
                variables.libelle = this.state.searchValueRetailers;
                variables.description = this.state.searchValueRetailers;
            }
            this.props.client.query({
                query: GET_RETAILERS_PAGINATION,
                variables,
                fetchPolicy: 'no-cache'
            }).then(result => {
                this.setState({
                    countPageRetailers: Math.ceil(result.data.retailers.totalCount / this.state.nbperpageRetailers),
                    getAllRetailers: result.data.retailers.edges,
                    readyRetailers: true,
                });
                this.props.stopLoading();
                resolve();
            })
        });
    }

    handleGetCursorsCatalogs = () => {
        return new Promise((resolve, reject) => {
            let localStorage_GET_CATALOGS_CURSOR = localStorage.getItem('GET_CATALOGS_CURSOR');
            if (!localStorage_GET_CATALOGS_CURSOR) {
                this.props.client.query({
                    query: GET_CATALOGS_CURSOR,
                    fetchPolicy: 'no-cache'
                }).then(result => {
                    localStorage.setItem('GET_CATALOGS_CURSOR', JSON.stringify(result));

                    this.setState({
                        listCursorsCatalogs: result.data.catalogs.edges
                    }, async () => {
                        await this.handleGetCatalogs();
                        resolve()
                    });
                });
            } else {
                let result = JSON.parse(localStorage_GET_CATALOGS_CURSOR);
                this.setState({
                    listCursorsCatalogs: result.data.catalogs.edges
                }, async () => {
                    await this.handleGetCatalogs();
                    resolve()
                });
            }
        })
    }

    handleGetCatalogs = () => {
        return new Promise(async (resolve, reject) => {
            let paginationVars = await Pagination.paginationQueryData(this.state.paginationForm.catalog);

            let variables = { ...paginationVars };
            // if(this.state.cursorCatalogs && this.state.listCursorsCatalogs && this.state.cursorCatalogs !== this.state.listCursorsCatalogs[0].cursor){
            //     variables.cursor = this.state.cursorCatalogs;
            // }

            if (this.state.searchValueCatalogs !== '') {
                variables.libelle = this.state.searchValueCatalogs;
                variables.description = this.state.searchValueCatalogs;
            }

            this.props.client.query({
                query: GET_CATALOGS_PAGINATION,
                variables,
                fetchPolicy: 'no-cache'
            }).then(async result => {
                let pagination = await Pagination.updatePaginationData(this.state.paginationForm.catalog, result.data.catalogs);
                let newPagination = this.state.paginationForm
                newPagination['catalog'] = pagination
                this.setState({
                    paginationForm: newPagination,
                    countPageCatalogs: Math.ceil(result.data.catalogs.totalCount / this.state.nbperpageCatalogs),
                    getAllCatalogs: result.data.catalogs.edges,
                    noResult: result.data.catalogs.edges.length > 0 ? false : true,
                    readyCatalogs: true,
                    cursorCatalogs: result.data.catalogs.pageInfo
                })
                this.props.stopLoading();
                resolve();
            });
        });
    };

    changePage = async (newPage, type = false) => {
        if (type) {
            this.props.startLoading();
            let paginationForm = this.state.paginationForm;
            paginationForm[type] = await Pagination.changePage(this.state.paginationForm[type], newPage);
            this.setState({
                paginationForm
            }, async () => {
                switch (type) {
                    case 'catalog':
                        await this.handleGetCatalogs();
                        this.handleAssetAdd(this.state.currentAsset, true);
                        break;
                    case 'retailer':
                        await this.handleGetRetailers();
                        this.handleAssetAdd(this.state.currentAsset, true);
                        break;
                    case 'asset':
                        await this.handleGetAssets(true);
                        break;
                    default:
                        this.handleGetAssets();
                        break;
                }
            });
        }
        else {
            let pagination = await Pagination.changePage(this.state.pagination, newPage);
            this.setState({ pagination }, () => this.handleGetAssets());
        }
    };

    changePerPage = async (perPage, type = false) => {
        if (type) {
            let paginationForm = this.state.paginationForm;
            paginationForm[type] = await Pagination.updatePerPage(this.state.paginationForm[type], perPage);
            this.setState({
                paginationForm
            }, async () => {
                switch (type) {
                    case 'catalog':
                        await this.handleGetCatalogs();
                        this.handleAssetAdd(this.state.currentAsset, true);
                        break;
                    case 'retailer':
                        await this.handleGetRetailers();
                        this.handleAssetAdd(this.state.currentAsset, true);
                        break;
                    case 'asset':
                        await this.handleGetAssets(true);
                        break;
                    default:
                        this.handleGetAssets();
                        break;
                }
            });
        }
        else {
            let pagination = await Pagination.updatePerPage(this.state.pagination, perPage);
            this.setState({ pagination }, () => { this.handleGetAssets() });
        }
    };

    handleGetCompletnessGroup = () => {

        let localStorage_GET_COMPLETENESSGROUPS = localStorage.getItem('GET_COMPLETENESSGROUPS');
        if (!localStorage_GET_COMPLETENESSGROUPS) {
            this.props.client.query({
                query: GET_COMPLETENESSGROUPS,
                fetchPolicy: "no-cache",
            }).then(result => {
                localStorage.setItem('GET_COMPLETENESSGROUPS', JSON.stringify(result));
                this.handleGetCompletnessGroupResult(result);
            });
        } else {
            let result = JSON.parse(localStorage_GET_COMPLETENESSGROUPS);
            this.handleGetCompletnessGroupResult(result);
        }
    }

    handleGetCompletnessGroupResult = (result) => {

        let selectGroup = {
            type: 'select',
            label: this.props.t("spread.active_assets.completenessGroup"),
            translated: false,
            helper: {
                text: this.props.t("spread.active_assets.selectCompletenessGroup"),
                link: false,
            },
            required: false,
            stateName: 'selectCompletenessGroup',
        };
        let selectGroupValues = [{ value: 'none', label: this.props.t("spread.active_assets.noGroup") }];

        for (let group of result.data.completenessGroups.edges) {
            selectGroupValues.push({ value: group.node.id, label: group.node.libelle });
        }

        selectGroup.value = selectGroupValues;
        this.setState({ listCompletenessGroup: result.data.completenessGroups, selectGroup, selectCompletenessGroup: 'none' });

    }

    handleFilters() {
        return new Promise((resolve, reject) => {
            let getFilters = getItemsLocalStorage("ASSETS_FILTERS")?.[this.props.filterName]?.filters;
            let getPagination = getItemsLocalStorage("ASSETS_FILTERS")?.[this.props.filterName]?.pagination;

            if (getFilters?.searchValue && getFilters.searchValue !== "") {
                this.setState({ searchValue: getFilters.searchValue });
            }
            if (getFilters?.assetTypeList && getFilters.assetTypeList.length > 0) {
                this.setState({ assetTypeList: getFilters.assetTypeList });
            }
            if (getPagination) {
                this.setState({ pagination: getPagination });
            }
            resolve();
        });
    }

    async componentDidMount() {
        checkRouting(this.props);
        if (getParams(this.props).includes('formOpen') || this.props.history?.location?.state?.formOpen) {
            this.handleToggleDrawer('form')
        }
        await this.handleFilters();
        await this.handleGetAssets();
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.typeList !== this.props.typeList || prevProps.filterBy !== this.props.filterBy) {
            let pagination = await Pagination.resetPagination(this.state.pagination.perPage);
            let getListCategories = []
            if (this.props.typeList === 'sales') {
                let arrayAssets = this.state.categories.filter(e => ASSET_SALES.includes(e.node.identifier))
                for (let catAsset of arrayAssets) {
                    getListCategories = getListCategories.concat(catAsset.node.assetTypes.edges)
                }
            } else {
                getListCategories = this.state.categories.find(e => e.node.identifier === this.props.typeList).node.assetTypes.edges
            }
            if (this.props.typeList === 'online') {
                if (this.props.filterBy === 'games') {
                    getListCategories = getListCategories.filter(e => ASSET_GAME.includes(e.node.identifier))
                } else {
                    getListCategories = getListCategories.filter(e => ASSET_MINISITE.includes(e.node.identifier))
                }
            }
            checkRouting(this.props);
            this.setState({
                pagination,
                listAssets: null,
                countPage: 0,
                assetTypeList: getListCategories.map(asset => asset.node.id)
            }, async () => {
                await this.handleFilters();
                await this.handleGetAssets(false, true);
            })
        }
        if (prevState.ready !== this.state.ready) {
            await this.handleGetAssets(true);
            this.handleGetEavTypes();
            this.prepareChannels();
            this.handleGetCurrencies();
            this.handleGetGroupRates();
            this.handleGetCompletnessGroup();
            if (this.props.history.location.state?.typeAsset) {
                this.handleAssetTypes(this.props.history.location.state.idCategorie, this.props.history.location.state.typeAsset)
            }
        }
        if (prevState.catalogSelected !== this.state.catalogSelected && this.state.catalogSelected !== null) {
            let variables = { "id": this.state.catalogSelected };
            this.props.client.query({
                query: GET_CATALOGS_ATTR_BY_ID,
                variables,
                fetchPolicy: 'no-cache'
            }).then(result => {
                this.setState({
                    catalogSelectedDatas: result.data,
                });
            });
        }
    }

    render() {        
        return (
            <Box style={{ height: 'calc(100% - 64px)', display: 'grid', gridTemplateRows: 'auto 1fr' }}>
                <TopPanel
                    icomoon={this.props.icon ? this.props.icon : "icon-asset"}
                    colorIcomoon={colors.blue.darker.hue300}
                    title={this.props.t(`drawer.${this.props.title}`)}
                    subtitle={this.props.t("spread.active_assets.assetManagement")}
                    gradientColor1={colors.menu.regular} gradientColor2={colors.menu.darker}
                    searchHandler={this.props.typeList === 'sales' ? null : (e) => this.handleChange(e, 'asset')}
                    searchValue={this.state.searchValue}
                    handlerAdd={() => { this.handleToggleDrawer('form') }} /* // TODO: Change this */
                    textAdd={this.props.typeList === 'sales' ? null : '+ ' + this.props.t(`spread.active_assets.addAsset.${this.props.title}`)}
                    buttonAvailable={this.state.categories ? this.state.activeAddButton : false}
                    windowWidth={this.props.windowWidth}
                    hasBorder={true}
                />
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    style={{ margin: "0 !important", width: '100%', height: '100%', display: 'grid', gridTemplateRows: 'auto 1fr' }}
                >
                    {/* <Grid
                        container
                        alignItems={this.props.windowWidth > 600 ? "center" : "flex-start"}
                        direction={this.props.windowWidth > 600 ? 'row' : 'column-reverse'}
                    // style={{background: 'white', borderBottom: `1px solid ${colors.grey.lighter.hue800}`}}
                    >
                        <Grid item xs={12}>
                            <TraductionSelect
                                currentLang={this.state.currentLang}
                                handleLang={this.handleLang}
                                locales={this.props.locales}
                            />
                        </Grid>
                    </Grid> */}
                    {/* <Grid item xs={2} style={{marginTop: 8, paddingRight: 16}}>
                        <TreeCategories query={GET_ASSET_CATEGORIES} resultVar={"assetCategories"} callbackCategorie={this.handleChangeTab} currentLang={this.state.currentLang} listType={this.props.typeList} filterBy={this.props.filterBy}/>
                    </Grid> */}
                    <Grid item xs={12} style={{ display: 'grid', gridTemplateRows: 'auto 1fr', position: 'relative' }}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="stretch"
                            spacing={2}
                            style={{ marginTop: 0 }}
                        >
                            {
                                this.state.listAssets ? (
                                    this.state.listAssets.length > 0 ? (
                                        this.state.listAssets.map((item, index) =>
                                            <GridCustom item lg={4} md={6} sm={6} xs={12} key={`ListProduct${index}`}>
                                                <CardCustom paddingbottom={0} paddingtop={0} style={{ width: "100%" }} contentpadding={'15px'}>
                                                    <CardAssets asset={item} inputCard={true} image={item.categoryLogo} currentLang={this.state.currentLang}></CardAssets>
                                                </CardCustom>
                                            </GridCustom>
                                        )
                                    ) : !this.state.hasAssets ? (
                                        <EmptyCard title={this.props.t("spread.active_assets.assetNotConfigured")} subtitle={this.props.t("spread.active_assets.clickToAdd")} textButton={this.props.t(`spread.active_assets.addAsset.${this.props.title}`)} onClick={() => { this.handleToggleDrawer('form') }} picto={EmptyAsset} openForm={this.state.openForm} xsImg={this.state.openForm ? 4 : 2} />
                                    ) : (
                                        <EmptyCard title={this.props.t("spread.active_assets.noResult")} subtitle={this.props.t("spread.active_assets.tryOther")} picto={EmptyAsset} xsImg={this.state.openForm ? 4 : 2} />
                                    )
                                ) : <PageLoader />
                            }
                        </Grid>
                        <Grid container alignItems='flex-end'>
                            {
                                this.state.ready && this.state.listAssets?.length > 0 ? (
                                    <Pagination.CursorPagination
                                        label={this.props.t("spread.active_assets.assetPerPage")}
                                        pagination={this.state.pagination}
                                        type="table"
                                        changePageCallback={this.changePage}
                                        perPageOptions={[6, 18, 30]}
                                        rowLabel={this.props.t("spread.active_assets.assetPerPageDot")}
                                        changePerPageCallback={this.changePerPage}
                                        onLeft={true}
                                    />
                                ) : null
                            }
                        </Grid>
                    </Grid>
                    {/* FIRST */}
                    {
                        this.state.dataLayout ?
                            (
                                <LayoutBuilder
                                    opened={this.state.openForm}
                                    isSublayout={false}
                                    forClose={() => { this.handleToggleDrawer('form') }}
                                    dataLayout={this.state.dataLayout}
                                    dataCard={this.state.typeOf}
                                />
                            ) : ''
                    }
                    {/* SECOND */}
                    {
                        this.state.assetTypesData ?
                            (
                                <LayoutBuilder
                                    opened={this.state.openTypesForm}
                                    isSublayout={true}
                                    forClose={() => { this.handleToggleDrawer('openTypesForm') }}
                                    dataLayout={this.state.dataLayout}
                                    dataCard={this.state.assetTypesData}
                                />
                            ) : ''
                    }

                    {
                        this.state.assetGamesData ?
                            (
                                <LayoutBuilder
                                    opened={this.state.openGamesForm}
                                    isSublayout={true}
                                    forClose={() => { this.handleToggleDrawer('openGamesForm') }}
                                    dataLayout={this.state.dataLayout}
                                    dataCard={this.state.assetGamesData}
                                />
                            ) : ''
                    }

                    {/* THIRD */}
                    {
                        this.state.assetsAddData && this.state.readyRetailers && this.state.readyCatalogs && this.state.readyAssets ?
                            (
                                <LayoutBuilder
                                    isSublayout={true}
                                    opened={this.state.openFormAdd}
                                    forClose={() => { this.handleToggleDrawer('openFormAdd') }}
                                    handleCancel={() => this.resetState()}
                                    dataLayout={this.state.assetsAddData}
                                    image={this.state.layoutImage}
                                    paginationInfo={{
                                        changePage: this.changePage,
                                        changePerPage: this.changePerPage,
                                        catalog: this.state.paginationForm.catalog,
                                        asset: this.state.paginationForm.asset,
                                        retailer: this.state.paginationForm.retailer,
                                    }}
                                    stepperButtonAction={[
                                        () => {
                                            if (this.hasErrors()) {
                                                this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.toastCheckField"));
                                                this.setState({
                                                    seeErrors: true,
                                                });
                                                eventService.fire();
                                                return false;
                                            }
                                            this.setState({
                                                searchValue: ''
                                            });

                                            this.handleDrawerWidthChange(-350)

                                            return true;
                                        },
                                        () => {
                                            if (this.hasErrors()) {
                                                this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.toastCheckField"));
                                                this.setState({
                                                    seeErrors: true,
                                                });
                                                eventService.fire();
                                                return false;
                                            }
                                            this.setState({
                                                searchValue: ''
                                            });
                                            return true;
                                        },
                                        () => {
                                            if (this.hasErrors()) {
                                                this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.toastCheckField"));
                                                this.setState({
                                                    seeErrors: true,
                                                });
                                                eventService.fire();
                                                return false;
                                            }
                                            this.setState({
                                                searchValue: ''
                                            });

                                            return true;
                                        },
                                        () => {
                                            if (this.hasErrors()) {
                                                this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.toastCheckField"));
                                                this.setState({
                                                    seeErrors: true,
                                                });
                                                eventService.fire();
                                                return false;
                                            }
                                            this.setState({
                                                searchValue: ''
                                            });

                                            return true;
                                        },
                                        () => {
                                            if (this.hasErrors()) {
                                                this.props.snack(ALERT_ERROR, this.props.t("spread.active_assets.toastCheckField"));
                                                this.setState({
                                                    seeErrors: true,
                                                });
                                                eventService.fire();
                                                return false;
                                            }
                                            this.setState({
                                                searchValue: ''
                                            });

                                            return true;
                                        }
                                    ]}
                                    backStepperButtonAction={[
                                        () => {
                                            this.setState({ errors: {} });
                                        },
                                        () => {
                                            this.setState({ errors: {} });
                                        },
                                        () => {
                                            this.setState({ errors: {} });
                                        },
                                        () => {
                                            this.setState({
                                                errors: {},
                                                searchValue: ''
                                            }, () => {
                                                this.props.startLoading();
                                                this.handleGetRetailers()
                                            });

                                        },
                                        () => {
                                            this.setState({
                                                errors: {},
                                                searchValue: ''
                                            }, () => {
                                                this.props.startLoading();
                                                this.handleGetCatalogs()
                                            });

                                        },
                                        () => {
                                            this.setState({ errors: {} });
                                        },
                                    ]}
                                    searchHandler={this.handleChange}
                                    allState={this.state}
                                    currentLang={this.state.currentLang}
                                    handleLang={this.handleLang}
                                    handlerMutation={this.handlerMutation}
                                    stateCallback={this.handleInputChange}
                                    errorCallback={this.handleFormError}
                                    handleButtonGroupChange={this.handleButtonGroupChange}
                                />
                            ) : ''
                    }
                </Grid>
            </Box>
        );
    }

    goTo = route => {
        this.props.history.push(route);
    };
}

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } })
    }
};

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        locales: state.locales,
    };
};

export default withTranslation()(withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(EngineAssets))));