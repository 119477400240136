import React from "react";

export default ({ data, preview, block }) => {
    var data;
    return(
        <div class="list unique">
            <div class="dotation">
                <div>
                    {
                        data.inputs.link.value ? (
                            <a href={data.inputs.link.value} target="_blank" rel="noopener noreferrer" style={{display: 'flex', justifyContent: data.inputs.alignItem?.value}}>
                                <img 
                                    style={{
                                        width: `${data.inputs.width.value}%`,
                                        maxWidth: `${data.inputs.maxWidth.value}px`,
                                        paddingTop: `${data.inputs.paddingTop.value}px`,
                                        paddingBottom: `${data.inputs.paddingBottom.value}px`,
                                    }}
                                    src={
                                        typeof data.inputs.image.value === 'string' && data.inputs.image.value !== '' ? 
                                            data.inputs.image.value : 
                                            data.inputs.image?.value?.filePath ? 
                                                ` ${process.env.REACT_APP_MEDIAS}/${data.inputs.image.value.filePath}` 
                                                : `http://via.placeholder.com/1920x600/FFFFFF/53839D?text=Image`
                                    }
                                    className={data.inputs.imageTablet.value && data.inputs.imageMobile.value ? 'onlyDesktop' : 'd-block'}
                                    alt={`Dotations`}
                                />
                                {
                                    data.inputs.imageTablet.value ? (
                                        <img 
                                            style={{
                                                width: `${data.inputs.width.value}%`,
                                                maxWidth: `${data.inputs.maxWidth.value}px`,
                                                paddingTop: `${data.inputs.paddingTop.value}px`,
                                                paddingBottom: `${data.inputs.paddingBottom.value}px`,
                                            }}
                                            src={
                                                typeof data.inputs.imageTablet.value === 'string' && data.inputs.imageTablet.value !== '' ? 
                                                    data.inputs.imageTablet.value : 
                                                    data.inputs.imageTablet?.value?.filePath ? 
                                                        ` ${process.env.REACT_APP_MEDIAS}/${data.inputs.imageTablet.value.filePath}` 
                                                        : `http://via.placeholder.com/600x800/FFFFFF/53839D?text=Image`
                                            }
                                            className={'onlyTablet'}
                                            alt={`Dotations`}
                                        />
                                    ) : null
                                }
                                {
                                    data.inputs.imageMobile.value ? (
                                        <img 
                                            style={{
                                                width: `${data.inputs.width.value}%`,
                                                maxWidth: `${data.inputs.maxWidth.value}px`,
                                                paddingTop: `${data.inputs.paddingTop.value}px`,
                                                paddingBottom: `${data.inputs.paddingBottom.value}px`,
                                            }}
                                            src={
                                                typeof data.inputs.imageMobile.value === 'string' && data.inputs.imageMobile.value !== '' ? 
                                                    data.inputs.imageMobile.value : 
                                                    data.inputs.imageMobile?.value?.filePath ? 
                                                        ` ${process.env.REACT_APP_MEDIAS}/${data.inputs.imageMobile.value.filePath}` 
                                                        : `http://via.placeholder.com/360x600/FFFFFF/53839D?text=Image`
                                            }
                                            className={'onlyMobile'}
                                            alt={`Dotations`}
                                        />
                                    ) : null
                                }
                            </a>
                        ) : (
                            <div style={{display: 'flex', justifyContent: data.inputs.alignItem?.value}}>
                                <img 
                                    style={{
                                        width: `${data.inputs.width.value}%`,
                                        maxWidth: `${data.inputs.maxWidth.value}px`,
                                        paddingTop: `${data.inputs.paddingTop.value}px`,
                                        paddingBottom: `${data.inputs.paddingBottom.value}px`,
                                    }}
                                    src={
                                        typeof data.inputs.image.value === 'string' && data.inputs.image.value !== '' ? 
                                            data.inputs.image.value : 
                                            data.inputs.image?.value?.filePath ? 
                                                ` ${process.env.REACT_APP_MEDIAS}/${data.inputs.image.value.filePath}` 
                                                : `http://via.placeholder.com/1920x600/FFFFFF/53839D?text=Image`
                                    }
                                    className={data.inputs.imageTablet.value && data.inputs.imageMobile.value ? 'onlyDesktop' : 'd-block'}
                                    alt={`Dotations`}
                                />
                                {
                                    data.inputs.imageTablet.value ? (
                                        <img 
                                            style={{
                                                width: `${data.inputs.width.value}%`,
                                                maxWidth: `${data.inputs.maxWidth.value}px`,
                                                paddingTop: `${data.inputs.paddingTop.value}px`,
                                                paddingBottom: `${data.inputs.paddingBottom.value}px`,
                                            }}
                                            src={
                                                typeof data.inputs.imageTablet.value === 'string' && data.inputs.imageTablet.value !== '' ? 
                                                    data.inputs.imageTablet.value : 
                                                    data.inputs.imageTablet?.value?.filePath ? 
                                                        ` ${process.env.REACT_APP_MEDIAS}/${data.inputs.imageTablet.value.filePath}` 
                                                        : `http://via.placeholder.com/600x800/FFFFFF/53839D?text=Image`
                                            }
                                            className={'onlyTablet'}
                                            alt={`Dotations`}
                                        />
                                    ) : null
                                }
                                {
                                    data.inputs.imageMobile.value ? (
                                        <img 
                                            style={{
                                                width: `${data.inputs.width.value}%`,
                                                maxWidth: `${data.inputs.maxWidth.value}px`,
                                                paddingTop: `${data.inputs.paddingTop.value}px`,
                                                paddingBottom: `${data.inputs.paddingBottom.value}px`,
                                            }}
                                            src={
                                                typeof data.inputs.imageMobile.value === 'string' && data.inputs.imageMobile.value !== '' ? 
                                                    data.inputs.imageMobile.value : 
                                                    data.inputs.imageMobile?.value?.filePath ? 
                                                        ` ${process.env.REACT_APP_MEDIAS}/${data.inputs.imageMobile.value.filePath}` 
                                                        : `http://via.placeholder.com/360x600/FFFFFF/53839D?text=Image`
                                            }
                                            className={'onlyMobile'}
                                            alt={`Dotations`}
                                        />
                                    ) : null
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
};
