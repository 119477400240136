import React from 'react';
import iconSet from "../../../assets/selection.json";
import IcomoonReact from "icomoon-react";
import muiIconList from './config/muiIconList.config';
import colors from '../../../config/theme/colors'

export default function Icon(props){
    const type = props.type ?? 'mui';
    let {icon,size,color,style,disabled} = props;
    
    color = disabled ? colors.grey.disabled : color;

    const renderMui = () =>{
        let MuiIcon = muiIconList[icon];
        return(
            <MuiIcon style={{color : color ?? colors.black.regular,fontSize : size ?? 'inherit',...style}} />
        )
    }
    return(
        type === 'mui' ?
            renderMui()
        : 
            <IcomoonReact iconSet={iconSet} color={color ?? colors.black.regular} size={size ?? 20} icon={icon} style={style}/>
    )

}