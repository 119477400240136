import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import ArrayHistory from './components/ArrayHistory';
import Typography from '../../../ui/typography/Typography';
import request from '../../../../js/utils/fetch';
import { checkRouting } from '../../../../js/utils/checkRouting';
import { Box, colors, Grid } from '@material-ui/core';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import { withTranslation } from 'react-i18next';
import { SET_GUIDELINE, SET_USER, SNACK, START_LOADING, STOP_LOADING } from '../../../../js/constants/action-types';
import EmptyCard from '../../../ui/empty-card/EmptyCard';

const ImportHistoryProduct = (props) => {
    const [rows, setRows] = useState(null);
    const [isLoaded, setIsLoaded] = useState();
    const [error, setError] = useState(null);

    useEffect(() => {
        checkRouting(props)
            request(`${process.env.REACT_APP_API}/scheduler/crons/product`, 'get', false)
            .then(
                (data) => {
                    if (data.success) {
                        setRows(data.crons)
                        console.log(data.cron)
                    }
                    setIsLoaded(true)
                }
            )
            .catch((err)=>{
                setError(err)    
                setIsLoaded(true)
            })        
    }, [])


    return (
        <div style={{ width: "100%", display: 'grid', gridTemplateRows: 'auto 1fr', height: 'calc(100% - 64px)' }}>
            {
                props.used === "dashboard" ?
                    <Typography>{props.t('drawer.history_imports')}</Typography>

                    : props.used === "history-product" ?
                        <TopPanel
                            title={props.t('drawer.history_imports_product')}
                            subtitle={"Liste des imports produits fait dans la plateforme par les utilisateurs"}
                            locales={props.locales}
                            hasBorder={true}
                        />
                        :
                        <TopPanel
                            title={props.t('drawer.history_imports')}
                            subtitle={"Liste des imports fait dans la plateforme par les utilisateurs"}
                            locales={props.locales}
                            hasBorder={true}
                        />
            }
                {isLoaded && !error ?
                    (
                        <Grid container style={{paddingTop: 8}}>
                            <ArrayHistory rows={rows} used={props.used} style={{ padding: 0 , boxShadow: 'none', background: 'transparent', margin: props.used === "page" ? '16px 32px' : null, width: props.used === "page" ? 'calc(100% - 64px)' : 'calc(100%)', display: 'grid', gridTemplateRows: '1fr auto' }} />
                        </Grid>
                    )
                    : error?
                        <EmptyCard
                            title={"Aucun historique disponible"}
                            // subtitle={this.props.t("spread.active_assets.clickToAdd")}
                            // textButton={process.env.REACT_APP_MODE_SPREAD !== "hub" ? this.props.t("catalogs.dashboard.createCatalog") : this.props.t("catalogs.catalogs.createWarehouse")}
                            // picto={EmptyCatalogue}
                            // onClick={() => this.handleFormAdd()}
                            // openForm={this.state.openForm}
                        />
                    : (
                        <Box style={{
                            position: 'relative',
                        }}>
                            <PageLoader />
                        </Box>
                    )
                }
        </div>
    );
}


const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        setUser: user => dispatch({ type: SET_USER, payload: { user } }),
        setGuideline: guideline => dispatch({ type: SET_GUIDELINE, payload: { guideline } }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } }),

    }
}

export default withTranslation()(withRouter(connect(null, mapDispatchToProps)(ImportHistoryProduct)));
