import { gql } from 'apollo-boost';

//----- Récupération des CATEGORIES MEDIA
export const GET_MEDIA_CATEGORIES = gql`
{
  mediaObjectCategories{
    edges{
      node{
        id
        libelle
        parent {
          id
          libelle
        }
      }
    }
  }
}
`;

//----- Récupération des CATEGORIES AVEC MEDIAS
export const GET_MEDIA_CATEGORIES_COMPLETE = gql`
{
  mediaObjectCategories {
    edges {
      node {
        id
        libelle
        parent {
          id
          libelle
        }
        mediaObjects {
          edges {
            node {
              id
              contentUrl
              filePath
              type
              isInternal
              size
            }
          }
        }
      }
    }
  }
}
`;



//----- Ajouter une catégorie media 
/*
 Exemple de variables
  {
    "libelle": "test",
    "parent": "/api/media-object-categories/2",
    "children": [""],
  }
*/

export const ADD_MEDIA_CATEGORY = gql`
mutation addMediaObjectCategory($libelle: String!, $parent: String, $children: [String]){
	createMediaObjectCategory(input: {libelle:$libelle, parent:$parent, children:$children}){
  	mediaObjectCategory{
      id
      libelle
      parent {
        id
        libelle
      }
    }
  }
}`;

//----- Editer une catégorie media 
/*
 Exemple de variables
  {
    "id": "/api/media-object-categories/3",
    "libelle": "Test edite",
    "parent": "/api/media-object-categories/2",
    "children": [""],
  }
*/

export const UPDATE_MEDIA_CATEGORY = gql`
mutation UpdateMediaObjectCategory($id:ID!, $libelle: String, $parent: String, $children: [String]){
	updateMediaObjectCategory(input: {id:$id,libelle:$libelle, parent:$parent, children:$children}){
  	mediaObjectCategory{
      id
      libelle
      parent {
        id
        libelle
      }
    }
  }
}`;


//----- Supprimer une catégorie media 
/*
 Exemple de variables
  {
    "id": "/api/media-object-categories/3"
  }
*/

export const DELETE_MEDIA_CATEGORY = gql`
mutation DeleteMediaObjectCategory($id:ID!){
	deleteMediaObjectCategory(input: {id:$id}){
  	mediaObjectCategory{
      id
    }
  }
}`;