import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import Typography from '../../../ui/typography/Typography';

class ProductsConnectors extends React.Component {
    constructor(props){
        super(props)
        this.state = {
        };
    }

    render() {
        return (
            <div>
                <Typography variant="h1">
                        Produits Connector's
                </Typography>
            </div>
        );
    }
    goTo = route => {
        this.props.history.push(route);
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};

export default withRouter(connect(mapStateToProps, null)(ProductsConnectors));
