import React from 'react';
import { withRouter } from 'react-router';
import { withApollo } from 'react-apollo';

import { connect } from "react-redux";
import Typography from '../../../ui/typography/Typography';
import {Grid, Box } from '@material-ui/core';
import colors from '../../../../config/theme/colors';
import Stats from '../../../layouts/Stats/Stats';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';

import CardMediaType from '../../../layouts/Card/cardContent/CardMediaType';
import CardActions from '../../../layouts/Card/cardContent/CardActions';
import CardCustom from '../../../layouts/Card/CardCustom';

import LastImage from './components/LastImage';
import MediaModal from './components/MediaModal';
import DataBar from './components/DataBar';
import { ROUTE_MEDIAS_CATEGORIES, ROUTE_HOME } from '../../../../js/constants/route-names';

import {GET_MEDIAS_IMAGES,GET_MEDIAS_COUNT} from '../../../../queries/medias';

import {ALLOWED,VIDEOS,IMAGES} from '../../../../js/constants/medias-types';

import request from '../../../../js/utils/fetch';

import { hasRights } from '../../../../js/utils/rights';
import { ALERT_ERROR } from '../../../../js/constants/alert-types';
import { SNACK } from '../../../../js/constants/action-types';
import { CRM_MEDIAS, CRM_MEDIAS_DASHBOARD,VIEW} from '../../../../js/constants/constant-rights';

class DashboardMedias extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            stockageSize: 50000000000,
            warningStorage: false,
            stockageCat: [
                {
                    graphSize:0,
                    name:'Image',
                    graphColor:'#58AFFD',
                    show:false,
                    mimetypes:IMAGES,
                },                
                {
                    graphSize:0,
                    name:'Fichiers Vidéos',
                    graphColor:'#1A2543',
                    show:false,
                    mimetypes:VIDEOS,
                },
            ],

            mediasCat: [
                {
                    title:'Mes images',
                    icon: 'picto-media',
                    types:IMAGES,
                    id:'images',
                    'route': ROUTE_MEDIAS_CATEGORIES,
                    'routeState': {types:IMAGES}
                },
                {
                    title:'Mes vidéos',
                    icon: 'picto-video',
                    types:VIDEOS,  
                    id:'video',
                    'route': ROUTE_MEDIAS_CATEGORIES,
                    'routeState': {types:VIDEOS}
                },
            ],

            stats: [
                {
                    'icon': 'picto-media',
                    'dataColor': colors.pink.regular,
                    subtitle: 'Images uploadée',
                    'gradientColor1': colors.pink.regular,
                    'gradientColor2': colors.pink.lighter,
                    'dataSize':'h3',
                    'notification':{
                        'notificationColor':colors.green.regular
                    },
                    info:'mediaCount',
                    types:IMAGES,
                },
                {
                    'icon': 'picto-video',
                    
                    'dataColor': colors.pink.regular,
                    subtitle: 'Vidéos uploadée',
                    'gradientColor1': colors.pink.regular,
                    'gradientColor2': colors.pink.lighter,
                    'dataSize':'h3',
                    'notification':{
                        'notificationColor':colors.green.regular
                    },
                    info:'mediaCount',
                    types:VIDEOS,
                },
                {
                    'icon': 'picto-media',
                    'dataColor': colors.pink.regular,
                    'subtitle': 'Dernier upload',
                    'gradientColor1': colors.pink.regular,
                    'gradientColor2': colors.pink.lighter,
                    'dataSize':'h3',
                    'notification':{
                        'notificationColor':colors.green.darker
                    },
                    info:'lastUpdate',
                    data:'19/09/2022'
                },
            ],
            medias:null,
            modalData:null,
            openModal:false
        };
        this.availablesMedias=[
            {
                id:'image',
                mime:IMAGES,
            },
            {
                id:'video',
                mime:VIDEOS,
            }
        ]

        this.actions=[
            {
                'title':'Importer un média',
                'icon': 'picto-dashboard-media',
                'data': '24/06/20 à 09:00',
                'dataColor': colors.pink.regular,
                'subtitle': 'Accédez à l’ajout de médias',
                'gradientColor1': colors.pink.regular,
                'gradientColor2': colors.pink.lighter,
                'route': ROUTE_MEDIAS_CATEGORIES,
                'routeState': {openMediaForm: true, buttonAvailable: true}
            },
            {
                'title':'Créer un répertoire de média',
                'icon': 'picto-dashboard-media',
                'data': '24/06/20 à 09:00',
                'marginTop': 16,
                'dataColor': colors.pink.regular,
                'subtitle': 'Catégorisez vos médias en créant un répertoire',
                'gradientColor1': '#1A2B4A',
                'gradientColor2': '#1A7EAC',
                'route': ROUTE_MEDIAS_CATEGORIES,
                'routeState': {openForm: true, buttonAvailable: true}
            },
        ]
        
    }

    handleMediaModal = (media,index,isNav=false) =>{
        let modalData = {
            ...media.node,
            index : index
        }

        if(!isNav){
            this.setState({ openModal: !this.state.openModal })  
        }
        this.setState({ modalData: modalData})
    }

    handleNextMedia = (step,index) => {
        let next;

        switch(step){
            case 'next':
                next = index +1;
                break;

            case 'prev':
                next = index-1;
                break;

            default :
                next = next;
                break;
        }
        
        let media = this.state.medias[next];
        this.handleMediaModal(media,next,true);
    }

    handleCloseModal = () => {
        this.setState({ openModal : !this.state.openModal })
    }


    getLastMedias=async()=>{
        //4 DERNIERS MEDIAS
        let getMedias    = GET_MEDIAS_IMAGES;
        let variables = {
            first : 4,
            types:['image/png','image/jpeg','image/jpg','video/mpeg','video/webm','video/mp4']
        }
        this.props.client.query({
            query:getMedias ,
            fetchPolicy:'no-cache',
            variables : variables
        }).then( result =>{
            if(result.data.mediaObjects.edges.length>0){
                this.setState({
                    medias  : result.data.mediaObjects.edges,
                })                
            }
        });      
    }
    
    getMediasCount=async()=>{
        let mediasCat=[];
        for(let cat of this.state.mediasCat){
            await this.getCount(cat.types).then(result=>{
                mediasCat.push({
                    ...cat,
                    count:result
                })
            });
            
        }
        this.setState({
            mediasCat : mediasCat
        })
    }
    getStats=async()=>{
        let stats=[];
        for(let stat of this.state.stats){
            switch(stat.info){
                case 'mediaCount':
                    await this.getCount(stat.types).then(result=>{
                        let text=stat.subtitle+`${result>1?'s':''}`;
                        stats.push({
                            ...stat,
                            data:result,
                            subtitle:text
                        })
                    });
                    break;

                /*case 'lastUpdate':
                    await this.getLastUpdate().then(result=>{
                        stats.push({
                            ...stat,
                            data:result
                        })
                    });*/
                default :
                stats.push({
                    ...stat,
                    
                })
                break;

            }

            
        }
        this.setState({ stats });
    }
    getCount=async(type)=>{
        return new Promise(async (resolve, reject) => {
            const result = await this.props.client.query({
                query:GET_MEDIAS_COUNT ,
                fetchPolicy:'no-cache',
                variables : {
                    types:type
                }
            });
            
            resolve(result.data.mediaObjects.totalCount);
        });
    }


    getLastUpdate=async()=>{
        return new Promise(async (resolve, reject) => {
            const result = await this.props.client.query({
                query:GET_MEDIAS_IMAGES ,
                fetchPolicy:'no-cache',
                variables : {
                    first:1,
                    types : ALLOWED
                }
            });
        });
    }

    getStorage=async()=>{
        request(`https://spread-api.dev.sinfin.fr/api/media-objects-size`).then(async (data) => {
            let result=data.response;
            let storages=this.state.stockageCat;
            let storageStats=[];
            for( let storage of storages){
                let size=0;
                for (let mimetype of storage.mimetypes){ 
                    if(typeof result[mimetype] !== 'undefined'){
                        size=size+result[mimetype];

                    }
                }
                storageStats.push({
                    ...storage,
                    size:size,
                    show:true
                })
            }

            this.setState({
                stockageCat:storageStats
            },()=>{
                this.handleGetStorageSize();
            })
        })
    }


    handleGetStorageSize(){
        let globalStockage=this.state.stockageSize;
        let files=this.state.stockageCat;
        let newList=[];
        let remainginSize=this.state.stockageSize;
        for (let file of files){
            let usedStockage=(100*file.size)/globalStockage;
            file['graphSize']=usedStockage;
            newList.push(file);
            remainginSize=remainginSize-file.size;
        }

        let warning=((remainginSize/globalStockage)<=0.1)?true:false;
        remainginSize=Math.round(remainginSize*100+Number.EPSILON)/100;
        this.setState({
            stockageCat:newList,
            remainginSize:remainginSize,    
            warningStorage:warning
        });
    }

    handleGetInfos= async()=>{
        this.getLastMedias();
        this.getMediasCount();
        this.getStats();
        this.getStorage();

    }



    componentDidMount(){
        const getRights = hasRights(CRM_MEDIAS, CRM_MEDIAS_DASHBOARD, VIEW);
        if (!getRights){
            this.props.snack(ALERT_ERROR, `Vous n'avez pas les droits suffisants pour accéder à cette page`);
            this.goTo(ROUTE_HOME);
        }else{
            /*this.handleGetStorageSize();*/
            this.handleGetInfos();
        }
    }

    render() {

        return (
            <div>
                <Typography variant="h1">
                        Dashboard Medias
                </Typography>
                <Typography variant="body1" component="div">
                    <Box pt={3} pb={2}>
                        Dernières datas
                    </Box>
                </Typography>
                <Grid container direction="row" spacing={6}>
                    
                        {this.state.stats.map((stat, index) =>
                            (
                                <Grid item key={`statsGrid${index}`}> 
                                    <Stats stat={stat} windowWidth={this.props.windowWidth}/>
                                </Grid>
                            )
                        )}
                    
                </Grid>
                <Grid container direction="column" justifyContent="center" spacing={0}>
                    <Grid container direction="row" spacing={4}>
                        <Grid item lg={3} md={12} style={{display: 'flex', flexDirection: 'column', paddingBottom: this.props.windowWidth < 1400 ? '0px' : '16px'}}>  
                            <Typography variant="body1" component="div">
                                <Box pt={3} pb={2}>
                                    Que voulez vous faire ?
                                </Box>
                            </Typography>
                            <Grid item xs={12} style={{display: 'flex', flexDirection: this.props.windowWidth < 1400 ? 'row' : 'column', justifyContent: 'start', flex: '1 1 auto'}}>
                                {   
                                    this.actions ? (this.actions.map((action,index)=>
                                        <Grid item lg={12} xs={12} key={`CardActions${index}`} style={{flexBasis: 'auto', marginRight: this.props.windowWidth < 1400 ? '20px' : '0px'}}>
                                            <CardCustom  style={{marginTop: this.props.windowWidth < 1400 ? '0px' : action.marginTop, background: `transparent linear-gradient(118deg, ${action.gradientColor1} 0%, ${action.gradientColor2} 100%) 0% 0% no-repeat padding-box`}}>
                                                <CardActions action={action} goTo={this.goToAction}></CardActions>
                                            </CardCustom>
                                        </Grid>
                                    )): null
                                } 
                            </Grid>
                        </Grid>
                        
                        <Grid item xs={8} lg={6} style={{paddingTop: this.props.windowWidth < 1400 ? '0px' : '16px'}}>
                            <Box pt={3} pb={2}>
                                <Typography variant="body1" component="div">
                                    Derniers médias importés
                                </Typography>
                            </Box>
                            <Grid item xs={12} height={600}>
                                <Box>
                                    {this.state.medias ?
                                        <LastImage lastMedias={this.state.medias} modal={this.handleMediaModal}></LastImage>
                                    : 
                                    <PageLoader/>
                                    }
                                    
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid item xs={4} lg={3} style={{display: 'flex', flexDirection: 'column', paddingTop: this.props.windowWidth < 1400 ? '0px' : '16px'}}>
                            <Box pt={3} pb={2}>
                                    <Typography variant="body1" component="div">
                                        Quel média voulez-vous voir ?
                                    </Typography>
                            </Box>
                            <Grid item xs={12} style={{display: 'flex', flexDirection: 'column', flex: '1 1 auto'}}>
                                {
                                this.state.mediasCat ? (this.state.mediasCat.map((item, index)=>
                                    <CardCustom  contentpadding={0} paddingbottom={'0px'} key={`CardMedia${index}`} style={{marginBottom:40}}>
                                        <CardMediaType media={item} goTo={this.goToAction} index={index}></CardMediaType>
                                    </CardCustom>
                                )): null
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <DataBar datas={this.state.stockageCat} remaining={this.state.remainginSize} warning={this.state.warningStorage}></DataBar>

                {this.state.modalData 
                        
                        ? 
                            <MediaModal
                                open                = {this.state.openModal}
                                onClose             = {this.handleCloseModal}
                                modalData           = {this.state.modalData}
                                handleNav           = {this.handleNextMedia}
                                lastMedia           = {this.state.medias.length-1}
                                handleDeleteModal   = {this.handleDeleteModal}
                                readOnly            = {true}
                                
                            />
                        
                             

                        : null}
            </div>
        );
    }
    goToAction = (route, state) => {
        this.props.history.push({
            pathname : route,
            state: state
        });
    };
    goTo = route => {
        this.props.history.push(route);
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};

const mapDispatchToProps = dispatch => {
    return {
    snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
}

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(DashboardMedias)));
