import React, {useEffect, useState} from "react";
import Button from '../../../../shared/components/CustomButton';
import H2 from "../../../../shared/components/CustomH2";
import PG from "../../../../shared/components/CustomParagraph";
import request from "../../../../../tools/Fetch";
import { getBackgroundImage } from "../../../../../utils/getBackgroundImage";

const GameQuizPersonality = (props) => {
    const [hover, setHover] = useState(false);
    const [hoverBlock, setHoverBlock] = useState(false);
    const [start, setStart] = useState(true);
    const [step, setStep] = useState(0);
    const [nextStepDisabled, setNextStepDisabled] = useState(true);
    const [selectedResponse, setSelectedResponse] = useState([]);
    const [classContainer, setClassContainer] = useState('show');
    const [finishDisabled, setFinishDisabled] = useState(true);
    const [cityResponse, setCityResponse] = useState([]);
    const [friendResponse, setFriendResponse] = useState([]);
    const [cityStep, setCityStep] = useState(0);
    const [friendStep, setFriendStep] = useState(0);

    let { 
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
        spread = false
    } = props;
    
    let image = data.inputs['backgroundImage'].value;
    let imageTablet = data.inputs['backgroundImageTablet']?.value;
    let imageMobile = data.inputs['backgroundImageMobile']?.value;
    let image2 = data.blocks.middleBox.inputs['backgroundImage'].value;
    let image3 = data.blocks.popupStart.inputs['backgroundImage'].value;

    let border = null;
    if (data.blocks.middleBox.inputs.border) {
        border = data.blocks.middleBox.inputs.border;
    }
    let borderPopup = null;
    if (data.blocks.popupStart.inputs.border) {
        borderPopup = data.blocks.popupStart.inputs.border;
    }

    const getResultQuiz = () => {
        let arr = [...selectedResponse];        
        let mostResponse = arr.sort((a,b) => arr.filter(v => v===a).length - arr.filter(v => v===b).length).pop();
        let result = data.blocks.result.config.find(e => e.blocks.result.inputs.allQuestion.value === mostResponse && e.blocks.result.inputs.firstQuestion.value === selectedResponse[0]);
        let customData = {reponse: selectedResponse, dotation: result.blocks.result.inputs.value.value}; 
        
        if(finishDisabled){
            setFinishDisabled(false);
            
            let dataPlay = new FormData();
            dataPlay.append('username', localStorage.getItem('username'));
            dataPlay.append('token', process.env.REACT_APP_ASSET_TOKEN);
            dataPlay.append('link1', window.link1);
            dataPlay.append('link2', window.link1);

            let datas = new FormData;
            datas.append('username', localStorage.getItem('username'));
            datas.append('token', process.env.REACT_APP_ASSET_TOKEN);
            datas.append('customDataResponse', JSON.stringify(customData));
            try {
                request(`${process.env.REACT_APP_API}/public/asset/registration/play`, 'post', dataPlay, 'multipart/form-data').then(result => {
                    request(`${process.env.REACT_APP_API}/public/asset/registration/add/custom-data`, 'post', datas, 'multipart/form-data')
                })
            }catch(e){
                console.log(e);   
            }
        }
        return(
            <div className="result">
                <img 
                    src={
                        typeof result.blocks.result.inputs.img.value === 'string' ? 
                            result.blocks.result.inputs.img.value : 
                            result.blocks.result.inputs.img?.value?.filePath ? 
                                ` ${process.env.REACT_APP_MEDIAS}/${result.blocks.result.inputs.img.value.filePath}` 
                                : "http://via.placeholder.com/170x80/FFFFFF/53839D?text=Image"
                    } 
                />
                <p>{result.blocks.result.inputs.value.value}</p>
            </div>
        )
    }

    const getResultQuizCityFriends = () => {                
        const getMajority =(array)=> {            
            var modeMap = {},
              maxEl = array[0],
              maxCount = 1;        
            for (var i = 0; i < array.length; i++) {
              var el = array[i];          
              if (modeMap[el] == null) modeMap[el] = 1;
              else modeMap[el]++;          
              if (modeMap[el] > maxCount) {
                maxEl = el;
                maxCount = modeMap[el];
              } else if (modeMap[el] == maxCount) {
                maxEl += "&" + el;
                maxCount = modeMap[el];
              }
            }
            return maxEl;
          }        
        

        const mostResponseCity = getMajority(cityResponse)
        const mostResponseFriends = getMajority(friendResponse)            

        let cityArray = data.blocks.result.config.filter(e => e.blocks.result.inputs.ref.value === 0);
        let friendArray = data.blocks.result.config.filter(e => e.blocks.result.inputs.ref.value === 1);
        let cityResult = cityArray.filter(e => e.blocks.result.inputs.allQuestion.value === Number(mostResponseCity.slice(0,1)) );
        let friendsResult = friendArray.filter(e => e.blocks.result.inputs.allQuestion.value === Number(mostResponseFriends.slice(0,1)));        
        let cityCustomData = {reponse: cityResponse, dotation: cityResult[0].blocks.result.inputs.value.value}; 
        let friendsCustomData = {reponse: friendResponse, dotation: friendsResult[0].blocks.result.inputs.value.value}; 
        
        
        if(finishDisabled){
            setFinishDisabled(false);
            
            let dataPlay = new FormData();
            dataPlay.append('username', localStorage.getItem('username'));
            dataPlay.append('token', process.env.REACT_APP_ASSET_TOKEN);
            dataPlay.append('link1', window.link1);
            dataPlay.append('link2', window.link2);
            dataPlay.append('link3', window.link3);

            let datas = new FormData;
            datas.append('username', localStorage.getItem('username'));
            datas.append('token', process.env.REACT_APP_ASSET_TOKEN);
            datas.append('customDataResponse', JSON.stringify({
                cityCustomData,
                friendsCustomData
            }));
            try {
                request(`${process.env.REACT_APP_API}/public/asset/registration/play`, 'post', dataPlay, 'multipart/form-data').then(result => {
                    request(`${process.env.REACT_APP_API}/public/asset/registration/add/custom-data`, 'post', datas, 'multipart/form-data')
                })
            }catch(e){
                console.log(e);   
            }
        }
        return(
            <div 
            className="result" 
            style={{
                display:"flex",
                gap:"20px",

            }}
            >
                <div 
                style={{
                    width:"50%"
                }}
                >
                    <img 
                        style={{
                            height:"200px",
                            objectFit:"contain",
                            marginBottom:"20px"
                        }}
                        src={
                            typeof cityResult[0].blocks.result.inputs.img.value === 'string' ? 
                            cityResult[0].blocks.result.inputs.img.value : 
                            cityResult[0].blocks.result.inputs.img?.value?.filePath ? 
                                    ` ${process.env.REACT_APP_MEDIAS}/${cityResult[0].blocks.result.inputs.img.value.filePath}` 
                                    : "http://via.placeholder.com/170x80/FFFFFF/53839D?text=Image"
                        } 
                    />
                    <p>{cityResult[0].blocks.result.inputs.value.value}</p>
                    {
                        cityResult[0].blocks.result.inputs.text
                        ?<p style={{fontSize:"14px",fontWeight:"normal",marginTop:"10px"}}>{cityResult[0].blocks.result.inputs.text.value}</p>
                        : null
                    }                    
                </div>
                <div
                 style={{
                    width:"50%"
                }}
                >
                    <img 
                        style={{
                            height:"200px",
                            objectFit:"contain",
                            marginBottom:"20px"
                        }}
                        src={
                            typeof friendsResult[0].blocks.result.inputs.img.value === 'string' ? 
                            friendsResult[0].blocks.result.inputs.img.value : 
                            friendsResult[0].blocks.result.inputs.img?.value?.filePath ? 
                                    ` ${process.env.REACT_APP_MEDIAS}/${friendsResult[0].blocks.result.inputs.img.value.filePath}` 
                                    : "http://via.placeholder.com/170x80/FFFFFF/53839D?text=Image"
                        } 
                    />
                    <p>{friendsResult[0].blocks.result.inputs.value.value}</p>
                    {
                        friendsResult[0].blocks.result.inputs.text 
                        ?<p style={{fontSize:"14px",fontWeight:"normal",marginTop:"10px"}}>{friendsResult[0].blocks.result.inputs.text.value}</p>
                        : null
                    }                    
                </div>
            </div>
        )
    }

    const updateSelectedResponse = (index, step) => {        
        const ref = data.blocks.step.config[step].blocks.question.inputs.ref.value 
        if (ref === 0) {
            let listResponse = [...cityResponse];            
            if (listResponse[cityStep]){
                listResponse[cityStep] = index.toString();
            }else{
                listResponse.push(index.toString())
            }
            setCityResponse(listResponse)            
        }else if (ref === 1) {            
            let listResponse = [...friendResponse];
            if (listResponse[friendStep]){
                listResponse[friendStep] = index.toString();
            }else{
                listResponse.push(index.toString())
            }
            setFriendResponse(listResponse)            
        }        
        let listResponse = [...selectedResponse];
        listResponse[step] = index;
        setSelectedResponse(listResponse);
        setNextStepDisabled(false);
    }
    
    const nextStep = () => {
        const ref = data.blocks.step.config[step].blocks.question.inputs.ref.value 
        ref === 0 ? setCityStep(cityStep+1): setFriendStep(friendStep+1)
        setNextStepDisabled(true)
        setClassContainer('hidden-block');
        setTimeout(() => {
            setStep(step+1); 
        }, 500);
        setTimeout(() => {
            setClassContainer('show');
        }, 1000);
    }

    useEffect(() => {
        if (spread)
            return;
        if(localStorage.getItem('username')){
            let data = new FormData();
            let currentSource = localStorage.getItem('GAME_SOURCE');
            data.append('source', currentSource);
            data.append('username', localStorage.getItem('username'));
            data.append('token', process.env.REACT_APP_ASSET_TOKEN);
            try {
                request(`${process.env.REACT_APP_API}/public/asset/games/${props.assetGameType}/registration/can_play`, 'post', data, 'multipart/form-data').then(response => {
                    if (response.code === "no_registration_for_asset"){
                        window.location = '/';
                    }else if(response.code === "email_not_validated"){
                        window.location = '/';
                    }else if (response.code === "already_played_today"){
                        window.location = '/already-played';
                    }
                    else if (response.code === "already_win"){
                        window.location = '/already-won';
                    }
                });
            } catch(e) {
                console.log(e);
            }
        }
        else{
            window.location = '/';
        }
    }, []);
    

    return (
        <div className="game_game" id="game_quiz">
            <section 
                style={{
                    backgroundColor: data.inputs['backgroundColor'].value,
                    backgroundImage:getBackgroundImage(image,imageTablet,imageMobile),
                    backgroundSize: "cover",
                    backgroundPosition: "top center",
                    backgroundRepeat: "no-repeat",
                    position: 'relative',
                    transition: 'all ease 500ms',
                }}
                onMouseEnter={() => {setHover(true); setHoverBlock(false)}} onMouseLeave={() => setHover(false)}
            >
                { !preview && hover && props.toolbarSection }
                {
                    start ? (
                        <div className="overlayPopupStart">
                            <div 
                                style={{
                                    backgroundColor: data.blocks.popupStart.inputs['backgroundColor'].value,
                                    backgroundImage: typeof image3 === 'string' ? `url(${image3})` : image3?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image3.filePath})` : 'none',
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    borderStyle: 'solid',
                                    borderWidth: borderPopup?.inputs?.borderWidth?.value ? borderPopup.inputs.borderWidth.value : '0px',
                                    borderColor: borderPopup?.inputs?.borderColor?.value ? borderPopup.inputs.borderColor.value : null,
                                    borderRadius: borderPopup?.inputs?.borderRadius?.value ? borderPopup.inputs.borderRadius.value : null,
                                    maxWidth: data.blocks.popupStart.inputs['maxWidth'].value,
                                    width: `${data.blocks.popupStart.inputs['width'].value}%`,
                                    paddingTop: data.blocks.popupStart.inputs['paddingTop'].value,
                                    paddingBottom: data.blocks.popupStart.inputs['paddingBottom'].value,
                                }}
                                onMouseEnter={() => {setHoverBlock(true); setHover(false)}} onMouseLeave={() => {setHoverBlock(false); setHover(true)}}
                            >
                                { !preview && hoverBlock && props.toolbarBlockStart }
                                <H2 data={data.blocks.popupStart} preview={preview} component={id} block={'titlePopup'} inputCallback={inputCallback} nopadding={true}/>
                                <PG data={data.blocks.popupStart} preview={preview} component={id} block={'descPopup'} inputCallback={inputCallback} nopadding={true}/>
                                {
                                    data.blocks.popupStart.blocks.imgPopup.inputs.value.value ? (
                                        <img 
                                            style={{margin: 'auto', display: 'block'}}
                                            src={
                                                typeof data.blocks.popupStart.blocks.imgPopup.inputs.value.value === 'string' ? 
                                                    data.blocks.popupStart.blocks.imgPopup.inputs.value.value : 
                                                    data.blocks.popupStart.blocks.imgPopup.inputs.value?.value?.filePath ? 
                                                        ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.popupStart.blocks.imgPopup.inputs.value.value.filePath}` 
                                                        : "http://via.placeholder.com/170x80/FFFFFF/53839D?text=Image"
                                            } 
                                        />
                                    ) : null
                                }
                                <Button data={data.blocks.popupStart} preview={preview} component={id} block={'buttonPopup'} className="as_button" onClick={() => {setStart(false); document.getElementById('top-game').scrollIntoView({behavior: 'smooth'});}} nopadding={true} />
                            </div>
                        </div>
                    ) : null
                }
                {
                    step < data.blocks.step.config.length ? (
                        <div className={`div_game ${classContainer} nb-step-${step}`} id="top-game">
                            <h2>{data.blocks.step.config[step].blocks.question.inputs.value.value ? data.blocks.step.config[step].blocks.question.inputs.value.value : 'Question'}</h2>
                            <div className={`container_response nb-${data.blocks.step.config[step].blocks.reponse.config.length}  ${data.inputs.questionType.value === "imageAndText" ? 'inline_answers':''}`}>
                                {data.blocks.step.config[step].blocks.reponse.config.map((e,i) => (                                                                 
                                    selectedResponse[step] === e.blocks.image.inputs.responseValue.value && e.blocks.image.inputs.hoverValue.value ? (
                                        <div onClick={() => updateSelectedResponse(e.blocks.image.inputs.responseValue.value, step)} key={`image-${i}-${e.blocks.image.id}`}>
                                            <img 
                                                src={
                                                    typeof e.blocks.image.inputs.hoverValue.value === 'string' ? 
                                                        e.blocks.image.inputs.hoverValue.value : 
                                                        e.blocks.image.inputs.hoverValue.value?.filePath ? 
                                                            ` ${process.env.REACT_APP_MEDIAS}/${e.blocks.image.inputs.hoverValue.value.filePath}` 
                                                            : "http://via.placeholder.com/170x80/FFFFFF/53839D?text=Image"
                                                }
                                            />
                                            {
                                                data.inputs.questionType.value === "imageAndText" && e.blocks.image.inputs.text
                                                ?<div className="active">
                                                    <p>{e.blocks.image.inputs.text.value}</p>
                                                    </div> 
                                                : null
                                            }                                            
                                        </div>
                                    ) 
                                    : e.blocks.image.inputs.value.value ? (
                                        <div onClick={() => updateSelectedResponse(e.blocks.image.inputs.responseValue.value, step)} key={`image-${i}-${e.blocks.image.id}`}>
                                            <img 
                                                src={
                                                    typeof e.blocks.image.inputs.value.value === 'string' ? 
                                                        e.blocks.image.inputs.value.value : 
                                                        e.blocks.image.inputs.value.value?.filePath ? 
                                                            ` ${process.env.REACT_APP_MEDIAS}/${e.blocks.image.inputs.value.value.filePath}` 
                                                            : "http://via.placeholder.com/170x80/FFFFFF/53839D?text=Image"
                                                }
                                            />
                                            {
                                                data.inputs.questionType.value === "imageAndText" && e.blocks.image.inputs.text
                                                ?<div>
                                                    <p>{e.blocks.image.inputs.text.value}</p>
                                                </div>
                                                : null
                                            }                                             
                                        </div>
                                    ) : (
                                        <div onClick={() => updateSelectedResponse(e.blocks.image.inputs.responseValue.value, step)} key={`image-${i}-`}>
                                            <img src="https://via.placeholder.com/300" />
                                        </div>
                                    )
                                ))}
                            </div>
                            <Button data={data} preview={preview} component={id} block={'buttonText'} inputCallback={inputCallback} className="as_button" nopadding={true} fullwidth={true} onClick={() => nextStep()} disabled={nextStepDisabled} />
                        </div>
                    ) : (
                        <div 
                            className="div_result"
                            style={{
                                backgroundColor: data.blocks.middleBox.inputs['backgroundColor'].value,
                                backgroundImage: typeof image2 === 'string' ? `url(${image2})` : image2?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image2.filePath})` : 'none',
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                borderStyle: 'solid',
                                borderWidth: border?.inputs?.borderWidth?.value ? border.inputs.borderWidth.value : '0px',
                                borderColor: border?.inputs?.borderColor?.value ? border.inputs.borderColor.value : null,
                                borderRadius: border?.inputs?.borderRadius?.value ? border.inputs.borderRadius.value : null,
                                position: 'relative',
                            }}
                            onMouseEnter={() => {setHoverBlock(true); setHover(false)}} onMouseLeave={() => {setHoverBlock(false); setHover(true)}}
                        >
                            { !preview && hoverBlock && props.toolbarBlock }
                            <div className="contain">
                                <H2 data={data.blocks.middleBox} preview={preview} component={id} block={'title'} inputCallback={inputCallback} nopadding={true}/>
                                <PG data={data.blocks.middleBox} preview={preview} component={id} block={'desc'} inputCallback={inputCallback} nopadding={true}/>
                                {getResultQuizCityFriends()}
                                <Button data={data.blocks.middleBox} preview={preview} component={id} block={'button'} inputCallback={inputCallback} className="as_button" nopadding={true} onClick={() => window.location = "/win"} disabled={finishDisabled} />
                            </div>
                        </div>
                    )
                }
                <p className="copyrightText"><span>Chaque boîte est vendue séparément. Piles non-incluses.<br/> ©2022 The LEGO Group. TM</span></p>
            </section>
        </div>
    );
};

export default GameQuizPersonality;
