import { gql } from 'apollo-boost';

//----- Récupération des merchandisings
export const GET_MERCHANDISINGS = gql`
query merchandisingGetAll ($category: String)  {
  merchandisings(category: $category){
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges{
      node{
        id
        category{
          id
          libelle
        }
        title
        status
        startAt
        endAt
      }
    }
  }
}
`;

//----- Récupération des merchandisings
export const GET_MERCHANDISINGS_PAGINATION = gql`
query merchandisingPagination ($nbperpage: Int, $cursor: String, $cursorLast: String, $id_list: [Int], $category: String){
  merchandisings (first: $nbperpage, after: $cursor, before: $cursorLast, id_list: $id_list, category: $category){
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges{
      node{
        id
        asset{
            id
        }
        category{
          id
          libelle
          categoryDatas{
            edges{
              node{
                value
                attribute{
                  identifier
                }
                media{
                  filePath
                }
                locale{
                  code
                }
              }
            }
          }
        }
        title
        status
        isActive
        isDefault
        startAt
        endAt
      }
    }
  }
}
`;

//----- Récupération des merchandisings inactifs
export const GET_MERCHANDISINGS_PAGINATION_INACTIVE = gql`
query merchandisingPaginationInactive ($nbperpage: Int, $cursor: String, $cursorLast: String, $id_list: [Int], $category: String){
  merchandisings (first: $nbperpage, after: $cursor, before: $cursorLast, id_list: $id_list, category: $category, isActive: false){
    totalCount
    pageInfo {
      startCursor
      endCursor
    }
    edges{
      node{
        id
        asset{
            id
        }
        category{
          id
          libelle
          categoryDatas{
            edges{
              node{
                value
                attribute{
                  identifier
                }
                media{
                  filePath
                }
                locale{
                  code
                }
              }
            }
          }
        }
        title
        status
        isActive
        isDefault
        startAt
        endAt
      }
    }
  }
}
`;

//----- Récupération des merchandisings isActive
export const GET_MERCHANDISINGS_ISACTIVE = gql`
query merchandisingIsActive ($category: String){
  merchandisings (first: 1, category: $category, isActive: true){
    edges{
      node{
        id
        category{
          id
          libelle
          categoryDatas{
            edges{
              node{
                value
                attribute{
                  identifier
                }
                media{
                  filePath
                }
                locale{
                  code
                }
              }
            }
          }
        }
        title
        status
        isActive
        isDefault
        startAt
        endAt
      }
    }
  }
}
`;

//----- Ajouter un merchandising
/*
 Exemple de variables
  {
  "category": "/api/categories/1",
  "title": "Test114",
  "status": true,
  "startAt": "2022-02-17",
  "endAt": "2022-02-18"
}
*/
export const CREATE_MERCHANDISING = gql`
mutation createMerchandising($category: String!, $asset:String, $title:String!, $status: Boolean!, $startAt: String, $endAt: String){
  createMerchandising(input: {category: $category, asset: $asset, title: $title, status: $status, startAt: $startAt, endAt: $endAt, isActive: false}) {
    merchandising{
      id
      category{
        id
      }
      title
      status
      startAt
      endAt
    }
  }
}
`;

//----- Modifier un merchandising
/*
 Exemple de variables
  {
    "id": "/api/merchandisings/3",
    "category" : "/api/categories/1",
    "title": "a new test",
    "status": false,
    "startAt": "2022-02-27",
    "endAt": "2022-02-28"
  }
*/
export const UPDATE_MERCHANDISING= gql`
mutation updateMerchandising($id: ID!, $category: String, $asset:String,  $title:String!, $status: Boolean!, $isActive: Boolean, $startAt: String, $endAt: String){
  updateMerchandising(input: {id: $id, category: $category, asset: $asset, title: $title, status: $status, isActive: $isActive, startAt: $startAt, endAt: $endAt}){
    merchandising{
      id
      category{
        id
      }
      title
      status
      startAt
      endAt
    }
  }
}
`;

//----- Supprimer un merchandising
/*
 Exemple de variables
  {
    "id" : "/api/merchandisings/3"
  }
*/
export const DELETE_MERCHANDISING = gql`
mutation deleteMerchandising($id: ID!){
  deleteMerchandising(input: {id:$id}){
    merchandising{
      id
    }
  }  
}
`;

//----- Récupération d'un seul merchandising
/*
 Exemple de variables
  {
    "id" : "/api/merchandisings/1"
  }
*/
export const GET_MERCHANDISING = gql`
query merchandising($id: ID!){
  merchandising(id: $id){
    id
    category{
      id
      libelle
    }
    merchandisingProducts(first: 48){
      edges{
        node{
          id
          position
          product{
            id
            sku
            flatProducts(locale: "fr_FR"){
              edges{
                node{
                  sku
                  name
                  image
                  imagesNb
                  completude
                }
              }          
            } 
            productDatas(first: 200){
              edges{
                node{
                  id
                  value
                  selection{
                    id
                    identifier
                  }
                  attribute{
                    identifier
                    isSystem
                  }
                  locale{
                    code
                  }
                  media{
                    id
                    filePath
                    type
                    size
                  }
                }
              }
            }
          }
        }
      }
    }
    merchandisingFilters{
      edges{
        node{
          id
          code
          sortBy
        }
      }
    }
    title
    status
    startAt
    endAt
  }
}
`;


//FILTERS MERCH
//----- Récupération des merchandisingsFilters
export const GET_MERCHANDISINGS_FILTERS = gql`
{
  merchandisingFilters{
    edges{
      node{
        id
        code
        value {
          id
        }
        sortBy
        position
        attribute{
          id
        }
        merchandising{
          id
        }
      }
    }
    pageInfo{
      endCursor
      startCursor
    }
  }
} 
`;

//----- Ajouter un merchandisingFilter
/*
 Exemple de variables
  {
    "code": "Testing Demo",
    "value": "/api/attribute-options/19",
    "sortBy": "ASC",
    "position": 5,
    "attribut": "/api/attributes/11",
    "merchandising": "/api/merchandisings/1"
  }
*/
export const CREATE_MERCHANDISING_FILTER = gql`
mutation createMerchandisingFilter($code: String!, $value: String, $sortBy: String, $position: Int!) {
  createMerchandisingFilter(input: {code: $code, value: $value, sortBy: $sortBy, position: $position}) {
    merchandisingFilter{
      id
      code
      value{
        id
      }
      sortBy
      position
      attribut{
        id
      }
      merchandising{
        id
      }
    }
  }
}
`;

//----- Modifier un MerchandisingFilter
/*
 Exemple de variables
  {
    "id": "/api/merchandising-filters/6",
    "code": "Demo 9.4",
    "value": "/api/attribute-options/17",
    "sortBy": "ASC",
    "position": 2,
  	"attribut": "/api/attributes/11",
    "merchandising": "/api/merchandisings/1"
  }
*/
export const UPDATE_MERCHANDISING_FILTER = gql`
mutation updateMerchandisingFilter($id: ID!, $code: String!, $value: String, $sortBy: String, $position: Int!, $attribut: String) {
  updateMerchandisingFilter(input: {id: $id, code: $code, value: $value, sortBy: $sortBy, position: $position, attribut: $attribut}) {
    merchandisingFilter{
      id
      code
      value{
        id
      }
      sortBy
      position
      attribut{
        id
      }
      merchandising{
        id
      }
    }
  }
}
`;

//----- Supprimer un MerchandisingFilter
/*
 Exemple de variables
  {
    "id" : "/api/merchandising-filters/4"
  }
*/
export const DELETE_MERCHANDISING_FILTER = gql`
mutation deleteMerchandisingFilter($id: ID!){
  deleteMerchandisingFilter(input: {id:$id}){
    merchandisingFilter{
      id
    }
  }  
}
`;

//----- Récupération d'un seul MerchandisingFilter
/*
 Exemple de variables
  {
    "id" : "/api/merchandising-filters/7"
  }
*/
export const GET_MERCHANDISING_FILTER = gql`
query merchandisingFilter($id: ID!){
  merchandisingFilter(id: $id){
    id
    code
    value{
      id
    }
    sortBy
    position
    attribut{
      id
    }
    merchandising{
      id
    }
  }
}
`;

//----- Récupération d'un ou plusieurs MerchandisingFilter depuis son Merchandising
/*
 Exemple de variables
  {
    "id" : "/api/merchandising/1"
  }
*/
export const GET_MERCHANDISING_FILTER_BY_MERCHANDISING = gql`
query merchandisingFiltersByMerchandising($id: ID!){
  merchandising(id: $id){
    id
    asset{
      id
    }
    category{
      id
      libelle
    }
    title
    status
    startAt
    endAt
    merchandisingFilters{
      edges{
        node{
          id
          code
          sortBy
          position
          attribut{
            id
          }
          value{
            id
          }
          merchandising{
            id
          }
        }
      }
    }
  }
}
`;