import {STEPPER} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';
import CatalogFinal from '../../../../../assets/pictos/empty-picto/catalogue.svg';

export default function catalogEdit(catalogData, handleMediaPicker, allTypes, inputAttributes){
    console.log('inputAttributes', inputAttributes)
    let inputAttributesArray = [...inputAttributes.edges]
    if(inputAttributesArray){
        inputAttributesArray.edges = inputAttributesArray.filter(e => e.node.internalFilter);
    }

    var obj = {
        titleForm: process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'Modifier le catalogue' : "Modifier l'entrepôt",
        subTitleForm: process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'Veuillez compléter les champs ci-dessous pour mettre à jour votre catalogue' : "Veuillez compléter les champs ci-dessous pour mettre à jour votre entrepôt",
        langSelect: false,
        drawerType: 'drawer',
        noForm: true,
        component: Button,
        formConfig: {
            type: STEPPER,
            finalStep: {
                picto: CatalogFinal,
                title: 'Et voilà !',
                subtitle: 'Vous pouvez confirmer votre modification',
                textButton: 'Enregistrer'
            },
            children:[
                {
                    labelName: 'Principal',
                    isOptionnal: false,
                    optionsInputs:[
                        {
                            type: 'mediaPicker',
                            label: process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'Entête de catalogue' : "Entête d'entrepôt",
                            helper: {
                                link: false,
                            },
                            required: false,
                            stateName: 'imageCatalog',
                            fullwidth: true,
                            handleMediaPicker:handleMediaPicker,
                        },
                        {
                            type: 'text',
                            label: 'Nom',
                            helper: {
                                text: process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'Indiquez le nom du catalogue' : "Indiquez le nom de l'entrepôt",
                                link: false,
                            },
                            required: true,
                            stateName: 'libelle',
                        },
                        {
                            type: 'text',
                            label: 'Identifiant',
                            helper: {
                                text: process.env.REACT_APP_MODE_SPREAD !== "hub" ? 'Indiquez l\'identifiant du catalogue' : "Indiquez l'identifiant de l'entrepôt",
                                link: false,
                            },
                            required: true,
                            disabled: true,
                            stateName: 'catalogIdentifier',
                        },
                        {
                            type: 'textarea',
                            label: 'Description',
                            helper: {
                                text: 'Entrez ici une description',
                                link: false,
                            },
                            required: false,
                            stateName: 'description',
                        },
                        {
                            type: 'buttonGroup',
                            label: 'État',
                            helper: {
                                label: 'État',
                                text: 'Indiquez l\'état',
                                link: false,
                            },
                            required: false,
                            stateName: 'status',
                            value: [
                                {
                                    value: true,
                                    label: 'Actif',
                                },
                                {
                                    value: false,
                                    label: 'Inactif',
                                },
                            ]
                        },
                        {
                            type: 'buttonGroup',
                            label: 'Autosync',
                            helper: {
                                label: 'Autosync',
                                text: 'Activer l\'autosync',
                                link: false,
                            },
                            required: false,
                            stateName: 'autosync',
                            value: [
                                {
                                    value: true,
                                    label: 'Actif',
                                },
                                {
                                    value: false,
                                    label: 'Inactif',
                                },
                            ]
                        },
                    ]
                },
                {
                    labelName: 'Filtres',
                    isOptionnal: false,
                    optionsInputs:[
                        {
                            type: 'rows',
                            translated: false,
                            required: false,
                            stateName: 'catalogFilters',
                            labelsNames:{
                                first: 'Attribut',
                                second: 'Opérateur',
                                third: 'Valeurs',
                            },
                            filter: true,
                            attributes: inputAttributesArray,
                            operators:[
                                {
                                    id: '=',
                                    label: '='
                                },
                                {
                                    id: '!=',
                                    label: '!='
                                },
                                {
                                    id: 'IN',
                                    label: 'IN'
                                },
                                {
                                    id: 'NOT IN',
                                    label: 'NOT IN'
                                },
                                {
                                    id: 'LIKE',
                                    label: 'LIKE'
                                },
                                {
                                    id: 'empty',
                                    label: 'empty'
                                }
                            ]
                        }
                    ]
                },
            ]
        }
    } 
    return obj;
}