import React from "react";
import Div from '../../../../shared/components/CustomDiv';
import H2 from '../../../../shared/components/CustomH2';
import H3 from '../../../../shared/components/CustomH3';

export default ({ data, preview, component, block, inputCallback, className }) => {
    var rules = data.blocks[block].config;
    return(
        <div class="regles special">
            <img class="img_center"
                onClick={() => inputCallback(component, 'rulesImage')} 
                src={
                    typeof data.blocks.rulesImage.inputs.value.value === 'string' && data.blocks.rulesImage.inputs.value.value !== '' ? 
                        data.blocks.rulesImage.inputs.value.value : 
                        data.blocks.rulesImage.inputs.value?.value?.filePath ? 
                            ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.rulesImage.inputs.value.value.filePath}` 
                            : `http://via.placeholder.com/600x300/FFFFFF/53839D?text=Image`
                }
                alt={`Règle illustration`}
            />
            <div>
                <H2 data={data} preview={preview} component={component} block={'rulesTitle'} inputCallback={inputCallback} />
                {
                    data.blocks.rulesSubtitle ?
                    (<H3 data={data} preview={preview} component={component} block={'rulesSubtitle'} inputCallback={inputCallback} />) : null
                }
                <div class="list_regles">
                    {
                        rules.map((rule, index) =>{
                            return(
                                <div class="regle">
                                    <div class="description">
                                        <Div data={rule} preview={preview} component={rule.blocks.text.id} block={'text'} inputCallback={() => inputCallback(component, block)} nopadding={true} />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>   
    )
};