import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDrop } from 'react-dnd';
import Div from '../../../../shared/components/CustomDiv';
import RulesModal from "./RulesModal";

const FooterGame = (props) => {
    const [scroll, setScroll] = useState(0);
    const [hover, setHover] = useState(false);
    const [headerTop, setHeaderTop] = useState(0);
    const [openRules, setOpenRules] = useState(false);

    const handleScroll = () => {
        setScroll(window.scrollY);
    };

    const [collectedProps, drop] = useDrop({
        accept: ['media'],
        drop: (item) => {
            data.inputs['backgroundImage'].value = item.media.node;
            update();
        }
    });


    let {
        preview = false,
        id = null, 
        data = null,
        noSticky,
        inputCallback = null,
        update = null,
        paramsGeneral
    } = props;

    function handleClick(e) {
        e.preventDefault();
        setOpenRules(true);
    }

    function closeModal(){
        setOpenRules(false);
        document.body.style.overflow = "auto";
    }

    useEffect(() => {
        const allLinks = document.querySelectorAll("a[href='#reglement_link']");
        for (let i = 0; i < allLinks.length; i++) {
            allLinks[i].addEventListener("click", function(e) {
                handleClick(e);
                document.body.style.overflow = "hidden";
            });
        }
    })

    let image = data.inputs['backgroundImage'].value;
    return (
        <footer 
            ref={drop}
            style={{
                backgroundColor: data.inputs['backgroundColor'].value,
                backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'top center',
                position: 'relative'
            }}
            onClick={() => inputCallback(id)}
            onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
        >
            { !preview && hover && props.children }
            <Div data={data} className="footer" preview={preview} component={id} block={'footerText'} inputCallback={inputCallback} nopadding={true} />
            {
                openRules ? 
                    <RulesModal isOpen={openRules} onClose={() => closeModal()} data={paramsGeneral}/>
                : null
            }
        </footer>
    );
};

FooterGame.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingClass: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default FooterGame;
