import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles({
    root: {
        width: '100%',
        marginBottom: 20
    },
    label: {
        color: '#000'
    },
    input: {
        width: '100%'
    },
    formControl: {
        width: '100%',
    },
});

export default function SelectInput(props) {
    const classes = useStyles();
    const handleInputChange = event => {
        props.onChange(event.target.value === '' ? '' : event.target.value);
    };

    return (
        <div className={classes.root}>
            <Typography gutterBottom style={{fontSize: 14}}>
                { props.label }
            </Typography>
            <FormControl variant="outlined" className={classes.formControl}>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={props.value}
                    onChange={handleInputChange}
                    style={{borderRadius: 0}}
                >
                    {props.params.map((param, i) => {
                        return <MenuItem value={param.value} key={i}>{param.label}</MenuItem>
                    })}
                </Select>
            </FormControl>
        </div>
    );
}
