import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Pg from '../../../../shared/components/CustomParagraph';
import moment from 'moment';

const HeaderGame = (props) => {
    const [scroll, setScroll] = useState(0);
    const [hover, setHover] = useState(false);
    const [headerTop, setHeaderTop] = useState(0);
    const handleScroll = () => {
        setScroll(window.scrollY);
    };
    
    
    let {
        preview = false,
        id = null, 
        data = null,
        noSticky,
        inputCallback = null,
        asset = null,
    } = props;

    let image = data.inputs['backgroundImage'].value

    return (
        <header onClick={() => inputCallback(id)}
            style={{
                backgroundColor: data.inputs['backgroundColor'].value,
                backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                backgroundSize: "cover",
                backgroundPosition: "bottom center",
                backgroundRepeat: "no-repeat",
                position: 'relative'
            }}
            onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
        >
            { !preview && hover && props.children }
            <div className={typeof data.blocks.logo?.inputs.value?.value === 'string' || data.blocks.logo?.inputs.value?.value?.filePath ? 'banner withlogo' : 'banner'}>
                {
                    typeof data.blocks.logo.inputs.value?.value === 'string' || data.blocks.logo.inputs.value?.value?.filePath ? (
                        <img className="logo" onClick={() => inputCallback(id, 'logo')} 
                            src={
                                typeof data.blocks.logo.inputs.value?.value === 'string' ? 
                                    data.blocks.logo.inputs.value.value : 
                                    data.blocks.logo.inputs.value?.value.filePath ? 
                                        ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.logo.inputs.value.value.filePath}` 
                                        : "http://via.placeholder.com/100x30/CCCCCC/AAAAAA?text=Logo"
                            }
                            alt="Logo jeu"
                        />
                    ) : null
                }
                <div style={{
                    fontSize: data.blocks['title'].inputs.size?.value,
                    color: data.blocks['title'].inputs.color?.value,
                    backgroundColor: data.blocks['title'].inputs.backgroundColor?.value,
                    lineHeight: '1.2'
                }}>
                    <Pg data={data} preview={preview} component={id} block={'title'} inputCallback={inputCallback} nopadding={true} /> 
                </div>
            </div>
        </header>
    );
};

HeaderGame.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingClass: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default HeaderGame;
