import { gql } from 'apollo-boost';

//----- Récupération des points
export const GET_POINTS = gql`
{
  points{
    edges{
      node{
        id
        createdAt
        updatedAt
        status
        pointDatas{
          edges{
            node{
              id
              value
              attribute{
                id
                identifier
              }
              locale{
                id
                code
                libelle
              }
              attributeOption{
                id
                identifier
              }
            }
          }
        }
        attributeGroup {
          id
          identifier
        }
      }
    }
  }
}
`;

export const GET_POINTS_BY_ID = gql`
query point($id: ID!){
  point(id: $id){
    id
    createdAt
    updatedAt
    status
    pointDatas{
      edges{
        node{
          id
          value
          attribute{
            id
            identifier
            attributeType{
              id
              input
            }
          }
          locale{
            id
            code
            libelle
          }
          media{
            id
            contentUrl
            description
            filePath
          }
          attributeOption{
            id
            identifier
          }
        }
      }
    }
    attributeGroup {
      id
      identifier
    }
  }
}
`;

/*
{
  "attributeGroup": "/api/attribute_groups/1",
  "pointDatas": ["/api/point_datas/1"],
  "created": "2020-01-01",
  "updatedAt": "2020-01-01",
  "status": true
}
 */
export const CREATE_POINTS = gql`
mutation createPoint($attributeGroup: String!, $pointDatas: [String], $created: String!, $updatedAt: String!, $status: Boolean!) {
  createPoint(input:{attributeGroup: $attributeGroup, pointDatas: $pointDatas, createdAt: $created, updatedAt: $updatedAt, status: $status}) {
    point{
      id
      createdAt
      updatedAt
      status
      pointDatas{
        edges{
          node{
            id
            value
            attribute{
              id
              identifier
              attributeType{
                id
                input
              }
            }
            locale{
              id
              code
              libelle
            }
            media{
              id
              contentUrl
              description
              filePath
            }
            attributeOption{
              id
              identifier
            }
          }
        }
      }
      attributeGroup {
        id
        identifier
      }
    }
  }
}
`;

/*
{
  "id": "/api/points/1",
  "attributeGroup": "/api/attribute_groups/1",
  "pointDatas": ["/api/point_datas/1"],
  "created": "2020-01-01",
  "updatedAt": "2020-01-01",
  "status": true
}
 */
export const UPDATE_POINTS = gql`
mutation updatePoint($id: ID!, $attributeGroup: String, $pointDatas: [String], $created: String, $updatedAt: String!, $status: Boolean!) {
  updatePoint(input:{id: $id, attributeGroup: $attributeGroup, pointDatas: $pointDatas, createdAt: $created, updatedAt: $updatedAt, status: $status}) {
    point{
      id
      createdAt
      updatedAt
      status
      pointDatas{
        edges{
          node{
            id
            value
            attribute{
              id
              identifier
              attributeType{
                id
                input
              }
            }
            locale{
              id
              code
              libelle
            }
            media{
              id
              contentUrl
              description
              filePath
            }
            attributeOption{
              id
              identifier
            }
          }
        }
      }
      attributeGroup{
        id
      }
    }
  }
}
`;

/*
{
  "id": "/api/points/1"
}
 */
export const DELETE_POINTS = gql`
mutation deletePoint($id: ID!) {
  deletePoint(input:{id: $id}) {
    point{
      id
    }
  }
}
`;

/*
{
  "id": "/api/points/1"
}
 */
export const GET_POINT_DATAS = gql`
query pointDatas($id: ID!) {
  points(id: $id){
    edges{
      node{
        id
        pointDatas{
          edges{
            node{
              id
              value
              media{
                id
                contentUrl
                description
              }
              attribute{
                id
                identifier
              }
              locale{
                id
                code
                libelle
              }
              attributeOption{
                id
                identifier
              }
            }
          }
        }
      }
    }
  }
}
`;

/*
{
  "media": "/api/medias/1",
  "value": "value",
  "point": "/api/points/1",
  "attribute": "/api/attributes/1",
  "attributeOption": "/api/attribute_options/1",
  "locale": "/api/locale/1"
}
 */
export const CREATE_POINT_DATAS = gql`
mutation createPointDatas($media: String, $value: String, $point: String!, $attribute: String!, $locale: String, $attributeOption: String) {
  createPointData(input: {media: $media, value: $value, point: $point, attribute: $attribute, locale: $locale, attributeOption: $attributeOption}){
    pointData{
      id
      value
      media{
        id
        contentUrl
        description
      }
      attribute{
        id
        identifier
      }
      locale{
        id
        code
        libelle
      }
      attributeOption{
        id
        identifier
      }
    }
  }
}
`;


/*
{
  "id": "/api/point_datas/1",
  "media": "/api/medias/1",
  "value": "value",
  "point": "/api/points/1",
  "attribute": "/api/attributes/1",
  "attributeOption": "/api/attribute_options/1",
  "locale": "/api/locale/1"
}
 */
export const UPDATE_POINT_DATAS = gql`
mutation updatePointDatas($id: ID!, $media: String, $value: String, $point: String!, $attribute: String!, $locale: String, $attributeOption: String) {
  updatePointData(input: {id: $id, media: $media, value: $value, point: $point, attribute: $attribute, locale: $locale, attributeOption: $attributeOption}){
    pointData{
      id
      value
      media{
        id
        contentUrl
        description
      }
      attribute{
        id
        identifier
      }
      locale{
        id
        code
        libelle
      }
      attributeOption{
        id
        identifier
      }
    }
  }
}
`;


/*
{
  "id": "/api/point_datas/1"
}
 */
export const DELETE_POINT_DATAS = gql`
mutation updatePointDatas($id: ID!) {
  deletePointData(input: {id: $id}){
    pointData{
      id
    }
  }
}
`;