import React, { useEffect, useState, useContext } from "react";
import Swiper from 'react-id-swiper';
import Pg from '../../../../shared/components/CustomParagraph';
import CustomImg from "../../../../shared/components/CustomImg";
import notFound from "../../../../../not-found.png";
import ninjago from "./ninjago.png";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Check from '@material-ui/icons/Check';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import _ from 'underscore';

class GiftFinder extends React.Component {
    state = {
        hover: false,
        nav: { prev: false, next: true },
        navResult: { prev: false, next: true },
        selectedCategories: [],
        filters: {},
        lastKnowCategories: null,
        lastKnowProducts: null,
        result: [],
        update: 0
    };

    componentDidUpdate() {
        let config = this.props.data.blocks['finder'].config;

        if (!config)
            return;

        if (this.swiper && config.categories?.length !== this.state.lastKnowCategories) {
            this.swiper.update();

            this.setState({ 
                nav: { prev: !this.swiper.isBeginning, next: !this.swiper.isEnd }, 
                lastKnowCategories: config.categories?.length
            });
        }

        if (this.swiperResult && this.state.result?.length !== this.state.lastKnowProducts) {
            this.swiperResult.update();

            this.setState({ 
                navResult: { prev: !this.swiperResult.isBeginning, next: !this.swiperResult.isEnd }, 
                lastKnowProducts: this.state.result?.length
            });
        }
    }

    onSlideChange = (e) => {
        if (this.swiper)
            this.setState({ nav: { prev: !this.swiper.isBeginning, next: !this.swiper.isEnd }});
    };

    onSlideChangeResult = (e) => {
        if (this.swiperResult)
            this.setState({ navResult: { prev: !this.swiperResult.isBeginning, next: !this.swiperResult.isEnd }});
    };

    goNext = () =>  {
        if (this.swiper) {
            this.swiper.slideNext();
            this.setState({ nav: { prev: !this.swiper.isBeginning, next: !this.swiper.isEnd }});
        }
    };

    goNextResult = () =>  {
        if (this.swiperResult) {
            this.swiperResult.slideNext();
            this.setState({ navResult: { prev: !this.swiperResult.isBeginning, next: !this.swiperResult.isEnd }});
        }
    };

    goPrev = () =>  {
        if (this.swiper) {
            this.swiper.slidePrev();
            this.setState({ nav: { prev: !this.swiper.isBeginning, next: !this.swiper.isEnd }});
        } 
    };

    goPrevResult = () =>  {
        if (this.swiperResult) {
            this.swiperResult.slidePrev();
            this.setState({ navResult: { prev: !this.swiperResult.isBeginning, next: !this.swiperResult.isEnd }});
        } 
    };

    search = () => {
        const { selectedCategories, filters } = this.state;

        let allProducts     = this.props.products;
        let collections     = [];

        if (selectedCategories?.length > 0) {
            allProducts = allProducts.filter(product => {
                for (let c of product.categories) {
                    if (selectedCategories.find(e => e.id === c))
                        return true;
                }

                return false;
            });
        }

        for (let filter in filters) {
            let obj = filters[filter];

            if (obj) {
                let type = obj.filter.attribute?.attributeType?.input;

                let ranges = obj.filter.ranges.filter(e => {
                    if (obj.selected[e.id])
                        return true;
                });

                let options = obj.filter.options.filter(e => {
                    if (obj.selected[e.id])
                        return true;
                });

                let filtered = allProducts.filter(product => {
                    let attribute = product.attributes[obj.filter.attribute?.identifier];

                    // no attribute 
                    if (!attribute) return false;

                    let value = attribute['fr_FR'];

                    // no value 
                    if (!value) return false;

                    let found = false;

                    if (type === "decimal") {
                        value = Number(value);

                        // not a number
                        if (isNaN(value)) return false;

                        for (let range of ranges)
                            if (value >= range.value && (range.end ? value <= range.end : true))
                                found = true;
                    } else if (type === "select") {
                        for (let option of options)
                            if (value === option.identifier)
                                found = true;
                    }

                    return found;
                });

                if (filtered.length > 0)
                    collections.push(filtered.map(e => e.id));
            }
        }

        let all = collections.length ? _.intersection(allProducts.map(e => e.id), ...collections) : allProducts.map(e => e.id);

        all = all.map(e => allProducts.find(f => f.id === e));

        if (all.length === 0)
            all = allProducts.slice(0, 30);
            
        this.setState({ result: all });
    };

    translateCategory = (identifier, attributes, lang, def, strict = false) => {
        if (!attributes) return null;

        let translations = attributes[identifier];
        let translation = translations ? translations[lang] : null;
    
        if (!translation)
            translation = translations ? translations['en_US'] : null; // try english
    
        return strict ? translation : translation || def;
    };

    render() {
        const { 
            id = null,
            data = null,
            categories = [],
            products = [],
            spread = false,
            preview = false,
            children = null,
            inputCallback = null,
            update = null,
            picker = null
        } = this.props;

        const { 
            hover,
            selectedCategories,
            nav,
            navResult,
            filters
        } = this.state;

        const params = {
            grabCursor: true,
            on: { slideChange: this.onSlideChange },
            breakpoints: {
                320: {
                    slidesPerView: 2,
                    spaceBetween: 0
                },
                500: {
                    slidesPerView: 5,
                    spaceBetween: 0
                },
                960: {
                    slidesPerView: 7,
                    spaceBetween: 0
                }
            }
        };

        const paramsResult = {
            grabCursor: true,
            slidesPerView: 4,
            on: { slideChange: this.onSlideChangeResult },
            containerClass: 'swiper-container-result',
            breakpoints: {
                320: {
                    slidesPerView: 2,
                    spaceBetween: 5
                },
                500: {
                    slidesPerView: 3,
                    spaceBetween: 10
                },
                960: {
                    slidesPerView: 4,
                    spaceBetween: 15
                }
            }
        };

        let image   = data.inputs['backgroundImage'].value;
        let config  = data.blocks['finder'].config;

        return (
            <div 
                className="builder-template-scope" 
                onMouseEnter={() => this.setState({ hover: true })} onMouseLeave={() => this.setState({ hover: false })} 
                style={{    
                    boxShadow: !preview && hover ? '#2B4553 0 0 0px 2px inset' : 'none',
                    backgroundColor: !preview ? '#eee' : '#fff',
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover',
                    marginBottom: 20,
                    position: 'relative',
                    width: 'calc(100% - 100px)',
                    padding: 50,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: data.inputs['backgroundColor'].value,
                    backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none'
                }}
            >
                { !preview && hover && children }
                
                <div className="finder-container">
                    <div className="top-presentation" style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: 20
                    }}>
                        <CustomImg data={data} preview={preview} component={id} block={'logo'} inputCallback={inputCallback} update={update} hover={hover} />
                        <Pg data={data} preview={preview} component={id} block={'title'} inputCallback={inputCallback} style={{
                            marginLeft: 20
                        }} />
                    </div>
    
                    <div className="finder-white-part"
                        onClick={() => inputCallback(id, 'finder')}
                    >
                        <div className="finder-left">
                            <div className="finder-categories"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    minHeight: 60
                                }}
                            > 
                                <Swiper 
                                    key="swiper"
                                    {...params}
                                    style={{ width: '100%' }}
                                    ref={(node) => {
                                        if (node?.swiper)
                                            this.swiper = node.swiper;
                                    }}
                                >
                                    {
                                        config && config.categories && config.categories.map((category, index) => {
                                            let selected    = selectedCategories.find(e => e.id === category.id) !== undefined;
                                            let image       = this.translateCategory('category_image', category.attributes, 'fr_FR', category.libelle, true);
                                            let label       = this.translateCategory('category_name', category.attributes, 'fr_FR', category.libelle);

                                            return (
                                                <div key={index} className="finder-category">
                                                    <div 
                                                        className={`finder-category-image ${selected ? 'selected' : ''}`}
                                                        title={label}
                                                        onClick={() => {
                                                            if (selected) {
                                                                this.setState({ selectedCategories: selectedCategories.filter(e => e.id !== category.id) });
                                                            } else {
                                                                selectedCategories.push(category);
                                                                this.setState({ selectedCategories: [...selectedCategories] });
                                                            }
                                                        }}
                                                    >
                                                        <img 
                                                            src={image ? `${process.env.REACT_APP_MEDIAS}/${image}` : notFound} 
                                                            style={{ width: '100%' }}
                                                            alt={label}
                                                        />
        
                                                        <div className="finder-selected" />
                                                    </div>
                                                </div>
                                            
                                            )
                                        })
                                    }
                                </Swiper>
        
                                <div className={`swiper-button-prev ${nav.prev ? '' : 'swiper-button-disabled'}`} onClick={this.goPrev}>
                                    <ChevronLeftIcon style={{ fontSize: 30, color: '#fff' }} />
                                </div>
        
                                <div className={`swiper-button-next ${nav.next ? '' : 'swiper-button-disabled'}`} onClick={this.goNext}>
                                    <ChevronRightIcon style={{ fontSize: 30, color: '#fff' }} />
                                </div>
                            </div>
        
                            <div className="filters">
                                {
                                    config && config.filters && config.filters.map((filter, i) => {
                                        let type        = filter.attribute?.attributeType.input;
                                        let isNumber    = type === 'decimal' || type === 'number';

                                        if (!type)
                                            return null;
        
                                        return (
                                            <div className="filter" key={i}>
                                                <p>{ filter.name }</p>
                                                <div className="btn-tags">
                                                    {
                                                        type === 'select' ? (
                                                            filter.options.map((option, j) => {
                                                                let data = option.translation?.translationDatas?.edges;

                                                                if (!data) return null;

                                                                let translation = data.find(t => t.node.locale.code === 'fr_FR');

                                                                if (!translation)
                                                                    translation = data.find(t => t.node.locale.code === 'en_US'); // try english

                                                                let value = translation?.node.value || option.identifier;
                                                                let selected = filters?.[filter.id]?.selected[option.id];

                                                                return (
                                                                    <button key={j} className={selected ? 'selected' : ''} onClick={() => {
                                                                        let filterSelected = filters[filter.id];

                                                                        filterSelected = filterSelected
                                                                            ? filterSelected
                                                                            : {
                                                                                filter,
                                                                                selected: {}
                                                                            };

                                                                        filterSelected.selected[option.id] = filterSelected.selected[option.id] ? false : true;
                                                                        filters[filter.id] = filterSelected;

                                                                        this.setState({ filters: {...filters} });
                                                                    }}>
                                                                        { value }
                                                                        <Check style={{
                                                                            display: selected ? 'inline' : 'none',
                                                                            position: 'absolute',
                                                                            top: -12,
                                                                            right: -10,
                                                                            color: 'green',
                                                                            fontSize: 30
                                                                        }} />
                                                                    </button>
                                                                );
                                                        })
                                                        ) : isNumber ? (
                                                            filter.ranges.map((range, j) => {
                                                                let selected = filters?.[filter.id]?.selected[range.id];

                                                                return (
                                                                    <button key={j} className={selected ? 'selected' : ''} onClick={() => {
                                                                        let filterSelected = filters[filter.id];

                                                                        filterSelected = filterSelected
                                                                            ? filterSelected
                                                                            : {
                                                                                filter,
                                                                                selected: {}
                                                                            };
        
                                                                        filterSelected.selected[range.id] = filterSelected.selected[range.id] ? false : true;
                                                                        filters[filter.id] = filterSelected;
        
                                                                        this.setState({ filters: {...filters} });
                                                                    }}>
                                                                        { range.display || 'n/a' }

                                                                        <Check style={{
                                                                            display: selected ? 'inline' : 'none',
                                                                            position: 'absolute',
                                                                            top: -12,
                                                                            right: -10,
                                                                            color: 'green',
                                                                            fontSize: 30
                                                                        }} />
                                                                    </button>
                                                                )
                                                            })
                                                        ) : null
                                                    }
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                        <div className="finder-right">
                            <button onClick={this.search}>Rechercher</button>
                        </div>
                    </div>

                    <div className="finder-result"  style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        minHeight: 60
                    }}>
                        {
                            this.state.result && this.state.result.length > 0 ? (
                                <Swiper 
                                    key="swiper-result"
                                    {...paramsResult}
                                    style={{ width: '100%' }}
                                    ref={(node) => {
                                        if (node?.swiper) {
                                            this.swiperResult = node.swiper;
                                        }
                                    }}
                                >
                                    {
                                        this.state.result && this.state.result.map((product, index) => {
                                            let title = this.translateCategory('product_name', product.attributes, 'fr_FR', product.sku, true);
                                            let image = this.translateCategory('product_image', product.attributes, 'fr_FR', product.sku, true);

                                            return (
                                                <div key={index} className="result-product">
                                                    <div className="result-product-image">
                                                        <img src={image ? `${process.env.REACT_APP_MEDIAS}/${image}` : notFound} />
                                                    </div>

                                                    <div className="bottom">
                                                        <span>{ title }</span>
                                                        <button>Ajouter</button>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </Swiper>
                            ) : null
                        }

                        {
                            this.state.result && this.state.result.length > 0 ? (
                                <>
                                    <div key={update} className={`swiper-result-button-prev ${navResult.prev ? '' : 'swiper-button-disabled'}`} onClick={this.goPrevResult}>
                                        <ChevronLeftIcon style={{ fontSize: 30, color: '#fff' }} />
                                    </div>

                                    <div key={update} className={`swiper-result-button-next ${navResult.next ? '' : 'swiper-button-disabled'}`} onClick={this.goNextResult}>
                                        <ChevronRightIcon style={{ fontSize: 30, color: '#fff' }} />
                                    </div>
                                </>
                            ) : null
                        }
                    </div>
                </div>
            </div>
        );
    }
};

export default GiftFinder;
