import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import request from "../../../../../tools/Fetch";
import H1 from "../../../../shared/components/CustomH1";
import Div from '../../../../shared/components/CustomDiv';
import Button from '../../../../shared/components/CustomButton';



const GameClicker = props=>{    
    const [hover, setHover] = useState(false);
    const [hoverBlock, setHoverBlock] = useState(false);
    const [endGame, setEndGame] = useState(false);
    const [clicks, setClicks] = useState(0);
    const [clickSpam, setClickSpam] = useState(0);
    const [isWin, setIsWin] = useState(false);
    const [hidePopup, setHidePopup] = useState(false);
    const ref = useRef()

    let { 
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
        spread = false
    } = props;    
    
    let image = data.inputs['backgroundImage'].value;  
    let popupImage =data.blocks.popup?.blocks?.image.inputs.value.value  
    let endGameBackgroundImageWin = data.blocks.backgroundImage_win.inputs.value.value
    let endGameBackgroundImageLoose = data.blocks.backgroundImage_loose.inputs.value.value

    const getBackgroundImageEndGame = (image)=>{
        if (typeof image === 'string') {
            return `url(${image})`
        }else if (image?.filePath){
            return `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})`
        }
        else return 'none'         
    }

    let border = null;
    if (data.blocks.popup.inputs.border) {
        border = data.blocks.popup.inputs.border;
    }

    let limit = data.blocks.number_clicks.inputs.value.value

    useEffect(()=>{
        if (clicks === Number(limit)) {
            setEndGame(true)
        }        
    },[clicks])   
    
    useEffect(()=>{
        let video = document.getElementById('result-video');        
        if (video) {
            video.onended = function(){
                window.location = isWin? '/win':'/loose';                                
            }        
        }        
    },[endGame])    

    
    
    function startGame(){
        setHidePopup(true)                        
            if (props.spread)
                return;

            let dataForm = new FormData();
            dataForm.append('username', localStorage.getItem('username'));
            dataForm.append('token', process.env.REACT_APP_ASSET_TOKEN);
            try {
                request(`${process.env.REACT_APP_API}/public/asset/games/instant/registration/has_win`, 'post', dataForm, 'multipart/form-data').then(response => {
                    console.log('response',response.code)
                    if (response.code === "has_win"){
                        setIsWin(true);
                        localStorage.setItem('dotation', JSON.stringify(response.productData));
                        localStorage.setItem('isVirtual', JSON.stringify(response.isVirtual));                                                
                    }else if(response.code === "has_lost"){
                        setIsWin(false);
                    }else if (response.code === "already_played_today"){
                        window.location = '/already-played';
                    }
                });
            } catch(e) {
                console.log(e);
            }        
    }

    useEffect(() => {		
        let scaling = setTimeout(() => {
            setClicks(0)
        }, 1000);

		return () => clearInterval(scaling);
	}, [clicks]);
    
    const handleClick = ()=>{        
        setClicks(clicks +1)
    }


    return (
        <div className="game_game" id="game_clicker">
            <section 
            className={endGame ? "game ended" : "game"}
            style={{
                backgroundColor: data.inputs['backgroundColor'].value,
                backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                backgroundSize: "cover!important",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                position: 'relative',
                display:"flex",                
                alignItems:"center",
                justifyContent:"center"
            }}
            onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
            >
                { !preview && hover && props.toolbarSection }                   
                <div className={hidePopup ? "popup game hidden" : "popup play game"}>
                    <div 
                        className="popup_content" 
                        onMouseEnter={() => {setHoverBlock(true); setHover(false);}} 
                        onMouseLeave={() => {setHoverBlock(false); setHover(true)}}
                        style={{
                            backgroundColor: data.blocks.popup.inputs['backgroundColor'].value,                                                        
                            borderWidth: border?.inputs?.borderWidth?.value ? border.inputs.borderWidth.value : '0px',
                            borderColor: border?.inputs?.borderColor?.value ? border.inputs.borderColor.value : null,
                            borderRadius: border?.inputs?.borderRadius?.value ? border.inputs.borderRadius.value : null,
                        }}
                    >
                        { !preview && hoverBlock && props.toolbarBlock }
                        <div className="content_popup">                            
                            {
                                data.blocks.popup?.blocks?.title.inputs.value.value ?                                 
                                    <H1 data={data.blocks.popup} preview={preview} component={id} block={'title'} inputCallback={inputCallback} />
                                : null
                            }
                            {
                                data.blocks.popup?.blocks?.desc.inputs.value.value && data.blocks.popup?.blocks?.desc.inputs.value.value !== "<p><br></p>" ? 
                                    <Div data={data.blocks.popup} preview={preview} component={id} block={'desc'} inputCallback={inputCallback} />
                                : null
                            }
                            {
                                data.blocks.popup?.blocks?.image.inputs.value.value ?                                 
                                    <img 
                                        src={typeof popupImage === 'string' ? popupImage : popupImage?.filePath ? `${process.env.REACT_APP_MEDIAS}/${popupImage.filePath}` : 'none'}
                                    />
                                : null
                            }

                            <Button data={data.blocks.popup} preview={preview} component={id} block={'button'} inputCallback={inputCallback} className="button goToGame" nopadding={true} onClick={() => startGame()}/>                            
                        </div>
                    </div>
                </div>
                <H1 className="game-title" data={data} preview={preview} component={id} block={'title'} inputCallback={inputCallback} />                            
                {
                    data.blocks.gameImage.inputs.value.value ? 
                        <section className="game-image">                                                                            
                            <img style={{transform: `scale(${clicks > 0?1 + clicks * 0.05:1 })`}} ref={ref} onClick={handleClick} src={`${process.env.REACT_APP_MEDIAS}/${data.blocks.gameImage.inputs.value.value.filePath}`} />                            
                        </section>
                    : null
                }
                {
                    endGame ?
                        <section className={"endGame_popup"}>
                            <div 
                                className="endGame_popup_content"
                                style={{
                                    backgroundImage:isWin ?getBackgroundImageEndGame(endGameBackgroundImageWin):getBackgroundImageEndGame(endGameBackgroundImageLoose)                                    
                                }}
                            >      
                            <div style={{marginTop:"30px"}}>
                                {
                                    data.blocks.title_win.inputs.value.value ||data.blocks.title_loose.inputs.value.value ?                                 
                                        <H1 data={data} preview={preview} component={id} block={isWin?'title_win':'title_loose'} inputCallback={inputCallback} />
                                    : null
                                }
                                {
                                    data.blocks.desc_win.inputs.value.value || data.blocks.desc_loose.inputs.value.value !== "<p><br></p>" ? 
                                        <Div data={data} preview={preview} component={id} block={isWin?'desc_win':'desc_loose'} inputCallback={inputCallback} />
                                    : null
                                }
                            </div>                                           
                                <div className="video_container">
                                    {
                                        data.blocks.end_game.inputs.value.value || data.blocks.end_game_loose.inputs.value.value?                                 
                                            <video width="85%" id="result-video" playsInline muted autoPlay>
                                                <source src={`${process.env.REACT_APP_MEDIAS}/${isWin ? data.blocks.end_game.inputs.value.value?.filePath : data.blocks.end_game_loose.inputs.value.value?.filePath}`} type={isWin ? data.blocks.end_game.inputs.value.value?.type : data.blocks.end_game_loose.inputs.value.value?.type} />
                                                Sorry, your browser doesn't support embedded videos.
                                            </video>
                                        : null
                                    }
                                </div>
                                {/* <Button data={data.blocks.popup} preview={preview} component={id} block={'button'} inputCallback={inputCallback} className="button goToGame" nopadding={true} onClick={() => startGame()}/>                             */}
                            </div>
                        </section>
                    : null
                }
            </section>
        </div>
    )
}

GameClicker.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingclassName: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default GameClicker;