import {FORM} from '../../../../../js/constants/form-types';
import Button from '../../../../ui/button/Button';

const formStorelocatorAdd = {
    titleForm: "Modifier un magasin",
    subTitleForm: 'Veuillez compléter les champs ci-dessous pour configurer votre image de marque',
    langSelect: false,
    drawerType: 'drawer',
    noForm: true,
    component: Button,
    textButtonValidate: 'Modifier le magasin',
    formConfig: {
        type: FORM,
        finalStep:{},
        children:[
            {
                labelName: 'Identité',
                optionsInputs:[]
            },
        ]
    }
}

export default formStorelocatorAdd;