import React from 'react';

import { Box, Divider, Grid, IconButton, ListItemIcon, Menu, MenuItem } from '@material-ui/core';
import Typography from '../../../ui/typography/Typography';
import colors from '../../../../config/theme/colors';
import styled from 'styled-components'
import { withTranslation } from 'react-i18next';
import Button from '../../../ui/button/Button';
import { ROUTE_SALES_ESHOP_MERCHANDISING_BUILDER } from '../../../../js/constants/route-names';
import * as moment from "moment";
import StatusInfo from '../../../ui/status-info/StatusInfo';
import { MoreVert } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import TextEllipsis from './TextEllipsis';
import OurMenu from '../../../ui/menu/Menu';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import FileCopySharpIcon from '@material-ui/icons/FileCopySharp';

const TypographyCustom=styled(Typography)`
    color: ${colors.sinfinNewMenu.normal};
    width: 180px;
`
const MenuCustom=styled(Menu)`
    .MuiMenu-paper{
        box-shadow: 0 0 10px #1622421A;
    }
`
const MenuItemCustom=styled(MenuItem)`
    color: ${colors.sinfinNewMenu.normal};
    svg{
        fill: ${colors.sinfinNewMenu.normal};
    }
`

const DividerCustom = styled(Divider)`
    width: 100%;
    margin-top: 14px;
    margin-bottom: 14px;
`;

function CardDiscount(props){
    console.log('CardDiscount', props)
    let data = props.data.node
    let typeDiscount = null
    switch (data.formType) {
        case 'reduction_produit':
            typeDiscount = 'Réduction produit'
            break;
        case 'reduction_panier':
            typeDiscount = 'Réduction panier'
            break;
        case 'x_pour_le_prix_de_y':
            typeDiscount = 'X pour le prix de Y'
            break;
        case 'frais_de_livraison':
            typeDiscount = 'Frais de livraison'
            break;
    
        default:
            break;
    }

    const editMenuOptions = [
        {
            label: 'Dupliquer',
            isDisabled: true,
            color: colors.blue.darker.hue300,
            icon: <FileCopySharpIcon style={{ fill: colors.blue.darker.hue300 }} />,
            action: (e) => {
                e.stopPropagation();
                // props.toggleDuplicateDialog(props.data.node.id)
            }
        },
        {
            label: 'Supprimer',
            color: colors.red.regular,
            icon: <DeleteSharpIcon style={{ fill: colors.red.regular }} />,
            action: (e) => {
                e.stopPropagation();
                props.handleClickMenu(e, props?.data.node.id)
                props.handlerButtonDelete()
            }
        }
    ]

    return(
        <Box width={'100%'} height={'100%'} display={"flex"} flexDirection={"column"}>
            <Box padding={'16px'} display={"flex"} flexDirection={"column"} flex={1}>
                <Box padding={"0 5px"} display={"flex"} flexDirection={"column"} flex={1}>
                    <Box display={"flex"} flexDirection={"row"} alignItems={"flex-start"} justifyContent={"space-between"}>
                        <Grid container alignItems='center' wrap='nowrap'>
                            <Typography variant={"h2"} style={{paddingRight: 8, fontSize: 19}}>{data.name}</Typography>
                            <Box display={"flex"} ml={1}>
                                <StatusInfo status={data.isActive}/>
                            </Box>
                        </Grid>
                        <OurMenu menuOptions={editMenuOptions} handleClick={(e, item) => item.action(e)} />
                    </Box>
                    <Grid container style={{minHeight: 38}}>
                        <TextEllipsis text={data.description} maxLine={2} ellipsis={'...'} hasModal={false} />
                    </Grid>
                    <DividerCustom />
                    <Box mb={2} display='flex' flexDirection='column' flex={1}>
                        <Box display={"flex"} flexDirection={"row"}>
                            <TypographyCustom>Type de réduction: </TypographyCustom>
                            <span style={{fontWeight: "bold"}}>{typeDiscount}</span>
                        </Box>
                        <Box display={"flex"} flexDirection={"row"}>
                            <TypographyCustom>Méthode : </TypographyCustom>
                            <span style={{fontWeight: "bold"}}>{data.hasCoupon ? `Code ${data.assetCartRuleCouponUnique.coupon}` : 'Réduction automatique'}</span>
                        </Box>
                        {
                            data.buyXGetYQty ?
                                <Box display={"flex"} flexDirection={"row"}>
                                    <TypographyCustom>Produits panier : </TypographyCustom>
                                    <span style={{fontWeight: "bold"}}>{data.buyXGetYQty} {data.buyXGetYQty > 1 ? 'produits' : 'produit'}</span>
                                </Box>
                            : null
                        }
                        {
                            data.buyXGetYOffer ?
                                <Box display={"flex"} flexDirection={"row"}>
                                    <TypographyCustom>Produits remisés : </TypographyCustom>
                                    <span style={{fontWeight: "bold"}}>{data.buyXGetYOffer} {data.buyXGetYOffer > 1 ? 'produits' : 'produit'}</span>
                                </Box>
                            : null
                        }
                        <Box display={"flex"} flexDirection={"row"}>
                            <TypographyCustom>Montant ({data.discountType === "percent" || data.formType === "x_pour_le_prix_de_y" ? '%' : '€'}) : </TypographyCustom>
                            <span style={{fontWeight: "bold"}}>{data.discount}{data.discountType === "percent" || data.formType === "x_pour_le_prix_de_y" ? '%' : '€'}</span>
                        </Box>
                        <Box display={"flex"} flexDirection={"row"}>
                            <TypographyCustom>Minimum d’achat : </TypographyCustom>
                            <span style={{fontWeight: "bold"}}>{data.minProductQty ? 'Quantité minimum' : data.minCartAmount ? 'Montant minimum' : 'Non'}</span>
                        </Box>
                        {
                            data.minProductQty ?
                                <Box display={"flex"} flexDirection={"row"}>
                                    <TypographyCustom>Quantité minimum : </TypographyCustom>
                                    <span style={{fontWeight: "bold"}}>{data.minProductQty}</span>
                                </Box>
                            : null
                        }
                        {
                            data.minCartAmount ?
                                <Box display={"flex"} flexDirection={"row"}>
                                    <TypographyCustom>Montant minimum (€) : </TypographyCustom>
                                    <span style={{fontWeight: "bold"}}>{data.minCartAmount}€</span>
                                </Box>
                            : null
                        }
                        <Box display={"flex"} flexDirection={"row"}>
                            <TypographyCustom>Utilisation par client : </TypographyCustom>
                            <span style={{fontWeight: "bold"}}>{data.usePerCustomer ? '1 par client' : 'Illimité'}</span>
                        </Box>
                        {
                            data.hasFreeShipping ?
                                <Box display={"flex"} flexDirection={"row"}>
                                    <TypographyCustom>Frais de livraison : </TypographyCustom>
                                    <span style={{fontWeight: "bold"}}>Offert</span>
                                </Box>
                            : null
                        }
                        <Box display={"flex"} flexDirection={"row"}>
                            <TypographyCustom>Date d’activation : </TypographyCustom>
                            <span style={{fontWeight: "bold"}}>{moment(data.startedAt).utc().format('DD/MM/YYYY')}{data.endedAt ? ` au ${moment(data.endedAt).utc().format('DD/MM/YYYY')}` : null}</span>
                        </Box>
                        <Box display={"flex"} flexDirection={"row"}>
                            <TypographyCustom>Nombre d'utilisation max. : </TypographyCustom>
                            <span style={{fontWeight: "bold"}}>{data.usePerCoupon ? data.usePerCoupon : 'Illimité'}</span>
                        </Box>
                        {console.log('data.products.edges', data.products.edges)}
                        {
                            (data.productSelectionType === 'in' || data.productSelectionType === 'not_in') && data.products.edges.length > 0 ?
                                <Box display={"flex"} flexDirection={"row"}>
                                    <TypographyCustom>Produits spécifiques : </TypographyCustom>
                                    <span style={{fontWeight: "bold"}}>{data.products.edges.length} {data.products.edges.length > 1 ? 'produits' : 'produit'}</span>
                                </Box>
                            : null
                        }
                    </Box>
                </Box>
                <Grid container>
                    <Grid item xs={12}>
                        <Button 
                            style={{margin: 0, padding: "14px 32px", width: "100%"}} 
                            text={props.t('catalogs.catalogs.modify')} 
                            color={colors.blue.darker.hue300} 
                            colorHover={colors.white} 
                            bgcolorhover={colors.blue.lighter.hue600} 
                            bgcolor={colors.blue.lighter.hue900} 
                            border={`1px solid ${colors.blue.darker.hue300}`} 
                            stopPropagation={true}
                            onClick={() => props.handlerButton(data)} 
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default withTranslation()(CardDiscount);