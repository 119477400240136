import React, { Component } from 'react';
import './App.css';
import 'cropperjs/dist/cropper.css';
import { withRouter, Redirect } from "react-router";
import {
  Switch,
  Route,
} from "react-router-dom";
import { connect } from "react-redux";
import * as moment from "moment";
import 'moment/locale/fr';
import { withApollo } from 'react-apollo';
import { START_LOADING, STOP_LOADING, STOP_SNACK, SET_USER } from './js/constants/action-types';
import { GET_USER_BY_ID } from './queries/users';
import LoadingOverlay from './components/ui/loading-overlay/LoadingOverlay';
import Snack from './components/ui/snack/Snack';
import Dashboard from './components/screens/dashboard/Dashboard';
import DashboardCRM from './components/screens/dashboardCRM/Dashboard';
import Login from './components/screens/login/Login';
import ForgetPassword from './components/screens/login/ForgetPassword';
import ResetPassword from './components/screens/login/ResetPassword';
import Demo from './components/screens/demo/Demo';
import { MuiThemeProvider } from '@material-ui/core/styles';
import THEME_SPREAD from '../src/config/theme/theming';
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
import './scss/main.scss'; // This only needs to be imported once in your app
import Builder from './builder/builder';
import HomepageGame from './builder/templates/assets/game/base';
import HomepageFlipbook from './builder/templates/assets/book/homepage';
import GameFlipbook from './builder/templates/assets/book/game';
import ResultFlipbook from './builder/templates/assets/book/results';
import RecapFlipbook from './builder/templates/assets/book/recap';
import CardIframe from './components/layouts/Card/cardContent/CardIframe';
import request from './js/utils/fetch';
import { default as ForgotPasswordTemplate } from './email/forgetPassword';
import { ROUTE_BUILDER, ROUTE_BUILDER_PREVIEW, ROUTE_BUILDER_PRESENTATION, ROUTE_BUILDER_MODEL_PRESENTATION, ROUTE_BUILDER_HOMEPAGE_GAME, ROUTE_FLIPBOOK, ROUTE_FLIPBOOK_RESULT, ROUTE_FLIPBOOK_GAME, ROUTE_FLIPBOOK_RECAP, ROUTE_BUILDER_DEV, ROUTE_BUILDER_CMS_CONTENT, ROUTE_SALES_ESHOP_MERCHANDISING_BUILDER } from './js/constants/route-names';
import { Pages } from './builder/shareable/types';
import BuilderMerch from './components/screens/builderMerch/BuilderMerch';
import { deleteLocalStorage } from './js/utils/deleteLocalStorage';
import Typography from '@material-ui/core/Typography';
const isCRM = process.env.REACT_APP_MODE_CRM === 'true';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    moment.locale('fr');
  }

  componentDidMount() {
    let localStorageUser = localStorage.getItem('AUTH_USER');
    if (!localStorageUser) {
      if (this.props.user) {
        this.props.client.query({
          query: GET_USER_BY_ID,
          variables: { id: this.props.user.id }
        }).then(result => {
          let getUser = result.data.user;
          getUser.menuEntries = getUser?.userGroup?.menuEntry?.edges?.map(e => e.node);
          localStorage.setItem('AUTH_USER', JSON.stringify(getUser));
          this.props.setUser(getUser);
        });
      }
    } else {
      if (this.props.user) {
        this.props.setUser(JSON.parse(localStorageUser));
      }
    }
    //Get window width
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.outerWidth, height: window.outerHeight });
  }

  render() {
    const { snackDuration, snackMsg, snackType, snacking, loading, user } = this.props;
    const { width, height } = this.state;
    let returnTo = null;
    isLoggedIn().then(r => {
      if (user) {
        let url_string = window.location.href
        let url = new URL(url_string);
        returnTo = url.searchParams.get("returnTo");
        if (returnTo) {
          returnTo = returnTo.replace(process.env.REACT_APP_PUBLIC, '')
        }
    }
    })

    async function isLoggedIn() {
      let localStorageTokenExpiration = localStorage.getItem('AUTH_TOKEN_EXPIRY');
      let now = new Date();
      let tokenExpiration = null;
      if (localStorageTokenExpiration) {
        tokenExpiration = new Date(localStorageTokenExpiration);
      }
      if (!tokenExpiration || now < tokenExpiration) {
        return true;
      } else {
        await deleteLocalStorage();
        return window.location.href = "/login";
      }
    }

    return (
      <MuiThemeProvider theme={THEME_SPREAD}>
        <div className="App">
          <Switch>
            <Route path={ROUTE_BUILDER_MODEL_PRESENTATION}>
              <Builder presentation={true} presentationModel={true} pageToEdit={Pages.PRESENTATION} />
            </Route>

            <Route path={ROUTE_BUILDER_PRESENTATION}>
              <Builder presentation={true} pageToEdit={Pages.PRESENTATION} />
            </Route>

            <Route path={ROUTE_BUILDER_DEV}>
              <Builder dev={true} />
            </Route>

            <Route path={ROUTE_BUILDER_CMS_CONTENT}>
              <Builder />
            </Route>

            <Route path={ROUTE_BUILDER}>
              <Builder />
            </Route>

            <Route path={ROUTE_BUILDER_PREVIEW}>
              <Builder onlyPreview={true} onlyContent={true} noToolbar={true} />
            </Route>

            <Route path={ROUTE_BUILDER_HOMEPAGE_GAME}>
              <HomepageGame />
            </Route>

            <Route path={`${ROUTE_FLIPBOOK}`} exact>
              <HomepageFlipbook />
            </Route>

            <Route path={`${ROUTE_FLIPBOOK_RESULT}`} exact>
              <ResultFlipbook />
            </Route>

            <Route path={`${ROUTE_FLIPBOOK_GAME}`} exact>
              <GameFlipbook />
            </Route>

            <Route path={`${ROUTE_FLIPBOOK_RECAP}`} exact>
              <RecapFlipbook />
            </Route>

            <Route path={`${ROUTE_SALES_ESHOP_MERCHANDISING_BUILDER}`} exact>
              <BuilderMerch windowWidth={width} />
            </Route>

            <Route path={`/card/product/:lang/:sku`} exact>
              <CardIframe />
            </Route>

            <Route path="/login">
              {user ? <Redirect to={returnTo ? returnTo : "/"} /> : <Login />}
            </Route>
            <Route path="/email">
              <h1 style={{ textDecoration: 'underline', margin: 10 }}>Oubli de mot de passe : </h1>
              <div dangerouslySetInnerHTML={{ __html: ForgotPasswordTemplate('https://google.com', `${process.env.REACT_APP_PUBLIC}/img/logo.png`) }}></div>
            </Route>
            <Route path="/password/forget">
              {user ? <Redirect to="/" /> : <ForgetPassword />}
            </Route>
            <Route path="/password/reset/:token">
              {user ? <Redirect to="/" /> : <ResetPassword />}
            </Route>
            <Route path="/demo">
              {process.env.REACT_APP_ENV === 'dev' ? <Demo /> : <Redirect to="/" />}
            </Route>
            <Route path="/crm">

              {user ? <DashboardCRM windowWidth={width} /> : <Redirect to="/login" />}
            </Route>
            <Route path="/" render={()=>isLoggedIn()}>
              {
                user ?
                  isCRM ?
                    <Redirect to="/crm" />
                    : <Dashboard windowWidth={width} />
                  : <Redirect to="/login" />
              }
            </Route>
          </Switch>

          {
            loading
              ? <LoadingOverlay />
              : null
          }

          <Snack
            message={snackMsg}
            type={snackType}
            duration={snackDuration}
            open={snacking}
            onClose={() => { this.props.stopSnack() }}
          />

          {/* 
            - Not used for now, as we don't want to block the user. 
            - cf LayerBreadcrumb that prevents access to specific routes
          */}

          {/* {
            this.props.loginProgress && (
              <div className="login-progress">
                <img src="/sinfin.gif" /> 
                <div className="login-progress-text">Nous préparons votre environnement</div>
                <div className="login-progress-step">{this.props.loginProgress}</div>
              </div>
            )
          } */}
        </div>

      </MuiThemeProvider>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    startLoading: () => dispatch({ type: START_LOADING }),
    stopLoading: () => dispatch({ type: STOP_LOADING }),
    stopSnack: () => dispatch({ type: STOP_SNACK }),
    setUser: (user) => dispatch({ type: SET_USER, payload: { user } })
  }
}

const mapStateToProps = state => {
  return {
    loading: state.loading,
    snacking: state.snacking,
    snackMsg: state.snackMsg,
    snackType: state.snackType,
    snackDuration: state.snackDuration,
    user: state.user,
    loginProgress: state.loginProgress
  };
};

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(App)));
