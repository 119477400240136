import React from "react";
import Pg from '../../../../shared/components/CustomParagraph';
import H2 from '../../../../shared/components/CustomH2';
import H3 from '../../../../shared/components/CustomH3';

export default ({ data, preview, component, block, inputCallback, className }) => {
    var dotations = data.blocks[block].config;
    return(
        <div class="dotations special">
            <div>
                <H2 data={data} preview={preview} component={component} block={'dotationsTitle'} inputCallback={inputCallback} />
                {
                    data.blocks.dotationsSubtitle ?
                    (<H3 className="center" data={data} preview={preview} component={component} block={'dotationsSubtitle'} inputCallback={inputCallback} />) : null
                }
            </div>
            <div class="list">
                {
                    dotations.map((dotation, index) =>{
                        return(
                            <div class="dotation" onClick={() => inputCallback(component, block)}>
                                <div>
                                    <img 
                                        className="img_center logo"
                                        src={
                                            typeof dotation.blocks.logo?.inputs?.value?.value === 'string' && dotation.blocks.logo?.inputs?.value?.value !== '' ? 
                                                dotation.blocks.logo.inputs.value.value : 
                                                dotation.blocks.logo.inputs.value?.value?.filePath ? 
                                                    ` ${process.env.REACT_APP_MEDIAS}/${dotation.blocks.logo.inputs.value.value.filePath}` 
                                                    : `http://via.placeholder.com/200x100/FFFFFF/53839D?text=Logo${index+1}`
                                        }
                                        alt={`Dotation ${index+1}`}
                                    />
                                    <img 
                                        className="img_center lot"
                                        src={
                                            typeof dotation.blocks.image?.inputs?.value?.value === 'string' && dotation.blocks.image?.inputs?.value?.value !== '' ? 
                                                dotation.blocks.image.inputs.value.value : 
                                                dotation.blocks.image.inputs.value?.value?.filePath ? 
                                                    ` ${process.env.REACT_APP_MEDIAS}/${dotation.blocks.image.inputs.value.value.filePath}` 
                                                    : `http://via.placeholder.com/450x175/FFFFFF/53839D?text=Dotations${index+1}`
                                        }
                                        alt={`Dotation ${index+1}`}
                                    />
                                    <Pg data={dotation} preview={preview} component={dotation.blocks.text.id} block={'text'} inputCallback={() => inputCallback(component, block)} nopadding={true} />
                                </div>
                            </div>
                        ) 
                    })
                }
            </div>
        </div>
    )
};