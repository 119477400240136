import React from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import ArrayHistory from './components/ArrayHistory';
import Typography from '../../../ui/typography/Typography';
import request from '../../../../js/utils/fetch';
import { checkRouting } from '../../../../js/utils/checkRouting';
import { Box, colors } from '@material-ui/core';
import PageLoader from '../../../ui/loadings/page-loader/PageLoader';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import { withTranslation } from 'react-i18next';

class ImportHistory extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            used: props.used
        };
    }

    componentDidMount() {
        checkRouting(this.props);
        try {
            request(`${process.env.REACT_APP_API}/scheduler/crons/check`, 'get', false).then(
                (data) => {
                    if (data.success) {
                        console.log(data)
                        this.setState({ rows: data.crons })
                    }
                }
            )
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        return (
            <div style={{ width: "100%", display: 'grid', gridTemplateRows: 'auto 1fr', height: '100%' /* height: 'calc(100% - 64px)' */ }}>
                {
                    this.props.used === "dashboard" ?
                        <Typography>{this.props.t('drawer.history_imports')}</Typography>
                        :
                        <TopPanel
                            title={this.props.t('drawer.history_imports')}
                            subtitle={"Liste des imports fait dans la plateforme par les utilisateurs"}
                            locales={this.props.locales}
                            hasBorder={true}
                        />
                }                
                {this.state.rows ?
                    this.state.rows.length > 0 ?
                    (
                        <ArrayHistory rows={this.state.rows} style={{ padding: 0, boxShadow: 'none', background: 'transparent', margin: this.state.used === "page" ? '16px 32px' : '8px', width: this.state.used === "page" ? 'calc(100% - 64px)' : 'calc(100% - 16px)', display: 'grid', gridTemplateRows: '1fr auto' }} />
                    )
                    : <Typography style={{padding:"16px 32px"}}>Aucun résultat</Typography>
                    : (
                        <Box style={{
                            position: 'relative',
                        }}>
                            <PageLoader />
                        </Box>
                    )}
            </div>
        );
    }
    goTo = route => {
        this.props.history.push(route);
    };
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, null)(ImportHistory)));
