import PropTypes from "prop-types";
import React, { useState } from "react";
import Rules from './Rules';
import Dotations from './Dotations';
import { getBackgroundImage } from "../../../../../utils/getBackgroundImage";

const ExplanationsGame = (props) => {
    const [hover, setHover] = useState(false);
    const [hoverBlock, setHoverBlock] = useState(false);

    let { 
        preview = false,  
        id = null, 
        data = null,
        inputCallback = null,
        update = null
    } = props;

    let border = null;
    if (data.blocks.explanation.inputs.border) {
        border = data.blocks.explanation.inputs.border;
    }

    let image = data.inputs['backgroundImage'].value;
    let image2 = data.blocks.explanation.inputs['backgroundImage'].value;
    let imageTablet = data.inputs['backgroundImageTablet']?.value;
    let imageMobile = data.inputs['backgroundImageMobile']?.value;
    
    return (
        <section 
            className={`how-container ${data.inputs['className'] ? data.inputs['className'].value:''}`}
            style={{
                backgroundColor: data.inputs['backgroundColor'].value,
                backgroundImage: getBackgroundImage(image,imageTablet,imageMobile),
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                position: 'relative',
            }}
            onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
            >
            { !preview && props.toolbarSection }
            
            <section 
                className="how" 
                style={{

                    backgroundColor: data.blocks.explanation.inputs['backgroundColor'].value,
                    backgroundImage: typeof image2 === 'string' ? `url(${image2})` : image2?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image2.filePath})` : 'none',
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    position: 'relative',
                    borderStyle: 'solid',
                    borderWidth: border?.inputs?.borderWidth?.value ? border.inputs.borderWidth.value : '0px',
                    borderColor: border?.inputs?.borderColor?.value ? border.inputs.borderColor.value : null,
                    borderRadius: border?.inputs?.borderRadius?.value ? border.inputs.borderRadius.value : null,
                }}
                onMouseEnter={() => {setHover(false); setHoverBlock(true)}} onMouseLeave={() => {setHover(true);setHoverBlock(false)}}
            >
                { props.test }
                { !preview && hoverBlock && props.toolbarBlock }
                {
                    data.blocks.explanation.blocks.rules.config.length > 0 ? (
                        <Rules data={data.blocks.explanation} preview={preview} component={id} block={'rules'} inputCallback={inputCallback} update={update} />
                    ) : null
                }
                {
                    // data.blocks.explanation.blocks.dotations.config.length > 0 ? (
                        <Dotations data={data.blocks.explanation} preview={preview} component={id} block={'dotations'} inputCallback={inputCallback} update={update} />
                    // ) : null
                }
            </section>
        </section>
    );
};

ExplanationsGame.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingclassName: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default ExplanationsGame;
