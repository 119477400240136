import PropTypes from "prop-types";
import React, { useState } from "react";
import H1 from '../../../../shared/components/CustomH1';
import H3 from '../../../../shared/components/CustomH3';
import Pg from '../../../../shared/components/CustomParagraph';
import Button from '../../../../shared/components/CustomLink';
import {Social} from '../index';

const AlreadyPlayedGameSimple = (props) => {
    const [hover, setHover] = useState(false);
    const [hoverBlock, setHoverBlock] = useState(false);
    const [hoverBlockSocial, setHoverBlockSocial] = useState(false);

    let { 
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
        update = null,
    } = props;

    let image = data.inputs['backgroundImage'].value;
    let image2 = data.blocks.middleBox.inputs['backgroundImage'].value;

    let border = null;
    if (data.blocks.middleBox.inputs.border) {
        border = data.blocks.middleBox.inputs.border;
    }
    let blockSocial = data.blocks.socialBlock;
    return (
        <div className="game_index alreadyplayed">
            <a className="reglement" href="#reglement_link">Règlement du jeu</a>
            <section 
                className="game"
                style={{
                    backgroundColor: data.inputs['backgroundColor'].value,
                    backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    position: 'relative',
                }}
                onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
            >
                { !preview && props.toolbarSection }
                <img className="logo" onClick={() => inputCallback(id, 'logo')} src={data.blocks.logo.inputs.value?.value ? ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.logo.inputs.value.value.filePath}` : typeof(data.blocks.logo.inputs.value.value) === "string" ? data.blocks.logo.inputs.value.value : "http://via.placeholder.com/250x80/CCCCCC/AAAAAA?text=Logo"} />
                <div class="container-elements">
                    {
                    blockSocial.blocks.shareText.inputs?.value?.value || blockSocial.blocks.social.config.length > 0  ?
                    (
                        <div 
                            style={{position: "relative", width: "100%"}} 
                            onMouseEnter={() => {setHoverBlockSocial(true); setHoverBlock(false); setHover(false);}} 
                            onMouseLeave={() => {setHoverBlockSocial(false); setHover(true);}}
                        >
                            { !preview && hoverBlockSocial && props.toolbarBlockSocial }

                            {
                                blockSocial.inputs.position?.value === 'top' ? 
                                (
                                    <div
                                        style={{
                                            backgroundColor: blockSocial.inputs.backgroundColor.value,
                                            backgroundImage: typeof blockSocial.inputs['backgroundImage'].value === 'string' ? `url(${blockSocial.inputs['backgroundImage'].value})` : blockSocial.inputs['backgroundImage'].value?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${blockSocial.inputs['backgroundImage'].value.filePath})` : 'none',
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                        }}
                                    >
                                        <div className="block_social">
                                            {
                                                blockSocial.blocks.shareText.inputs.value.value ? (
                                                    <div className="social" style={{background: blockSocial.blocks.shareText.inputs.backgroundColor ? blockSocial.blocks.shareText.inputs.backgroundColor.value : null }}>
                                                        <div>
                                                            <Pg data={blockSocial} preview={preview} component={id} block={'shareText'} inputCallback={inputCallback} nopadding={true} style={{height: 'auto'}} />
                                                        </div>
                                                    </div>
                                                ) : null
                                            }
                                        </div>
                                        <div className="block_social">
                                            <Social data={blockSocial} preview={preview} component={id} block={'social'} inputCallback={inputCallback} update={update} />
                                        </div>
                                    </div>
                                ) : blockSocial.inputs.position?.value === "bottom" ? 
                                (
                                    <>
                                        <div 
                                            className="block_social"
                                            style={{
                                                backgroundColor: blockSocial.inputs.backgroundColor.value,
                                                backgroundImage: typeof blockSocial.inputs['backgroundImage'].value === 'string' ? `url(${blockSocial.inputs['backgroundImage'].value})` : blockSocial.inputs['backgroundImage'].value?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${blockSocial.inputs['backgroundImage'].value.filePath})` : 'none',
                                                backgroundPosition: 'center',
                                                backgroundRepeat: 'no-repeat',
                                            }}
                                        >
                                            <Social data={blockSocial} preview={preview} component={id} block={'social'} inputCallback={inputCallback} update={update} />
                                        </div>

                                        <div 
                                            className="block_social"
                                            style={{
                                                backgroundColor: blockSocial.inputs.backgroundColor.value,
                                                backgroundImage: typeof blockSocial.inputs['backgroundImage'].value === 'string' ? `url(${blockSocial.inputs['backgroundImage'].value})` : blockSocial.inputs['backgroundImage'].value?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${blockSocial.inputs['backgroundImage'].value.filePath})` : 'none',
                                                backgroundPosition: 'center',
                                                backgroundRepeat: 'no-repeat',
                                            }}
                                        >
                                            {
                                                blockSocial.blocks.shareText.inputs.value.value ? (
                                                    <div className="social" style={{background: blockSocial.blocks.shareText.inputs.backgroundColor ? blockSocial.blocks.shareText.inputs.backgroundColor.value : null }}>
                                                        <div>
                                                            <Pg data={blockSocial} preview={preview} component={id} block={'shareText'} inputCallback={inputCallback} nopadding={true} style={{height: 'auto'}} />
                                                        </div>
                                                    </div>
                                                ) : null
                                            }
                                        </div>
                                    </>
                                ) :
                                (
                                    <div className="block_social">
                                        {
                                            blockSocial.blocks.shareText.inputs.value.value ? (
                                                <div className="social" style={{background: blockSocial.blocks.shareText.inputs.backgroundColor ? blockSocial.blocks.shareText.inputs.backgroundColor.value : null }}>
                                                    <div>
                                                        <Pg data={blockSocial} preview={preview} component={id} block={'shareText'} inputCallback={inputCallback} nopadding={true} />
                                                    </div>
                                                </div>
                                            ) : null
                                        }
                                    
                                        <Social data={blockSocial} preview={preview} component={id} block={'social'} inputCallback={inputCallback} update={update} />
                                    </div>
                                )
                            }

                        </div>
                    ) : null
                }
                </div>
            </section>
        </div>
    );
};

AlreadyPlayedGameSimple.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingclassName: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default AlreadyPlayedGameSimple;
