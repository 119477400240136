import PropTypes from "prop-types";
import React, { useState } from "react";
import Div from '../../../../shared/components/CustomDiv';
import Pg from '../../../../shared/components/CustomParagraph';

const BlocBasic = (props) => {
    const [hover, setHover] = useState(false);

    let {   
        preview = false,
        id = null, 
        data = null,
        inputCallback = null
    } = props;

    let image = data.inputs['backgroundImage'].value;

    return (
        <div 
            className="basicBlock"
            style={{
                backgroundColor: data.inputs['backgroundColor'].value,
                backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                backgroundSize: "cover",
                backgroundPosition: "top center",
                backgroundRepeat: "no-repeat",
                position: 'relative'
            }}
            onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
        >
            { !preview && props.children }
            <Pg data={data} preview={preview} className="center" component={id} block={'title'} inputCallback={inputCallback} nopadding={true} />
            <Pg data={data} preview={preview} className="center" component={id} block={'subtitle'} inputCallback={inputCallback} nopadding={true} />
            <Div data={data} preview={preview} className="center" component={id} block={'text'} inputCallback={inputCallback} nopadding={true} />
        </div>
    );
};

BlocBasic.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingClass: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default BlocBasic;
