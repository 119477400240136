import React from "react";
import TableCell from '@material-ui/core/TableCell';
import styled from 'styled-components';
import Button from '../../../../../ui/button/Button'
import { withRouter } from 'react-router';


function CellLink(props){
    const goTo = (route) => {
        props.history.push(route);

    };

    return(
        <TableCell 
            style={{
                width : props.width ? props.width :'auto',textAlign:'right',
                padding : '4px 16px' 
            }}
        >
            
            <Button onClick={()=>goTo(props.value)}>Voir le détail</Button>
        </TableCell>
    )
}
export default withRouter(CellLink);