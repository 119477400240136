import React from 'react';
import { connect } from "react-redux";
import { SNACK, START_LOADING, STOP_LOADING } from '../../../js/constants/action-types';
import axios from '../../../js/utils/axios';
import {Grid, Typography} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PageLoader from '../loadings/page-loader/PageLoader';

class Mapping extends React.Component {  
    state = {
        loading: false,
        headers: [],
        options: [],
        import: {},
        ftpReady: false,
    };
    
    getHeader = (locale, options) => {
        return new Promise(async (resolve, reject) => {
            let headersFormData = new FormData();
            if (this.props.allState.importFile === 'ftp' || this.props.allState.importFile === "sftp") {
                let mapOn = this.props.input.mapOn + '_import';
                if(!this.props.allState[locale.node.code][mapOn]){
                    this.setState({ftpReady: false});
                    resolve();
                } else {
                    if (this.props.allState['importSep'] === "xml"){
                        headersFormData.append('xml', this.props.allState[locale.node.code][mapOn]);
    
                    }else{
                        headersFormData.append('csv', this.props.allState[locale.node.code][mapOn]);
                    }
                    headersFormData.append('separator', this.props.allState['importSep']);
                    let resultHeaders = await axios(this.props.allState['importSep'] === "xml" ? `${process.env.REACT_APP_API_ROOT}/api/export/simplexml/structure` : `${process.env.REACT_APP_API_ROOT}/api/export/csv/structure`, 'post', headersFormData);
                    
                    this.state.headers = resultHeaders.message;
                    this.setState({
                        ftpReady: true,
                        [locale.node.code]: {
                            headers: (this.state.headers || []).map(e => ({
                                used: false,
                                label: e,
                                id: e
                            })),
                            options
                        }
                    });
                    resolve();
                }
            } else {
                if (this.props.allState['importSep'] === "xml"){
                    headersFormData.append('xml', this.props.allState[locale.node.code][this.props.input.mapOn]);

                }else{
                    headersFormData.append('csv', this.props.allState[locale.node.code][this.props.input.mapOn]);
                }
                headersFormData.append('separator', this.props.allState['importSep']);
                let resultHeaders = await axios(this.props.allState['importSep'] === "xml" ? `${process.env.REACT_APP_API_ROOT}/api/export/csv/structure` : `${process.env.REACT_APP_API_ROOT}/api/export/simplexml/structure`, 'post', headersFormData);
                this.state.headers = resultHeaders.message;                      
                this.setState({
                    [locale.node.code]: {
                        headers: (this.state.headers || []).map(e => ({
                            used: false,
                            label: e,
                            id: e
                        })),
                        options
                    }
                });
                resolve();
            }
        });
    };

    getValue = (value, label) => {
        if(value){
            return(value);
        }
        else{
            for(let option of this.state.options){
                if(option.label === label){
                    return option.id
                }
            }
            return '-1'
        }
    }


    componentDidMount() {
        if (this.props.input?.staticOptions) {
            let options = this.props.input.staticOptions;
    
            if (this.props.input.translated) {
                for(let locale of this.props.locales){
                    if(this.props.allState[locale.node.code][this.props.input.mapOn] !== null){
                        if(typeof(this.props.allState[locale.node.code][this.props.input.mapOn]) === "string"){
                            this.getHeader(locale, options)
                        }
                        else{
                            if(this.props.allState[locale.node.code][this.props.input.mapOn]?.headers){                                
                                this.setState({
                                    [locale.node.code]: {
                                        headers: (this.props.allState[locale.node.code][this.props.input.mapOn].headers || []).map(e => ({
                                            used: false,
                                            label: e,
                                            id: e
                                        })),
                                        options
                                    }
                                });
                            }
                        }
                    }
                }
            } else {
                if(this.props.allState[this.props.input.mapOn] !== null){                    
                    this.setState({ 
                        options,
                        headers: (this.props.allState[this.props.input.mapOn].headers || []).map(e => ({
                            used: false,
                            label: e,
                            id: e
                        })),
                    });
                }
            }

        } else {
            let options =[]
            if (this.props.input.mapperType !== 'brand') {                
                options = this.props.category
                    ? [ 
                        {
                            id: 'libelle',
                            label: 'Identifiant'
                        }, {
                            id: 'parent',
                            label: `Parent`
                        }
                    ]: [
                        {
                            id: 'sku',
                            label: 'Code article'
                        }, {
                            id: 'libelle',
                            label: 'Catégorie'
                        }, {
                            id: 'attributeGroup',
                            label: `Groupe d'attributs`
                        }
                    ];
            }
            
    
            let currentLang = this.props.locales[0].node.code;
    
            let attributes = this.props.category 
                ? this.props.attributes.category.attributes.edges
                : this.props.attributes.product.attributes.edges;
    
            for (let attribute of attributes) {
                const defaultLang = attribute.node.translation.translationDatas.edges[0];
    
                const langSelected = attribute.node.translation.translationDatas.edges.find(
                    lang => lang.node.locale.code === currentLang
                );
                if(attribute.node.status){
                    options.push({
                        id: attribute.node.id,
                        label: langSelected?.node.value ?? defaultLang.node.value
                    });
                }
            }

            if (this.props.input.translated) {
                for(let locale of this.props.locales){
                    if(this.props.allState[locale.node.code][this.props.input.mapOn] !== null){
                        if(typeof(this.props.allState[locale.node.code][this.props.input.mapOn]) === "string"){
                            this.getHeader(locale, options)
                        }
                        else{                            
                            this.setState({
                                [locale.node.code]: {
                                    headers: (this.props.allState[locale.node.code][this.props.input.mapOn].headers || []).map(e => ({
                                        used: false,
                                        label: e,
                                        id: e
                                    })),
                                    options
                                }
                            });
                        }
                    }
                }
            } else {                
                if(this.props.allState[this.props.input.mapOn] !== null){                        
                    this.setState({ 
                        options,
                        headers: (this.props.allState[this.props.input.mapOn] || []).map(e => ({                            
                            used: false,
                            label: e.code,
                            id: e.code,
                            deleted: e.deleted?e.deleted:false
                        })),
                    });
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props !== prevProps){
            if (this.props.input?.staticOptions) {
                let options = this.props.input.staticOptions;
        
                if (this.props.input.translated) {
                    for(let locale of this.props.locales){
                        if(this.props.allState[locale.node.code][this.props.input.mapOn] !== null){
                            if(typeof(this.props.allState[locale.node.code][this.props.input.mapOn]) === "string"){
                                this.getHeader(locale, options)
                            }
                            else{
                                if(this.props.allState[locale.node.code][this.props.input.mapOn]?.headers){                                    
                                    this.setState({
                                        [locale.node.code]: {
                                            headers: (this.props.allState[locale.node.code][this.props.input.mapOn].headers || []).map(e => ({
                                                used: false,
                                                label: e,
                                                id: e
                                            })),
                                            options
                                        }
                                    });
                                }
                            }
                        }
                    }
                } else {
                    if(this.props.allState[this.props.input.mapOn] !== null){                        
                        this.setState({ 
                            options,
                            headers: (this.props.allState[this.props.input.mapOn].headers || []).map(e => ({
                                used: false,
                                label: e,
                                id: e
                            })),
                        });
                    }
                }
    
            } else {
                let options
                if (this.props.input.mapperType === 'brand') {
                    options = []
                }else{
                    options = this.props.category
                        ? [ 
                            {
                                id: 'libelle',
                                label: 'Identifiant'
                            }, {
                                id: 'parent',
                                label: `Parent`
                            }
                        ]: [
                            {
                                id: 'sku',
                                label: 'Code article'
                            }, {
                                id: 'libelle',
                                label: 'Catégorie'
                            }, {
                                id: 'attributeGroup',
                                label: `Groupe d'attributs`
                            }
                        ];
                }
        
                let currentLang = this.props.locales[0].node.code;
        
                let attributes = this.props.category 
                    ? this.props.attributes.category.attributes.edges
                    : this.props.attributes.product.attributes.edges;
        
                for (let attribute of attributes) {
                    const defaultLang = attribute.node.translation.translationDatas.edges[0];
                    const langSelected = attribute.node.translation.translationDatas.edges.find(
                        lang => lang.node.locale.code === currentLang
                    );

                    if(attribute.node.status){
                        options.push({
                            id: attribute.node.id,
                            label: langSelected?.node.value ?? defaultLang.node.value
                        });
                    }
                }
    
                if (this.props.input.translated) {
                    for(let locale of this.props.locales){
                        if(this.props.allState[locale.node.code][this.props.input.mapOn] !== null){
                            if(typeof(this.props.allState[locale.node.code][this.props.input.mapOn]) === "string"){
                                this.getHeader(locale, options)
                            }
                            else{                                
                                this.setState({
                                    [locale.node.code]: {
                                        headers: (this.props.allState[locale.node.code][this.props.input.mapOn].headers || []).map(e => ({
                                            used: false,
                                            label: e,
                                            id: e
                                        })),
                                        options
                                    }
                                });
                            }
                        }
                    }
                } else {
                    if(this.props.allState[this.props.input.mapOn] !== null){
                        if (this.props.input.mapperType !== 'brand') {
                            // console.log(this.props.allState[this.props.input.mapOn])
                            // this.setState({ 
                            //     options,
                            //     headers: (this.props.allState[this.props.input.mapOn]).map(e => ({
                            //         used: false,
                            //         label: e,
                            //         id: e
                            //     })),
                            // });    
                            // console.log(this.state)
                            this.setState({ 
                                options,
                                headers: (this.props.allState[this.props.input.mapOn].headers || []).map(e => ({
                                    used: false,
                                    label: e,
                                    id: e
                                })),
                            });
                        }
                    }
                }
            }
        }
    }

    construct = () => {
        const { allState, stateCallback } = this.props;
            const { headers } = this.state;
    
            let mapper = [];
    
            for (let header of headers) {
                let value = allState.importValues[header.id];
                mapper.push(!value || value === '' || value === '-1' ? null : value);
            }
            if(this.props.input.translated){
                stateCallback(mapper, null, true, null);
            }else{
                stateCallback(mapper, null, null, null);
            }
    };

    render() {
        const { options, headers } = this.state;
        const { allState, stateCallback, input } = this.props;
        if(this.props.input.translated && this.state[allState.currentLang] && this.state.ftpReady){
            return (
                <div style={{ paddingTop: 15, paddingBottom: 15, backgroundColor: 'rgb(250, 251, 251)' }}>
                    {
                        this.state[allState.currentLang].headers.map((header, i) => {
                            let value = allState[allState.currentLang][this.props.input.stateName][header.id];
                            if (!value){
                                let values = allState[allState.currentLang][this.props.input.stateName];
                                for (let option of this.state[allState.currentLang].options){
                                    if(option.label.toLowerCase() === header.label.toLowerCase() || option.identifier?.toLowerCase() === header.id.toLowerCase()){
                                        values[header.id] = [option.id];
                                        option.used = true;
                                    }
                                }
                            }
                            return (
                                <div key={`attribute_${i}`} style={{
                                    padding: 10,
                                    backgroundColor: (!value || value === '' || value === '-1') ? '#fff' : 'rgb(224, 241, 251)',
                                    width: '100%',
                                    margin: '0 auto',
                                    marginBottom: 5
                                }}>
                                    <Grid container>
                                        <Grid item xs={6} style={{ 
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'flex-end',
                                            paddingRight: 20,
                                            borderRightWidth: 1,
                                            borderRightStyle: 'solid',
                                            fontWeight: (!value || value === '' || value === '-1') ? 'normal' : 'bold'
                                        }}>
                                            { header.label }
                                        </Grid>
                                        <Grid item xs={6} style={{ 
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'flex-start',
                                            paddingLeft: 20
                                        }}>
                                            <FormControl>
                                                <Select
                                                    value={allState[allState.currentLang][this.props.input.stateName] && allState[allState.currentLang][this.props.input.stateName][header.id] ? this.getValue(allState[allState.currentLang][this.props.input.stateName][header.id], header.label) : '-1'}
                                                    onChange={(evt) => {
                                                        this.setState({
                                                            [this.state[allState.currentLang].options] : 
                                                                this.state[allState.currentLang].options.map(e => {
                                                                    if (e.id === evt.target.value)
                                                                        e.used = true; // make option unavailable
                                                                    if (e.id === allState[allState.currentLang][this.props.input.stateName][header.id])
                                                                        e.used = false; // make old option available
                                                                    
                                                                    return e;
                                                                })
                                                        });
                                                        let values = allState[allState.currentLang][this.props.input.stateName];
                                                        values[header.id] = evt.target.value;
                                                        stateCallback(values, null, true, () => {
                                                            this.construct();
                                                        });
                                                    }}
                                                >
                                                    <MenuItem value="-1">
                                                        <em>Aucun</em>
                                                    </MenuItem>
    
                                                    {
                                                        this.state[allState.currentLang].options.map((e, i) => (
                                                            <MenuItem key={`header-${i}`} disabled={e.used} value={e.id}>{ e.label }</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </div>
                            );
                        })  
                    }
                    { 
                        !this.state[allState.currentLang] || this.state[allState.currentLang].headers.length === 0 ? (
                            <Typography style={{background: "white", padding: 15, width: "100%", display: "block"}}>Aucun Mapper, veuillez revoir l'étape précédente.</Typography>
                        ) : null
                    }
                </div>
            );
        }

        else if(input.translated && allState.importFile === "ftp" && !allState[allState.currentLang][input.mapOn + '_import'] || ((allState.importFile === "ftp" || allState.importFile === "sftp") && !this.state.ftpReady)){
            return(
                <PageLoader />
            );
        }
        else if(this.props.input.translated && !this.state[allState.currentLang] && this.state.ftpReady){
            return(
                <Typography style={{background: "white", padding: 15, width: "100%", display: "block"}}>Aucun Mapper, veuillez revoir l'étape précédente.</Typography>
            );
        }
        else if(this.props.input.mapperType === 'brand'){
            return(
                <div style={{ paddingTop: 15, paddingBottom: 15, backgroundColor: 'rgb(250, 251, 251)' }}>                    
                {
                    console.log('options',options)
                }
                {
                    console.log('allState',allState)
                }
                {
                    console.log('headers',headers)
                }
                {
                    console.log('input',this.props.input)
                }
                    {
                        options.map((option, i) => {
                            let value = allState[this.props.input.stateName][option.id];
                            return (
                                <div key={`attribute_${i}`} style={{
                                    padding: 10,
                                    backgroundColor: (!value || value === '' || value === '-1') ? '#fff' : 'rgb(224, 241, 251)',
                                    width: '100%',
                                    margin: '0 auto',
                                    marginBottom: 5
                                }}>
                                    <Grid container>
                                        <Grid item xs={6} style={{ 
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'flex-end',
                                            paddingRight: 20,
                                            borderRightWidth: 1,
                                            borderRightStyle: 'solid',
                                            fontWeight: (!value || value === '' || value === '-1') ? 'normal' : 'bold'
                                        }}>
                                            { option.label }
                                        </Grid>
                                        <Grid item xs={6} style={{ 
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'flex-start',
                                            paddingLeft: 20
                                        }}>
                                            <FormControl>
                                                <Select
                                                    value={allState[this.props.input.stateName] && allState[this.props.input.stateName][option.id] ? allState[this.props.input.stateName][option.id] : '-1'}
                                                    onChange={(evt) => {                                                                                                        
                                                        this.setState({
                                                            headers: headers.map(e => {
                                                                if (e.id === evt.target.value)                                                                
                                                                    e.used = true;  // make option unavailable
                                                                if (e.id === allState[this.props.input.stateName][option.id])                                                                
                                                                    e.used = false; // make old option available
                                                                
                                                                return e;
                                                            })
                                                        });                                                              
                                                        let values = allState[this.props.input.stateName];
                                                        values[option.id] = evt.target.value;           
                                                        stateCallback(values, null, null, () => {
                                                            this.construct();
                                                        });                                                                                            
                                                    }}
                                                >
                                                    <MenuItem value="-1">
                                                        <em>Aucun</em>
                                                    </MenuItem>
    
                                                    {
                                                        headers.map((e, i) => (
                                                            !e.deleted?
                                                            <MenuItem key={`header-${i}`} value={e.id}>{ e.label }</MenuItem>
                                                            :null
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </div>
                            );
                        })
                    }
                </div>
            );
        }
        else{
            return (
                <div style={{ paddingTop: 15, paddingBottom: 15, backgroundColor: 'rgb(250, 251, 251)' }}>                    
                    {
                        headers.map((header, i) => {
                            let value = allState[this.props.input.stateName][header.id];
                            return (
                                <div key={`attribute_${i}`} style={{
                                    padding: 10,
                                    backgroundColor: (!value || value === '' || value === '-1') ? '#fff' : 'rgb(224, 241, 251)',
                                    width: '100%',
                                    margin: '0 auto',
                                    marginBottom: 5
                                }}>
                                    <Grid container>
                                        <Grid item xs={6} style={{ 
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'flex-end',
                                            paddingRight: 20,
                                            borderRightWidth: 1,
                                            borderRightStyle: 'solid',
                                            fontWeight: (!value || value === '' || value === '-1') ? 'normal' : 'bold'
                                        }}>
                                            { header.label }
                                        </Grid>
                                        <Grid item xs={6} style={{ 
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'flex-start',
                                            paddingLeft: 20
                                        }}>
                                            <FormControl>
                                                <Select
                                                    value={allState[this.props.input.stateName] && allState[this.props.input.stateName][header.id] ? allState[this.props.input.stateName][header.id] : '-1'}
                                                    onChange={(evt) => {
                                                        this.setState({
                                                            options: options.map(e => {
                                                                if (e.id === evt.target.value)
                                                                    e.used = true; // make option unavailable
                                                                if (e.id === allState[this.props.input.stateName][header.id])
                                                                    e.used = false; // make old option available
                                                                
                                                                return e;
                                                            })
                                                        });
                                                        let values = allState[this.props.input.stateName];
                                                        values[header.id] = evt.target.value;
                                                        stateCallback(values, null, null, () => {
                                                            this.construct();
                                                        });
                                                    }}
                                                >
                                                    <MenuItem value="-1">
                                                        <em>Aucun</em>
                                                    </MenuItem>
    
                                                    {
                                                        options.map((e, i) => (
                                                            <MenuItem key={`header-${i}`} disabled={e.used} value={e.id}>{ e.label }</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </div>
                            );
                        })
                    }
                </div>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
        attributes: state.attributes, 
        locales: state.locales,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING }),
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message }})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Mapping);