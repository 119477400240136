import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import * as moment from 'moment';
import { SNACK, START_LOADING, STOP_LOADING } from '../../../../js/constants/action-types';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../../js/constants/alert-types';
import { ROUTE_HOME } from '../../../../js/constants/route-names';
import { BUILDER, BUILDER_ASSETS, VIEW, UPDATE } from '../../../../js/constants/constant-rights';
import { withTranslation } from 'react-i18next';
import TopPanel from '../../../layouts/TopPanel/TopPanel';
import colors from '../../../../config/theme/colors';
import EmptyCard from "../../../ui/empty-card/EmptyCard";
import LayoutBuilder from '../../../ui/form/LayoutFormBuilder';
import notificationsEdit from './config/notifications/notificationsEdit.config';
import { eventService } from '../../../../js/services/event.service';
import { saveElement } from '../../../../js/utils/functions';
import request from '../../../../js/utils/fetch';
import PageLoader from "../../../ui/loadings/page-loader/PageLoader";
import { checkRouting } from '../../../../js/utils/checkRouting';
import { GET_ASSET_BY_ID_NAVIGATION } from '../../../../queries/assets';

function EngineAssetsDetailsNotifications(props) {
    const [asset, setAsset] = React.useState(props.match.params?.id ? props.match.params?.id : null);
    const [ready, setReady] = React.useState(false);
    const [listGroups, setListGroups] = React.useState([]);
    const [currentLang, setCurrentLang] = React.useState(props.locales[0].node.code);
    const [errors, setErrors] = React.useState({});
    const [states, setStates] = React.useState({
        notificationTitle: null,
        notificationMessage: null,
        // notificationLink: null,
        notificationScheduledAt: null,
        notificationGroups: [],
        openForm: false
    });

    useEffect(() => {
        checkRouting(props)
        initGroups()
        if (!JSON.parse(localStorage.getItem('ASSET_CURRENT'))) {
            initNavAsset()
        }
    }, [])

    const initNavAsset = () => {
        return new Promise((resolve, reject) => {
            props.client.query({
                query: GET_ASSET_BY_ID_NAVIGATION,
                variables: { id: `/api/assets/${props.match.params.id}` },
                fetchPolicy: 'no-cache'
            }).then(result => {
                window.setAssetNameMenu(result.data.asset)
                resolve()
            })
        })
    }

    const goTo = route => {
        props.history.push(route);
    };

    const initGroups = () => {
        return new Promise((resolve, reject) => {
            request(`${process.env.REACT_APP_API}/application/usergroups`, 'get', false).then(
                (data) => {
                    if (data.success) {
                        setListGroups(data.datas)
                        setReady(true)
                    }
                    resolve()
                }
            );
            resolve()
        })
    }

    const resetStates = () => {
        setStates({
            notificationTitle: null,
            notificationMessage: null,
            // notificationLink: null,
            notificationScheduledAt: null,
            notificationGroups: [],
            openForm: false
        })
    };

    const handleToggleDrawer = (stateDrawer) => {
        let getState = { ...states };
        getState[stateDrawer] = !states[stateDrawer];
        setStates(getState)
    };

    const handleLang = (event) => {
        setCurrentLang(event.target.value)
        eventService.fire();
    };

    const stateCallback = (stateName, value, custom, translated, callback) => {
        let getState = { ...states };
        getState[stateName] = value?.target?.value ?? value;
        setStates(getState)
    };

    const handleFormError = (stateName, error) => {
        let getErrors = errors;
        getErrors[stateName] = error;
        setErrors(errors)
    };

    const handlerMutation = async () => {
        props.startLoading()
        let variables = null;
        let getAllTokens = [];
        for (let group of states.notificationGroups) {
            if (listGroups.find(e => e.name === group)) {
                let getElements = listGroups.find(e => e.name === group).tokens
                getAllTokens.push(...getElements)
            }
        }
        getAllTokens = getAllTokens.map(e => `/api/app-tokens/${e}`)
        variables = {
            asset: `/api/assets/${asset}`,
            title: states.notificationTitle,
            message: states.notificationMessage,
            // link: states.notificationLink,
            scheduledAt: moment(states.notificationScheduledAt).toISOString(),
            appToken: getAllTokens,
            status: "pending"
        }
        try {
            await saveElement('appNotification', variables, { enableLoad: false })
            props.snack(ALERT_SUCCESS, 'Notification ajoutée avec succès');
        } catch {
            props.snack(ALERT_ERROR, 'Une erreur est survenu lors de la création de la notification');
        }
        resetStates();
        handleToggleDrawer('openForm', true);
        props.stopLoading();
    }

    return (
        <div>
            <TopPanel
                icomoon={"ico-notifications"}
                colorIcomoon={colors.blue.darker.hue300}
                title={props.t("spread_builder.notifications.title")}
                ready={ready}
                gradientColor1={colors.menu.regular}
                gradientColor2={colors.menu.darker}
                openForm={states.openForm}
                currentLang={currentLang}
                handleLang={handleLang}
                locales={props.locales}
                hasBorder={true}
            />
            {
                ready ?
                    <>
                        <EmptyCard title={props.t("spread_builder.notifications.message_title")} textButton={props.t("spread_builder.notifications.button")} onClick={() => { handleToggleDrawer("openForm") }} openForm={states.openForm} xsImg={states.openForm ? 4 : 2} />
                        <LayoutBuilder
                            isSublayout={false}
                            icomoon="ico-notifications"
                            opened={states.openForm}
                            forClose={() => handleToggleDrawer('openForm', true)}
                            handlerSetup={() => { }}
                            dataLayout={notificationsEdit('add', listGroups)}
                            drawerWidth={props.drawerWidth}
                            allState={states}
                            stateCallback={stateCallback}
                            errorCallback={handleFormError}
                            // handleButtonGroupChange={this.handleButtonGroupChange}
                            validateButton={true}
                            handlerMutation={handlerMutation}
                        // currentLang={currentLang}
                        // handleLang={this.handleLang}
                        />
                    </>
                    :
                    <PageLoader />
            }
        </div>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        locales: state.locales
    };
};

const mapDispatchToProps = dispatch => {
    return {
        snack: (type, message) => dispatch({ type: SNACK, payload: { type, message } }),
        startLoading: () => dispatch({ type: START_LOADING }),
        stopLoading: () => dispatch({ type: STOP_LOADING })
    }
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(EngineAssetsDetailsNotifications)));
