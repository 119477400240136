import React from 'react';
import {Grid, Box} from '@material-ui/core';
import Typography from '../../../ui/typography/Typography';
import CardCustom from '../../../layouts/Card/CardCustom';
import Button from '../../../ui/button/Button';
import colors from '../../../../config/theme/colors';
import CardHomepageStats from '../../../layouts/Card/cardContent/CardHomepageStats';
import CardActions from '../../../layouts/Card/cardContent/CardActions';

import { withRouter } from 'react-router';
import { connect } from "react-redux";

import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import SliderActu from './components/SliderActu';
import { withApollo } from 'react-apollo';

import Image1 from '../../../../../src/assets/images/socials/Stats.png';
import Image2 from '../../../../../src/assets/images/socials/Calendar.png';
import BannerVideo from '../../../../../src/assets/images/banner_video.png';
import PictoVideo from '../../../../../src/assets/images/pictoVideo.svg';

import { GET_CATALOGS_TOTAL } from '../../../../queries/catalogs';
import { GET_ASSETS_TOTAL } from '../../../../queries/assets';
import { GET_PRODUCTS_TOTAL } from '../../../../queries/products';

import { 
    ROUTE_STATS_ORDERS,
    ROUTE_CATALOGS_LIST,
    ROUTE_MEDIAS,
    ROUTE_CHANNELS,
    ROUTE_SETTINGS_LANGUAGES,
    ROUTE_CRM_CONTACTS
} from '../../../../js/constants/route-names';


const notifications = [
    {
        date: '19/09/2022',
        text: 'Nouveau ! vous pouvez dès maintenant créer un catalogue éphémère en déterminant une date de début et une date de fin et en y associant les catégories et produits de votre choix',
        id: uuidv4()
    },
    {
        date: '19/09/2022',
        text: 'Agissez sur votre réseau social facebook avec Sinfin DXP c’est maintenant possible ! Il vous suffit de vous rendre dans l’onglet canaux de diffusion : Réseaux sociaux et d’y ajouter votre connecteur Facebook.',
        id: uuidv4()
    },
];
const sliderData = [
    {
        title: 'Ajuster votre stratégie en consultant vos statistiques en temps réel :',
        text: 'Un découpage de dashboard est en place dans chacun des onglets pour vous permettre d’obtenir en un clic le récapitulatif de vos dernières datas. Un dashboard complet pour orienter vos stratégies commerciales/ Marketing est à votre disposition dans l’onglet statistiques : top des ventes produits, produits les plus vus... Dans la partie produits, vous pouvez avoir une vision sur les dernières mises à jour produits, stocks, prix qui ont été effectuées. Vous possédez à votre disposition tous les leviers sur lesquelles vous pouvez performer.',
        image: Image1,
        id: uuidv4()
    },
    {
        title: 'C’est tout nouveau !',
        text: 'Dans l’onglet Calendrier retrouvez toutes vos activations et les informations associées à celles-ci afin de vous apporter un accompagnement sur la répartition à venir de votre stratégie Marketing. Obtenez une vision globale des activités à venir ou appliquez des filtres par campagne ou retailer pour cibler l’information.',
        image: Image2,
        id: uuidv4()
    },
];

const ContainerBlocNotification = styled(Box)`
    border-radius: 10px;
    position: relative;
`
const ContainerNotification = styled(Box)`
    background: ${colors.blue.lighter.hue900};
    border-radius: 10px;
    position: relative;
`
const RemoveNotification = styled(Button)`
    background: ${colors.blue.lighter.hue900};
    border-radius: 10px;
`

class Homepage extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            lastName: '',
            firstName:'',
            age: '',
            birth: '',
            description:'',
            openForm: false,
            notifications,
            statsHomepage: [
                {
                    'icon': 'icon-catalogue',
                    'data': '-',
                    'dataColor': colors.blue.darker.hue300,
                    'dataCustomFont': '18px',
                    'subtitle': 'Catalogue',
                    'subtitleCustomFont': '16px',
                    'subtitleColor': colors.blue.darker.hue300,
                    'gradientColor1': colors.blue.darker.hue300,
                    'gradientColor2': colors.black.regular,
                },
                {
                    'icon': 'icon-asset',
                    'data': '-',
                    'dataColor': colors.blue.darker.hue300,
                    'dataCustomFont': '18px',
                    'subtitle': 'Asset actif',
                    'subtitleCustomFont': '16px',
                    'subtitleColor': colors.blue.darker.hue300,
                    'gradientColor1': colors.blue.darker.hue300,
                    'gradientColor2': colors.black.regular,
                },
                {
                    'icon': 'picto-produit',
                    'data': '-',
                    'dataColor': colors.blue.darker.hue300,
                    'dataCustomFont': '18px',
                    'subtitle': 'Produit',
                    'subtitleColor': colors.blue.darker.hue300,
                    'subtitleCustomFont': '16px',
                    'gradientColor1': colors.blue.darker.hue300,
                    'gradientColor2': colors.black.regular,
                },

            ]
        }
        this.actions=[
            {
                'icon': 'icon-statistique',
                'title':'Statistiques',
                'subtitle': 'Visionnez l’ensemble de vos statistiques et actionnez les leviers de vos actions marketing',
                'gradientColor1': colors.white,
                'gradientColor2': colors.white,
                'colorPicto': colors.blue.darker.hue300,
                'color': colors.black.regular,
                'colorHover': colors.blue.darker.hue300,
                'route': ROUTE_STATS_ORDERS,
                // 'routeState': {openMediaForm: true, buttonAvailable: true}
            },
            {
                'icon': 'icon-asset',
                'title':'Sinfin DXP Engine',
                'subtitle': 'Créez votre catalogue produits puis diffusez-le sur les assets de vos choix',
                'gradientColor1': colors.white,
                'gradientColor2': colors.white,
                'colorPicto': colors.blue.darker.hue300,
                'color': colors.black.regular,
                'colorHover': colors.blue.darker.hue300,
                'route': ROUTE_CATALOGS_LIST,
                // 'routeState': {openForm: true, buttonAvailable: true}
            },
            {
                'icon': 'picto-categorie',
                'title':'Média',
                'subtitle': 'Accédez à l’ensemble des données concernant vos médias et créez en des nouveaux',
                'gradientColor1': colors.white,
                'gradientColor2': colors.white,
                'colorPicto': colors.blue.darker.hue300,
                'color': colors.black.regular,
                'colorHover': colors.blue.darker.hue300,
                'route': ROUTE_MEDIAS,
                // 'routeState': {openMediaForm: true, buttonAvailable: true}
            },
            {
                'icon': 'icon-contacts',
                'title':'Contacts',
                'subtitle': 'Passez à la création de vos contacts ou importez-les directement',
                'gradientColor1': colors.white,
                'gradientColor2': colors.white,
                'colorPicto': colors.blue.darker.hue300,
                'color': colors.black.regular,
                'colorHover': colors.blue.darker.hue300,
                'route': ROUTE_CRM_CONTACTS,
                // 'routeState': {openForm: true, buttonAvailable: true}
            },
            {
                'icon': 'icon-cms',
                'title':'Canaux de diffusion',
                'subtitle': 'Obtenez une vision d’ensemble sur vos canaux de diffusion en place (marketplace, réseaux sociaux ..)',
                'gradientColor1': colors.white,
                'gradientColor2': colors.white,
                'colorPicto': colors.blue.darker.hue300,
                'color': colors.black.regular,
                'colorHover': colors.blue.darker.hue300,
                'route': ROUTE_CHANNELS,
                // 'routeState': {openMediaForm: true, buttonAvailable: true}
            },
            {
                'icon': 'picto-parametre',
                'title':'Paramètres',
                'subtitle': 'Accédez à la gestion de vos paramétrages et personnalisez votre environnement',
                'gradientColor1': colors.white,
                'gradientColor2': colors.white,
                'colorPicto': colors.blue.darker.hue300,
                'color': colors.black.regular,
                'colorHover': colors.blue.darker.hue300,
                'route': ROUTE_SETTINGS_LANGUAGES,
                // 'routeState': {openForm: true, buttonAvailable: true}
            },
        ]
    }

    removeNotification(id) {
        const newState = this.state;
        const index = newState.notifications.findIndex(a => a.id === id);
    
        if (index === -1) return;
        newState.notifications.splice(index, 1);
    
        this.setState(newState); // This will update the state and trigger a rerender of the components
    }

    handleToggleDrawer = () => {
        this.setState({ 
            openForm : !this.state.openForm
        });
    }

    handleInputChange = (stateName, evt) => {
        const value = evt.target.value;
    
        this.setState({
            ...this.state,
            [stateName]: value
        });
    }

    componentDidMount(){
        this.setState({
            lastName: 'DOE',
            firstName: 'John',
            age: 20,
            birth: '01/01/2000',
            description:'Hello world',
            openForm: false,
        });
        this.getTotalCatalogs()
        this.getTotalAssets()
        this.getTotalProducts()
    }

    render() {
        return (
            <div>
                <Grid container direction="column" justifyContent="center" spacing={0}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={9}>
                            <Box pt={3} pb={1}>
                                <CardCustom paddingbottom="0px" paddingtop="0px">
                                    <CardHomepageStats statsHomepage={this.state.statsHomepage} user={this.props.user} windowWidth={this.props.windowWidth}/>
                                </CardCustom>
                                <CardCustom paddingbottom="0px" paddingtop="0px" style={{marginBottom: 8, marginTop: 16, background: `url(${BannerVideo})`, height: 160, backgroundSize: 'cover', backgroundPosition: '0px'}}>
                                    <div style={{display: 'flex', justifyContent: 'space-between', alignItem: 'center', flexDirection: 'row', alignItems: 'center', height: '100%', maxWidth: 'calc(100% - 50px)', width: 900, marginLeft: 50}}>
                                        <div>
                                            <Typography variant="h2" style={{color: colors.white, marginBottom: 8}}>Découvrez le fonctionnement de Sinfin DXP</Typography>
                                            <Typography variant="body1" style={{color: colors.white, maxWidth: 460}}>Sinfin DXP est une plateforme de Content Marketing Experience à destination des marques.</Typography>
                                        </div>
                                        <div>
                                            <img src={PictoVideo} />
                                        </div>

                                    </div>
                                </CardCustom>
                            </Box>
                            <Box>
                                <Grid container direction="row" spacing={2}>
                                    {   
                                        this.actions ? (this.actions.map((action,index)=>
                                            <Grid item lg={4} xs={4} key={`CardActions${index}`} style={{marginRight: this.props.windowWidth < 1400 ? '20px' : '0px'}}>
                                                <CardCustom  style={{marginTop: this.props.windowWidth < 1400 ? '0px' : action.marginTop, background: `transparent linear-gradient(118deg, ${action.gradientColor1} 0%, ${action.gradientColor2} 100%) 0% 0% no-repeat padding-box`}}>
                                                    <CardActions action={action} goTo={this.goToAction}></CardActions>
                                                </CardCustom>
                                            </Grid>
                                        )): null
                                    }
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Box pt={3} pb={2}>
                                <CardCustom style={{height: 'calc(90vh - 90px)', overflow: 'auto'}}>
                                    <Box mt={2} mb={2}>
                                        <Typography variant="h3" colortext={colors.blue.darker.hue300}>Notifications</Typography>
                                    </Box>
                                    <ContainerBlocNotification>
                                        {this.state.notifications.map((notification,index) => {
                                            return(
                                                <ContainerNotification p={2} mt={2} pb={3} key={`Notification${index}`}>
                                                    <Typography 
                                                        style={{fontWeight: 'bold', fontSize: '12px', lineHeight: 'inherit'}} 
                                                        colortext={colors.blue.darker.hue300}
                                                    >{notification.date}</Typography>
                                                    <Box mt={1}>
                                                        <Typography variant={"body2"} style={{fontWeight: 'normal'}}>{notification.text}</Typography>
                                                    </Box>
                                                    <RemoveNotification 
                                                        bgcolor={"transparent"} 
                                                        bgcolorhover={"transparent"} 
                                                        color={colors.blue.darker.hue300} 
                                                        fontsize={16}
                                                        style={{
                                                            padding: '0px 10px',
                                                            position: 'absolute',
                                                            right: '1rem',
                                                            minWidth: 'auto',
                                                        }} 
                                                        onClick={() => this.removeNotification(notification.id)}
                                                    >x</RemoveNotification>
                                                </ContainerNotification>
                                            )
                                        })}
                                        <Button 
                                            bgcolor={"transparent"} 
                                            bgcolorhover={"transparent"} 
                                            color={colors.blue.darker.hue300} 
                                            fontsize={24}
                                            style={{
                                                minWidth: 'auto',
                                                padding: '10px',
                                                lineHeight: 'unset',
                                                margin: 0,
                                                left:'70%',
                                                borderRadius:'50%',
                                            }}
                                            contentStyle={{
                                                top: '-8px',
                                                position: 'relative',
                                            }}
                                            onClick={() => console.log('SeeMore clicked')} 
                                        >...</Button>
                                    </ContainerBlocNotification>
                                    <Box mt={3} mb={2}>
                                        <Typography variant="h3" colortext={colors.blue.darker.hue300}>C'est tout nouveau !</Typography>
                                    </Box>
                                    <Box>
                                        <SliderActu sliderData={sliderData}/>
                                    </Box>
                                </CardCustom>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
    goToAction = (route) => {
        this.props.history.push(route);
    };

    getTotalCatalogs = () => {
        this.props.client.query({
            query: GET_CATALOGS_TOTAL,
        }).then(result => {
            let getCatalogsStatus = result.data.catalogs.edges;
            let catalogsActive = getCatalogsStatus.filter(e => e.node.status === true);
            this.setState({
                totalCatalog: catalogsActive.length,
                [this.state.statsHomepage[0].data] : this.state.totalCatalog,
            });
            if(catalogsActive.length > 1){
                this.setState({
                    [this.state.statsHomepage[0].subtitle] : 'Catalogues',
                });
            }
            this.forceUpdate()
        });
    }
    getTotalAssets = () => {
        this.props.client.query({
            query: GET_ASSETS_TOTAL,
        }).then(result => {
            this.setState({
                totalAssets: result.data.assets.totalCount,
                [this.state.statsHomepage[1].data] : this.state.totalAssets,

            });
            if(this.state.totalAssets > 1){
                this.setState({
                    [this.state.statsHomepage[1].subtitle] : 'Assets actifs',
                });
            }
            this.forceUpdate()
        });
    }
    getTotalProducts = () => {
        this.props.client.query({
            query: GET_PRODUCTS_TOTAL,
        }).then(result => {
            this.setState({
                totalProducts: result.data.products.totalCount,
                [this.state.statsHomepage[2].data] : this.state.totalProducts,
            });
            if(this.state.totalProducts > 1){
                this.setState({
                    [this.state.statsHomepage[2].subtitle] : 'Produits',
                });
            }
            this.forceUpdate()
        });
    }
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        products: state.products,
    };
};
export default withApollo(withRouter(connect(mapStateToProps, null)(Homepage)));
