import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDrop } from 'react-dnd';
import H1 from '../../../../shared/components/CustomH1';
import H2 from '../../../../shared/components/CustomH2';
import Pg from '../../../../shared/components/CustomParagraph';

const BlocBasic2 = (props) => {
    const [hover, setHover] = useState(false);
    const [collectedProps, drop] = useDrop({
        accept: ['media'],
        drop: (item) => {
            data.inputs['backgroundImage'].value = item.media.node;
            update();
        }
    });

    let {   
        preview = false,
        id = null, 
        data = null,
        inputCallback = null,
        update = null,
    } = props;

    let image = data.inputs['backgroundImage'].value;

    return (
        <div 
        ref={drop}
        style={{
            backgroundColor: data.inputs['backgroundColor'].value,
            backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
            backgroundSize: "100%",
            backgroundPosition: "bottom center",
            backgroundRepeat: "no-repeat",
            position: 'relative'
        }}
        className={'typeSection'}
        onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
    >
        { !preview && props.children }

        <a class="reglement">Règlement du jeu</a>
        <section class="first overflow_hidden" style={{minHeight: '500px',padding: '50px 0',display: 'flex',flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center'}}>
            <img class="logo" onClick={() => inputCallback(id, 'logo')}
                style={{    width: 'auto', maxWidth: '45%', minWidth: 330}}
                src={
                    typeof data.blocks.logo.inputs.value.value === 'string' ? 
                        data.blocks.logo.inputs.value.value : 
                        data.blocks.logo.inputs.value?.value?.filePath ? 
                            ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.logo.inputs.value.value.filePath}` 
                            : "http://via.placeholder.com/330x110/FFFFFF/53839D?text=Logo"
                    }
                alt="Logo jeu"
            />
            <H1 data={data} preview={preview} component={id} block={'title'} inputCallback={inputCallback} nopadding={true} />
            <H2 data={data} preview={preview} component={id} block={'subtitle'} inputCallback={inputCallback} nopadding={true} />
            <Pg data={data} preview={preview} className="center" component={id} block={'text'} inputCallback={inputCallback} nopadding={true} />
        </section>
    </div>
    );
};

BlocBasic2.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingClass: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default BlocBasic2;
