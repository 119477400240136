import React from "react";
import colors from '../../../colors';
import Rules from '../../assets/game/components/amazing/Rules';
import RulesInlineFlex from '../../assets/game/components/amazing/RulesInlineFlex';
import OneImageBlock from '../../assets/game/components/amazing/OneImageBlock';
import AmazingBlocGame from '../../assets/game/components/explanations/AmazingBlocGame';
import SocialList from "../../assets/game/components/amazing/SocialList";
import Gallery from "../../assets/game/components/amazing/Gallery";

const AmazingBlock = ({ data, preview, inputCallback, lang }) => {
    switch (data.key) {
        case 'title': 
            return (
                <div style={{border: !preview ? `1px dashed ${colors.blue.lighter.hue150}` : null}}>
                    <p 
                        className={`title ${data.inputs.decoration.value}`} 
                        key={data.key}
                        data-color={data.inputs.color?.value}
                        style={{
                            fontSize: data.inputs.size?.value,
                            lineHeight: '1.2',
                            color: data.inputs.color?.value,
                            '--tooltip-color': data.inputs.color?.value,
                            backgroundColor: data.inputs.backgroundColor?.value,
                            textTransform: data.inputs.textTransform?.value,
                            textAlign: data.inputs.textAlign?.value,
                            margin: 'auto',
                            marginTop: data.inputs.margin.inputs.marginTop.value,
                            marginBottom: data.inputs.margin.inputs.marginBottom.value,
                            borderStyle: 'solid',
                            borderWidth: data.inputs.border.inputs?.borderWidth?.value ? data.inputs.border.inputs.borderWidth.value : '0px',
                            borderColor: data.inputs.border.inputs?.borderColor?.value ? data.inputs.border.inputs.borderColor.value : null,
                            borderRadius: data.inputs.border.inputs?.borderRadius?.value ? data.inputs.border.inputs.borderRadius.value : null,
                            width: `${data.inputs['width'].value}%`,
                            maxWidth: data.inputs['maxWidth'].value,
                            paddingTop: data.inputs.padding.inputs.paddingTop.value,
                            paddingBottom: data.inputs.padding.inputs.paddingBottom.value,
                        }}
                        dangerouslySetInnerHTML={{ __html: data.inputs.value.value }}
                    />
                </div>
            );
        case 'paragraph': 
            return (
                <div style={{border: !preview ? `1px dashed ${colors.blue.lighter.hue150}` : null}}>
                    <p
                        key={data.key}
                        data-color={data.inputs.color?.value}
                        className={data.inputs.className?.value}
                        style={{
                            fontSize: data.inputs.size?.value,
                            lineHeight: '1.2',
                            color: data.inputs.color?.value,
                            '--tooltip-color': data.inputs.color?.value,
                            backgroundColor: data.inputs.backgroundColor?.value,
                            textTransform: data.inputs.textTransform?.value,
                            textAlign: data.inputs.textAlign?.value,
                            margin: 'auto',
                            marginTop: data.inputs.margin.inputs.marginTop.value,
                            marginBottom: data.inputs.margin.inputs.marginBottom.value,
                            width: `${data.inputs['width'].value}%`,
                            maxWidth: data.inputs['maxWidth'].value,
                            paddingTop: data.inputs.padding.inputs.paddingTop.value,
                            paddingBottom: data.inputs.padding.inputs.paddingBottom.value,
                        }}
                        dangerouslySetInnerHTML={{ __html: data.inputs.value.value }}
                    />
                </div>
            );
        case 'rules': 
            return ( <Rules data={data} preview={preview} block={'rules'} inputCallback={inputCallback} /> );
        case 'rules-inline-flex': 
            return ( <RulesInlineFlex data={data} preview={preview} block={'rules'} inputCallback={inputCallback} /> );
        case 'one-image':
            return ( <OneImageBlock data={data} preview={preview} block={'dotations'} inputCallback={inputCallback} />);
        case 'social-list':
            return ( <SocialList data={data} preview={preview} block={'socials'} inputCallback={inputCallback} lang={lang}/>);
        case 'gallery':
            return ( <Gallery data={data} preview={preview} block={'gallery'} inputCallback={inputCallback} />);
        case 'button':
            let image = data.inputs['backgroundImage']?.value;
            return (
                <div style={{
                    display: 'flex',
                    justifyContent: data.inputs.alignItem?.value,
                }}>
                    <a 
                        className={'actionButton'} 
                        style={{
                            fontSize: data.inputs.size.value,
                            color: data.inputs.color.value,
                            backgroundColor: data.inputs.backgroundColor.value,
                            backgroundImage: typeof image === 'string' ? `url(${image})` : image?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${image.filePath})` : 'none',
                            backgroundPosition: 'center center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            lineHeight: '2',
                            borderStyle: 'solid',
                            borderWidth: data.inputs.border?.inputs?.borderWidth?.value ? data.inputs.border.inputs.borderWidth.value : '0px',
                            borderColor: data.inputs.border?.inputs?.borderColor?.value ? data.inputs.border.inputs.borderColor.value : null,
                            borderRadius: data.inputs.border?.inputs?.borderRadius?.value ? data.inputs.border.inputs.borderRadius.value : null,
                            padding: `${data.inputs?.paddingVertical?.value ?? 5}px ${data.inputs?.paddingHorizontal?.value ?? 10}px`,
                            textTransform: data.inputs?.textTransform?.value,
                            fontWeight: 'normal',
                            cursor: 'pointer',
                            textDecoration: 'none',
                        }}
                        target={data.inputs.link?.value ? '_blank' : null}
                        href={data.inputs.link?.value ? data.inputs.link?.value : typeof window !== 'undefined' ? window.location.href : typeof document !== 'undefined' ? document.location.href : null}
                    >
                        { data.inputs.value.value }
                    </a> 
                </div>
            )
        case 'amazing':
            return ( <AmazingBlocGame data={data} preview={preview} block={'amazing'} inputCallback={inputCallback} />)
        default: return null;
    }
};

export default AmazingBlock;