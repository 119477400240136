import { useQuery } from "react-query";
import { GET_ATTRIBUTES_BY_TYPE } from ".";
import { GET_ATTRIBUTE_TYPES, GET_ATTR_GROUPE_WITH_ATTRIBUTES } from "../attributes";
import _request from "../_request";

export function useAttributes(id) {
    return useQuery(
        ["attributes", id],
        async () => {
            return await _request(
                process.env.REACT_APP_API_GQL,
                GET_ATTRIBUTES_BY_TYPE,
                { id }
            );
        },
        { enabled: !!id }
    );
}

export function fetchAttributes(client, id) {
    return client.fetchQuery(
        ["attributes", id],
        async () => {
            return await _request(
                process.env.REACT_APP_API_GQL,
                GET_ATTRIBUTES_BY_TYPE,
                { id }
            );
        }
    );
}
export function fetchAttributesTypes(client, id) {
    return client.fetchQuery(
        ["attributeTypes"],
        async () => {
            return await _request(
                process.env.REACT_APP_API_GQL,
                GET_ATTRIBUTE_TYPES,
            );
        }
    );
}

export function fetchGroupsWithAttributes(client) {
    return client.fetchQuery(
        "attributesByGroup",
        async () => {
            return await _request(
                process.env.REACT_APP_API_GQL,
                GET_ATTR_GROUPE_WITH_ATTRIBUTES
            );
        }
    );
}