import React, {useState} from "react";
import Div from '../../../../shared/components/CustomDiv';
import PG from "../../../../shared/components/CustomDiv";
import ModalEmailShare from '../blocks/results/ModalEmailShare';
import request from "../../../../../tools/Fetch";

export default ({ data, preview, component, block, inputCallback, className , id, actionClick, lang}) => {
    var socials = data.blocks[block].config;
    const [openModal, setOpenModal] = useState(false);

    function toggleModal(){
        setOpenModal(!openModal)
    }

    function sendAllMail(){
        let data = new FormData();
        data.append('username', localStorage.getItem('username'));
        data.append('link', window.linkShop);
        data.append('token', process.env.REACT_APP_ASSET_TOKEN);
        data.append('send', true);
        try {
            request(`${process.env.REACT_APP_API}/public/asset/registration/send/share-emails`, 'post', data, 'multipart/form-data').then(result => {
            })
        }catch(e){
            console.log('Erreur fin du jeu',e);   
        }
    }
    
    return(
        <>
            <div className="socials">
                <div className="list_socials">
                    {
                        socials.map((social, index) =>{
                            return(
                                <div class="social complete" onClick={() => inputCallback(component, block)}>
                                    <a 
                                        class="share_button"
                                        // href={social.blocks.link.inputs.value.value && social.blocks.type.inputs.value.value !== "email" ? social.blocks.link.inputs.value.value : null }
                                        href="javascript:void(0)"
                                        // target="_blank" 
                                        rel="noopener noreferrer" 
                                        aria-label=""
                                        style={{backgroundColor: social.blocks.colors.inputs.backgroundColor2.value}}
                                        onClick={social.blocks.type.inputs.value.value === "email" ? () => setOpenModal(true) : () => window.open(social.blocks.link.inputs.value.value,'sharer','toolbar=0,status=0,width=580,height=325')}
                                    >
                                        {
                                            data.inputs?.styleDisplay.value === "iconText" || data.inputs?.styleDisplay.value === "image" ?
                                                <div className={data.inputs?.styleDisplay.value === "image" ? "container-picto full" : "container-picto"} style={{backgroundColor: social.blocks.colors.inputs.backgroundColor1.value}}>
                                                    <img  
                                                        src={
                                                            typeof social.blocks.image?.inputs.value.value === 'string' ? 
                                                                social.blocks.image.inputs.value.value : 
                                                                social.blocks.image.inputs.value?.value?.filePath ? 
                                                                    ` ${process.env.REACT_APP_MEDIAS}/${social.blocks.image.inputs.value.value.filePath}` 
                                                                    : `http://via.placeholder.com/50x50/FFFFFF/53839D?text=${index+1}`
                                                        }
                                                    />
                                                </div>
                                            : null
                                        }
                                        
                                        {
                                            data.inputs?.styleDisplay.value === "iconText" || data.inputs?.styleDisplay.value === "texte" ?
                                                social.blocks.text?.inputs.value.value ?
                                                    <PG className={"text-button"} data={social} preview={preview} component={id} block={'text'} inputCallback={inputCallback} nopadding={true} style={{color: social.blocks.colors.inputs.colorText.value}}/>
                                                : null
                                            : null
                                        }
                                    </a>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            {
                openModal ?
                    <ModalEmailShare onClose={toggleModal} isOpen={openModal} sendAllMail={sendAllMail} lang={lang}/>
                : null
            }
        </>
    )
};