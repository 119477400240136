import React from "react";

export default ({ data, preview, component, block, inputCallback, className }) => {
    var socials = data.blocks[block].config;
    return(
        socials.map((social, index) =>{
            return(
                <div class="social" onClick={() => inputCallback(component, block)}>
                    <a 
                        class="share_button"
                        href={social.blocks.link.inputs.value.value ? social.blocks.link.inputs.value.value : null }
                        target="_blank" 
                        rel="noopener noreferrer" 
                        aria-label=""
                    >
                        <img  
                            src={
                                typeof social.blocks.image.inputs.value.value === 'string' ? 
                                    social.blocks.image.inputs.value.value : 
                                    social.blocks.image.inputs.value?.value?.filePath ? 
                                        ` ${process.env.REACT_APP_MEDIAS}/${social.blocks.image.inputs.value.value.filePath}` 
                                        : `http://via.placeholder.com/50x50/FFFFFF/53839D?text=${index+1}`
                            }
                        />
                    </a>
                </div>
            )
        })
    )
};