import PropTypes from "prop-types";
import React, { useState } from "react";
import H1 from '../../../../shared/components/CustomH1';
import H2 from '../../../../shared/components/CustomH2';
import H3 from '../../../../shared/components/CustomH3';
import Pg from '../../../../shared/components/CustomParagraph';
import Link from '../../../../shared/components/CustomLink';
import Label from '../../../../shared/components/CustomLabel';
import Button from '../../../../shared/components/CustomButton';
import Div from '../../../../shared/components/CustomDiv';
import colors from '../../../../../colors';
import request from "../../../../../tools/Fetch";
import { getBackgroundImage } from "../../../../../utils/getBackgroundImage";

const HomeGame = (props) => {
    const [hover, setHover] = useState(false);
    const [hoverBlock, setHoverBlock] = useState(false);
    const [hoverPopup, setHoverPopup] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [valueInput, setValueInput] = useState(null);
    const [valueInputOther, setValueInputOther] = useState(null);
    const [consentInput, setConsentInput] = useState(false);
    const [optionnalInput, setOptionnalInput] = useState(false);
    const [errorConsent, setErrorConsent] = useState(false);
    const [errorMail, setErrorMail] = useState(false);
    const [loading, setLoading] = useState(false);

    let { 
        preview = false,  
        id = null, 
        data = null,
        inputCallback = null,
    } = props;

    function handleChange(value, type=false) {
        if(!type){
            setValueInput(value)
            setErrorMail(false)
        }else if(type === 'other'){
            setValueInputOther(value);
        }
    }

    const isToday = (date) => {
        const today = new Date()
        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
    };

    const handleClickButton = () => {
        setLoading(true)
        let data = new FormData();
        let currentSource = localStorage.getItem('GAME_SOURCE');
        data.append('source', currentSource);
        data.append('username', valueInput);
        data.append('token', process.env.REACT_APP_ASSET_TOKEN);
        if (optionnalInput){
            data.append('subscribe', optionnalInput);
        }
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(valueInput)) {
            if (consentInput){
                try {
                    request(`${process.env.REACT_APP_API}/public/asset/games/${props.assetGameType}/registration/can_play`, 'post', data, 'multipart/form-data').then(response => {
                        console.log(response.code)
                        if (response.code === "no_registration_for_asset"){
                            setShowPopup(true);
                        }else if(response.code === "email_not_validated"){
                            setShowPopup(true);
                        }else if(response.code === "can_play"){
                            if (props.assetGameType === "random_draw" && props.isMultiplePlayGame){
                                localStorage.setItem('username', valueInput);
                                window.location = '/game';
                            }else{
                                localStorage.setItem('wishlist_validated', false)
                                localStorage.setItem('username', valueInput);
                                if(response.data?.userToken){
                                    localStorage.setItem('userToken', response.data.userToken);
                                }
                                window.location = '/game';
                            }
                        }else if (response.code === "already_played_today"){
                            window.location = '/already-played';
                        }else if (response.code === "already_win"){
                            window.location = '/already-won';
                        }
                        setLoading(false);
                    });
                } catch(e) {
                    console.log(e);
                    setLoading(false);
                }
            }else{
                setLoading(false);
                setErrorConsent(true);
            }
        }else{
            setErrorMail(true);
            if (!consentInput){
                setErrorConsent(true);
            }
            setLoading(false);
        }
    }

    const handlePopup = () => {
        setShowPopup(!showPopup);
    }
    
    let imagePopup = data.blocks.popupEmail?.inputs['backgroundImage'].value;
    let imagePopupContent = data.blocks.popupEmail?.inputs['backgroundImageContent']?.value;

    let element; 
    let imageBlock;
    if(data.blocks.contentTop){
        element = data.blocks.contentTop;
        imageBlock = element.inputs['backgroundImage'].value;
    } else{
        element = data;
    }
    
    let image = data.inputs['backgroundImage'].value;
    let imageTablet = data.inputs['backgroundImageTablet']?.value;
    let imageMobile = data.inputs['backgroundImageMobile']?.value;

    return (
        <div 
            style={{
                backgroundColor: data.inputs['backgroundColor'].value,
                backgroundImage: getBackgroundImage(image,imageTablet,imageMobile),
                backgroundSize: "cover",
                backgroundPosition: "bottom center",
                backgroundRepeat: "no-repeat",
                position: 'relative'
            }}
            className={'typeSection'}
            onMouseEnter={() => {setHover(true); setHoverBlock(false); setHoverPopup(false)}} onMouseLeave={() => {setHover(false); }}
        >
            <Link className="reglement home_reglement" href="#reglement_link" data={data} preview={preview} component={id} block={'linkReglementText'} inputCallback={inputCallback} />
            { !preview && hover && props.toolbarSection }
            {
                data.inputs['typeBlock'] && data.inputs['typeBlock']?.value === 'original' ? (
                    <div className={data.blocks.imageSide && data.blocks.imageSide.inputs.value.value ? 'withImageSide' : 'withoutImageSide'}>
                        {
                            data.inputs.positionImage.value === 'left' && data.blocks.imageSide && data.blocks.imageSide.inputs.value.value ? (
                                <div className="imageSide" style={{backgroundImage: `url(${typeof data.blocks.imageSide.inputs.value.value === 'string' ? data.blocks.imageSide.inputs.value.value : data.blocks.imageSide.inputs.value?.value?.filePath ? ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.imageSide.inputs.value.value.filePath}` : null})`}}></div>
                            ) : null
                        }
                        <div className="contentCenter" style={{paddingTop: 1}}>
                            {
                                data.blocks.imageAbsolute && data.blocks.imageAbsolute.inputs.value.value ? (
                                    <img 
                                        className={data.blocks.imageAbsolute.inputs.position?.value === 'absolute' ? 'imageAbsolute' : 'imageRelative'} 
                                        style={{
                                            margin: '12px auto', 
                                            display: 'block',
                                            maxWidth: data.blocks.imageAbsolute?.inputs?.maxWidth?.value || '100%',
                                            width: '90%',
                                        }} 
                                        onClick={() => inputCallback(id, 'imageAbsolute')}
                                        src={
                                            typeof data.blocks.imageAbsolute.inputs.value.value === 'string' ? 
                                                data.blocks.imageAbsolute.inputs.value.value : 
                                                data.blocks.imageAbsolute.inputs.value?.value?.filePath ? 
                                                    ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.imageAbsolute.inputs.value.value.filePath}` 
                                                    : null
                                            }
                                        alt="Logo jeu"
                                    />
                                ) : null
                            }
                            <section 
                                className="first overflow_hidden" 
                                style={{
                                    minHeight: ((element.blocks['title'].inputs.value?.value && element.blocks['title'].inputs.value?.value !== "<p><br></p>") || (element.blocks['subtitle'].inputs.value?.value && element.blocks['subtitle'].inputs.value?.value !== '<p><br></p>')) ? '500px' : 'auto',
                                    padding: ((element.blocks['title'].inputs.value?.value && element.blocks['title'].inputs.value?.value !== "<p><br></p>") || (element.blocks['subtitle'].inputs.value?.value && element.blocks['subtitle'].inputs.value?.value !== '<p><br></p>')) ? '50px 0' : '10px 0',
                                    display: 'flex',
                                    flexDirection: 'column', 
                                    justifyContent: 'space-around', 
                                    alignItems: 'center'
                                }}
                            >
                                {
                                    element.inputs?.positionLogo?.value ? (
                                        <img className="logo" onClick={() => inputCallback(id, 'logo')}
                                            style={{
                                                width: element.blocks.logo?.inputs?.maxWidth?.value || '100%', 
                                                maxWidth: 'auto',
                                                maxHeight: ((element.blocks['title'].inputs.value?.value && element.blocks['title'].inputs.value?.value !== "<p><br></p>") && (element.blocks['subtitle'].inputs.value?.value && element.blocks['subtitle'].inputs.value?.value !== '<p><br></p>')) ? null : '300px',
                                                border: !preview ? `1px dashed ${colors.blue.lighter.hue150}` : null,
                                                display: 'block',
                                                margin: 'auto',
                                                marginBottom: 16,
                                                marginTop: 0,
                                            }}
                                            src={
                                                typeof element.blocks.logo.inputs.value.value === 'string' ? 
                                                    element.blocks.logo.inputs.value.value : 
                                                    element.blocks.logo.inputs.value?.value?.filePath ? 
                                                        ` ${process.env.REACT_APP_MEDIAS}/${element.blocks.logo.inputs.value.value.filePath}` 
                                                        : "http://via.placeholder.com/400x250/FFFFFF/53839D?text=Logo"
                                                }
                                            alt="Logo jeu"
                                        />
                                    ) : null
                                }
                                <div 
                                    style={{
                                        position: 'relative', 
                                        backgroundColor: element.inputs?.backgroundColor?.value ? element.inputs?.backgroundColor?.value : null,
                                        backgroundImage: typeof imageBlock === 'string' ? `url(${imageBlock})` : imageBlock?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${imageBlock.filePath})` : 'none',
                                        backgroundSize: "100% 100%",
                                        backgroundPosition: "bottom center",
                                        backgroundRepeat: "no-repeat",
                                        padding: `${element.inputs?.paddingVertical?.value ? element.inputs?.paddingVertical.value : 0}px ${element.inputs?.paddingHorizontal?.value ? element.inputs?.paddingHorizontal.value : 0}px`,
                                    }}
                                    onMouseEnter={() => {setHoverBlock(true); setHover(false); setHoverPopup(false)}} onMouseLeave={() => {setHoverBlock(false); }}
                                >
                                    {!preview && hoverBlock && props.toolbarBlock}
                                    {
                                        !element.inputs?.positionLogo?.value && data.inputs?.displayLogo?.value !== 'hidden' ? (
                                            <img className="logo" onClick={() => inputCallback(id, 'logo')}
                                                style={{
                                                    width: element.blocks.logo?.inputs?.maxWidth?.value || '100%', 
                                                    maxWidth: 'auto',
                                                    maxHeight: ((element.blocks['title'].inputs.value?.value && element.blocks['title'].inputs.value?.value !== "<p><br></p>") && (element.blocks['subtitle'].inputs.value?.value && element.blocks['subtitle'].inputs.value?.value !== '<p><br></p>')) ? null : '300px',
                                                    border: !preview ? `1px dashed ${colors.blue.lighter.hue150}` : null,
                                                    display: 'block',
                                                    margin: 'auto'
                                                }}
                                                src={
                                                    typeof element.blocks.logo.inputs.value.value === 'string' ? 
                                                        element.blocks.logo.inputs.value.value : 
                                                        element.blocks.logo.inputs.value?.value?.filePath ? 
                                                            ` ${process.env.REACT_APP_MEDIAS}/${element.blocks.logo.inputs.value.value.filePath}` 
                                                            : "http://via.placeholder.com/400x250/FFFFFF/53839D?text=Logo"
                                                    }
                                                alt="Logo jeu"
                                            />
                                        ) : null
                                    }
                                    {
                                        element.blocks['title'].inputs.value?.value && element.blocks['title'].inputs.value?.value !== "<p><br></p>" ? (
                                            <H1 data={element} preview={preview} component={id} block={'title'} inputCallback={inputCallback} nopadding={true} />
                                        ) : null
                                    }
                                    {
                                        element.blocks['subtitle'].inputs.value?.value && element.blocks['subtitle'].inputs.value?.value !== '<p><br></p>' ? (
                                            <H2 data={element} preview={preview} component={id} block={'subtitle'} inputCallback={inputCallback} nopadding={true} />
                                        ) : null
                                    }
                                    {
                                        data.inputs.displayForm?.value === "show" ? (
                                            <form 
                                                id="form_login" 
                                                method="POST"
                                                onSubmit={(e) => e.preventDefault()}
                                            >
                                                {
                                                    element.blocks['labelEmail'].inputs.value.value !== "" ? (
                                                        <Label data={element} preview={preview} component={id} block={'labelEmail'} inputCallback={inputCallback} nopadding={true} forInput="inputEmail" />
                                                    ) : null
                                                }
                                                <div className="inputZone">
                                                    <input style={{
                                                        fontSize: element.blocks['button'].inputs.size.value,
                                                        lineHeight: '2',
                                                        borderStyle: 'solid',
                                                        borderWidth: element.blocks.button.inputs?.border?.inputs?.borderWidth?.value ? element.blocks.button.inputs.border.inputs.borderWidth.value : '0px',
                                                        borderColor: element.blocks.button.inputs?.border?.inputs?.borderColor?.value ? element.blocks.button.inputs.border.inputs.borderColor.value : null,
                                                        borderRadius: element.blocks.button.inputs?.border?.inputs?.borderRadius?.value ? element.blocks.button.inputs.border.inputs.borderRadius.value : null,
                                                    }} type="email" value={valueInput} name="email" id="inputEmail" placeholder={element.blocks['labelEmail']?.inputs?.placeholder?.value} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required autofocus onChange={(e) => {handleChange(e.target.value)}}/>
                                                    {
                                                        data.inputs.positionButton.value === 'align' ? (
                                                            <Button data={element} preview={preview} component={id} block={'button'} inputCallback={inputCallback} className="button" nopadding={true} type="submit" onClick={data.blocks.popupEmail?.inputs?.use.value === 'enable' ? loading ? null : () => handleClickButton() : null} loading={loading}/>
                                                        ) : null
                                                    }
                                                    
                                                </div>
                                                {
                                                    errorMail ?
                                                        <div className={"verification"}>Veuillez utiliser une adresse mail valide (ex: johndoe@example.com)</div>
                                                    : null
                                                }
                                                {
                                                    element.blocks['labelOtherInput']?.inputs?.value?.value !== "" ? (
                                                        <Label data={element} preview={preview} component={id} block={'labelOtherInput'} inputCallback={inputCallback} nopadding={true} forInput="labelOtherInput" />
                                                    ) : null
                                                }
                                                {
                                                    element.blocks['labelOtherInput']?.inputs?.isActive?.value ? (
                                                        <div className="inputZone">
                                                            <input style={{
                                                                fontSize: element.blocks['labelOtherInput']?.inputs?.size?.value,
                                                                lineHeight: '2',
                                                                borderStyle: 'solid',
                                                                borderWidth: element.blocks.button.inputs?.border?.inputs?.borderWidth?.value ? element.blocks.button.inputs.border.inputs.borderWidth.value : '0px',
                                                                borderColor: element.blocks.button.inputs?.border?.inputs?.borderColor?.value ? element.blocks.button.inputs.border.inputs.borderColor.value : null,
                                                                borderRadius: element.blocks.button.inputs?.border?.inputs?.borderRadius?.value ? element.blocks.button.inputs.border.inputs.borderRadius.value : null,
                                                            }} type="text" value={valueInputOther} name="text" id="inputOther" placeholder={element.blocks['labelOtherInput']?.inputs?.placeholder?.value} required autofocus onChange={(e) => {handleChange(e.target?.value, 'other')}}/>
                                                        </div>
                                                    ) : null
                                                }
                                                <div className="boxCheckbox">
                                                    <div className={errorConsent ? "consent error" : "consent"}>
                                                        <input required id="consentement" value={consentInput} type="checkbox" onClick={() => { setConsentInput(!consentInput); setErrorConsent(false);}}/>
                                                        <Label data={element} preview={preview} component={id} block={'labelConsent'} inputCallback={inputCallback} nopadding={true} forInput="consentement" />    
                                                    </div>
                                                    {
                                                        element.blocks.labelConsentOptionnal.inputs?.value?.value && element.blocks.labelConsentOptionnal.inputs?.isActive?.value ? 
                                                            <div className={"consent"}>
                                                                <input id="consentementOptionnal" value={optionnalInput} type="checkbox" onClick={() => setOptionnalInput(!optionnalInput)}/>
                                                                <Label data={element} preview={preview} component={id} block={'labelConsentOptionnal'} inputCallback={inputCallback} nopadding={true} forInput="consentementOptionnal"/>    
                                                            </div>
                                                        : null
                                                    }
                                                </div>
                                                {
                                                    data.inputs.positionButton.value === 'bottom' ? (
                                                        <Button data={element} preview={preview} component={id} block={'button'} inputCallback={inputCallback} className="button" nopadding={true} type="submit" onClick={data.blocks.popupEmail?.inputs?.use.value === 'enable' ? loading ? null : () => handleClickButton() : null} loading={loading}/>
                                                    ) : null
                                                }
                                            </form>
                                        ) : element.blocks.button.inputs.link.value ? (
                                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                <Link data={element} preview={preview} component={id} block={'button'} className="button" inputCallback={inputCallback} nopadding={true}/>
                                            </div>
                                        ) : (
                                            <Button data={element} preview={preview} component={id} block={'button'} className="button" inputCallback={inputCallback} nopadding={true} onClick={data.blocks.popupEmail?.inputs?.use.value === 'enable' ? () => handleClickButton() : null}/>
                                        )
                                    }
                                </div>
                                {
                                    data.blocks.copyright.inputs.value.value ? (
                                        <Pg data={data} preview={preview} component={id} block={'copyright'} inputCallback={inputCallback} nopadding={true} className={'copyrightText'} />
                                    ) : null
                                }
                            </section>
                        </div>
                        {
                            data.inputs.positionImage.value === 'right' && data.blocks.imageSide && data.blocks.imageSide.inputs.value.value ? (
                                <div className="imageSide" style={{backgroundImage: `url(${typeof data.blocks.imageSide.inputs.value.value === 'string' ? data.blocks.imageSide.inputs.value.value : data.blocks.imageSide.inputs.value?.value?.filePath ? ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.imageSide.inputs.value.value.filePath}` : null})`}}></div>
                            ) : null
                        }
                    </div>
                ) : (
                    <section 
                        className="first overflow_hidden" 
                        style={{
                            minHeight: ((element.blocks['title'].inputs?.value?.value && element.blocks['title'].inputs.value?.value !== "<p><br></p>") || (element.blocks['subtitle'].inputs.value?.value && element.blocks['subtitle'].inputs.value?.value !== '<p><br></p>')) ? '500px' : 'auto',
                            padding: ((element.blocks['title'].inputs?.value?.value && element.blocks['title'].inputs.value?.value !== "<p><br></p>") || (element.blocks['subtitle'].inputs.value?.value && element.blocks['subtitle'].inputs.value?.value !== '<p><br></p>')) ? '50px 0' : '10px 0',
                            display: 'flex',
                            flexDirection: 'column', 
                            justifyContent: 'space-around', 
                            alignItems: 'center'
                        }}
                    >
                        {
                            element.inputs?.positionLogo?.value ? (
                                <img className="logo" onClick={() => inputCallback(id, 'logo')}
                                    style={{
                                        width: element.blocks.logo?.inputs?.maxWidth?.value || '100%', 
                                        maxWidth: 'auto',
                                        maxHeight: ((element.blocks['title'].inputs.value?.value && element.blocks['title'].inputs.value?.value !== "<p><br></p>") && (element.blocks['subtitle'].inputs.value?.value && element.blocks['subtitle'].inputs.value?.value !== '<p><br></p>')) ? null : '300px',
                                        border: !preview ? `1px dashed ${colors.blue.lighter.hue150}` : null,
                                        display: 'block',
                                        margin: 'auto',
                                        marginBottom: 16,
                                        marginTop: 0,
                                    }}
                                    src={
                                        typeof element.blocks.logo.inputs.value.value === 'string' ? 
                                            element.blocks.logo.inputs.value.value : 
                                            element.blocks.logo.inputs.value?.value?.filePath ? 
                                                ` ${process.env.REACT_APP_MEDIAS}/${element.blocks.logo.inputs.value.value.filePath}` 
                                                : "http://via.placeholder.com/400x250/FFFFFF/53839D?text=Logo"
                                        }
                                    alt="Logo jeu"
                                />
                            ) : null
                        }
                        <div 
                            style={{
                                position: 'relative', 
                                backgroundColor: element.inputs?.backgroundColor?.value ? element.inputs?.backgroundColor?.value : null,
                                backgroundImage: typeof imageBlock === 'string' ? `url(${imageBlock})` : imageBlock?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${imageBlock.filePath})` : 'none',
                                backgroundSize: "100% 100%",
                                backgroundPosition: "bottom center",
                                backgroundRepeat: "no-repeat",
                                padding: `${element.inputs?.paddingVertical?.value ? element.inputs?.paddingVertical.value : 0}px ${element.inputs?.paddingHorizontal?.value ? element.inputs?.paddingHorizontal.value : 0}px`,
                            }}
                            onMouseEnter={() => {setHoverBlock(true); setHover(false); setHoverPopup(false)}} onMouseLeave={() => {setHoverBlock(false); }}
                        >
                            {!preview && hoverBlock && props.toolbarBlock}
                            {
                                !element.inputs?.positionLogo?.value ? (
                                    <img className="logo" onClick={() => inputCallback(id, 'logo')}
                                        style={{
                                            width: element.blocks.logo?.inputs?.maxWidth?.value || '100%', 
                                            maxWidth: 'auto',
                                            maxHeight: ((element.blocks['title'].inputs.value?.value && element.blocks['title'].inputs.value?.value !== "<p><br></p>") && (element.blocks['subtitle'].inputs.value?.value && element.blocks['subtitle'].inputs.value?.value !== '<p><br></p>')) ? null : 'auto',
                                            border: !preview ? `1px dashed ${colors.blue.lighter.hue150}` : null,
                                            display: 'block',
                                            margin: 'auto'
                                        }}
                                        src={
                                            typeof element.blocks.logo.inputs.value.value === 'string' ? 
                                                element.blocks.logo.inputs.value.value : 
                                                element.blocks.logo.inputs.value?.value?.filePath ? 
                                                    ` ${process.env.REACT_APP_MEDIAS}/${element.blocks.logo.inputs.value.value.filePath}` 
                                                    : "http://via.placeholder.com/400x250/FFFFFF/53839D?text=Logo"
                                            }
                                        alt="Logo jeu"
                                    />
                                ) : null
                            }
                            {
                                element.blocks['title'].inputs.value?.value && element.blocks['title'].inputs.value?.value !== "<p><br></p>" ? (
                                    <H1 data={element} preview={preview} component={id} block={'title'} inputCallback={inputCallback} nopadding={true} />
                                ) : null
                            }
                            {
                                element.blocks['subtitle'].inputs.value?.value && element.blocks['subtitle'].inputs.value?.value !== '<p><br></p>' ? (
                                    <H2 data={element} preview={preview} component={id} block={'subtitle'} inputCallback={inputCallback} nopadding={true} />
                                ) : null
                            }
                            {
                                data.inputs.displayForm?.value === "show" ? (
                                    <form 
                                        id="form_login" 
                                        method="POST"
                                        onSubmit={(e) => e.preventDefault()}
                                    >
                                        <div class="container-form">
                                            {
                                                element.blocks['labelEmail'].inputs.value.value !== '' ? (
                                                    <Label data={element} preview={preview} component={id} block={'labelEmail'} inputCallback={inputCallback} nopadding={true} forInput="inputEmail" />
                                                ) : null
                                            }
                                            <div className="inputZone">
                                                {
                                                    
                                                }
                                                <input style={{
                                                    fontSize: element.blocks['button'].inputs.size.value,
                                                    lineHeight: '2',
                                                    borderStyle: 'solid',
                                                    borderWidth: element.blocks.button.inputs?.border?.inputs?.borderWidth?.value ? element.blocks.button.inputs.border.inputs.borderWidth.value : '0px',
                                                    borderColor: element.blocks.button.inputs?.border?.inputs?.borderColor?.value ? element.blocks.button.inputs.border.inputs.borderColor.value : null,
                                                    borderRadius: element.blocks.button.inputs?.border?.inputs?.borderRadius?.value ? element.blocks.button.inputs.border.inputs.borderRadius.value : null,
                                                }} type="email" value={valueInput} name="email" id="inputEmail" placeholder="Votre adresse email..." pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required autofocus onChange={(e) => {handleChange(e.target.value)}}/>
                                                {
                                                    errorMail ?
                                                        <div className={"verification"}>Veuillez utiliser une adresse mail valide (ex: johndoe@example.com)</div>
                                                    : null
                                                }
                                            </div>
                                            <div className={errorConsent ? "consent error" : "consent"}>
                                                <div>
                                                    <input required id="consentement" value={consentInput} type="checkbox" onClick={() => { setConsentInput(!consentInput); setErrorConsent(false);}}/>
                                                    <Label data={element} preview={preview} component={id} block={'labelConsent'} inputCallback={inputCallback} nopadding={true} forInput="consentement" />    
                                                </div>
                                                {
                                                    element.blocks.labelConsentOptionnal.inputs?.value?.value && element.blocks.labelConsentOptionnal.inputs?.isActive?.value ? 
                                                        <div>
                                                            <input id="consentementOptionnal" value={optionnalInput} type="checkbox" onClick={() => setOptionnalInput(!optionnalInput)}/>
                                                            <Label data={element} preview={preview} component={id} block={'labelConsentOptionnal'} inputCallback={inputCallback} nopadding={true} forInput="consentementOptionnal"/>    
                                                        </div>
                                                    : null
                                                }
                                            </div>
                                            <Button data={element} preview={preview} component={id} block={'button'} inputCallback={inputCallback} className="button" nopadding={true} type="submit" onClick={loading ? null : () => handleClickButton()} loading={loading}/>
                                        </div>
                                    </form>
                                ) : (
                                    <Button data={element} preview={preview} component={id} block={'button'} className="button" inputCallback={inputCallback} nopadding={true}/>
                                )
                            }
                        </div>
                    </section>
                )
            }
            {
                data.blocks.popupEmail?.inputs.use.value === 'enable' ? (
                    <div className="popup" style={{display: showPopup ? 'block' : 'none'}} onMouseEnter={() => {setHoverPopup(true); setHover(false); setHoverBlock(false)}} onMouseLeave={() => {setHoverPopup(false);}}>
                        { !preview && hoverPopup && props.toolbarPopup }
                        <div 
                            className="overlay" 
                            style={{
                                backgroundColor: data.blocks.popupEmail?.inputs.backgroundColor.value,
                                backgroundImage: typeof imagePopup === 'string' ? `url(${imagePopup})` : imagePopup?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${imagePopup.filePath})` : 'none',
                                backgroundPosition: 'center center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat'
                            }}
                        />
                        <div className="popup_content" style={{
                                backgroundColor: data.blocks.popupEmail?.inputs.backgroundColorContent?.value,
                                backgroundImage: typeof imagePopupContent === 'string' ? `url(${imagePopupContent})` : imagePopupContent?.filePath ? `url(${process.env.REACT_APP_MEDIAS}/${imagePopupContent.filePath})` : 'none',
                                backgroundPosition: 'center center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat'
                            }}>
                            <div className="inscription">
                                <H2 data={data.blocks.popupEmail} preview={preview} component={id} block={'titlePopup'} inputCallback={inputCallback} nopadding={true} />
                                <Div data={data.blocks.popupEmail} preview={preview} component={id} block={'descriptionPopup'} inputCallback={inputCallback} nopadding={true} />
                                {
                                    data.blocks.popupEmail.blocks.imageLink?.inputs.value.value ?
                                        <a className="image-popup" href={data.blocks.popupEmail.blocks.urlImage.inputs.value.value ?? null} target="_blank">
                                            <img src={typeof data.blocks.popupEmail.blocks.imageLink.inputs.value.value === 'string' ? data.blocks.popupEmail.blocks.imageLink.inputs.value.value : data.blocks.popupEmail.blocks.imageLink.inputs.value?.value?.filePath ? ` ${process.env.REACT_APP_MEDIAS}/${data.blocks.popupEmail.blocks.imageLink.inputs.value.value.filePath}` : null} />
                                        </a>
                                    : null
                                }
                                {
                                    data.blocks.popupEmail.blocks.buttonPopup?.inputs.value.value ?
                                        <Button data={data.blocks.popupEmail} preview={preview} component={id} block={'buttonPopup'} inputCallback={inputCallback} className="button" nopadding={true} blank/>
                                    : null
                                }
                                {
                                    data.blocks.popupEmail.blocks.titleExplicationPopup.inputs.value.value ? (
                                        <H3 data={data.blocks.popupEmail} preview={preview} component={id} block={'titleExplicationPopup'} inputCallback={inputCallback} nopadding={true} />
                                    ) : null
                                }
                                {
                                    data.blocks.popupEmail.blocks.explicationPopup.inputs.value.value ? (
                                        <Pg data={data.blocks.popupEmail} preview={preview} component={id} block={'explicationPopup'} inputCallback={inputCallback} nopadding={true} />
                                    ) : null
                                }
                            </div>
                        </div>
                    </div> 
                ) : null
            }
        </div>
    );
};

HomeGame.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingclassName: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string, 
    noSticky: PropTypes.bool
};

export default HomeGame;
